import { useEffect, useState } from "react";
import { selectCurrentOrganization } from "../../app/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../app/slices/AuthenticationSlice";
import { PropService } from "../../services/PropService";
import {
  addPagedProps,
  initializePropLibrary,
  selectFilteredCategory,
  selectPropFilter,
  selectProps,
} from "../../app/slices/PropLibrarySlice";

export default function usePropLibraryPagination() {
  // needed for service
  const orgId = useSelector(selectCurrentOrganization)?.id;
  const token = useSelector(selectToken);

  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 10;

  // props
  const allProps = useSelector(selectProps);

  // filters
  const filteredCategory = useSelector(selectFilteredCategory); // category - filter
  const propFilter = useSelector(selectPropFilter); // text - filter

  // slice
  const dispatch = useDispatch();

  useEffect(() => {
    LoadFirstPage();
  }, [filteredCategory]);

  async function LoadFirstPage() {
    if (!orgId) return;

    setCurrentPage(1);

    const result = await PropService.GetPropsFromPage(
      orgId,
      token,
      currentPage,
      pageSize,
      filteredCategory,
      propFilter
    );
    dispatch(
      initializePropLibrary({
        totalCount: result.totalCount,
        firstPage: result.props,
      })
    );
  }

  const onRerender = async (
    amountOfColumns: number,
    rowStartIndex: number,
    rowStopIndex: number
  ) => {
    if (!orgId || !token) return;

    var startIndex = amountOfColumns * rowStartIndex;
    var endIndex = amountOfColumns * rowStopIndex + amountOfColumns;
    var startPage = Math.floor(startIndex / 10);
    var endPage = Math.ceil(endIndex / 10);

    // load next page
    for (var s = startPage; s < endPage; s++) {
      console.log(s);
      if (allProps[10 * s].id === "unloaded") {
        console.log("Loading page", s + 1);
        var result = await PropService.GetPropsFromPage(
          orgId,
          token,
          s + 1,
          pageSize,
          filteredCategory,
          propFilter
        );
        if (!result) return;
        dispatch(addPagedProps({ pageNumber: s, assets: result.props }));
      }
    }
  };

  return { LoadFirstPage, onRerender };
}
