import { useSelector } from "react-redux";
import { selectPropCategories } from "../../app/slices/PropLibrarySlice";
import PiButton from "../../components/PiButton";
import PropLibraryCategoryLabel from "./PropLibraryCategoryLabel";
import PropLibraryInteractionLabel from "./PropLibraryInteractionLabel";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import { TranslatedPropDTO } from "../../dto/TranslatedPropDTO";
import { TranslatedPropContentDTO } from "../../dto/TranslatedPropContentDTO";
export default function PropLibraryDetails(props: {
  onAddPropClick: () => void;
  selectedProp: TranslatedPropDTO | undefined;
  propContent: TranslatedPropContentDTO | null;
}) {
  const { onAddPropClick, selectedProp, propContent } = { ...props };
  const categories = useSelector(selectPropCategories);

  const myCategoriesMapped = selectedProp?.categories.map((category) => {
    const result = categories.find((cat) => cat.id === category.id);
    if (!result) return;
    return <PropLibraryCategoryLabel key={category.id} category={result} />;
  });

  const myInteractionsMapped = propContent?.interactions.map((interaction) => {
    return (
      <PropLibraryInteractionLabel
        key={interaction.id}
        interactionName={interaction.name}
      />
    );
  });

  const image = (
    <>
      {selectedProp && (
        <img
          src={selectedProp.downloadUri}
          alt={selectedProp.name}
          className={
            "w-full h-[96px] piLg:h-[256px] object-contain rounded-lg drop-shadow-xl"
          }
        />
      )}
    </>
  );

  const noImage = (
    <div className="h-[96px] piLg:h-[256px] w-full flex">
      <div className="m-auto text-[96px] text-creator-text-soft">
        <ViewInArOutlinedIcon fontSize="inherit" color="inherit" />
      </div>
    </div>
  );

  const propPreview = selectedProp?.downloadUri ? image : noImage;

  return (
    <div
      className={
        "relative w-full h-full bg-interaction-softBackground rounded-[5px] p-[14px] select-none"
      }
    >
      {selectedProp && (
        <div className={"flex flex-col gap-[16px] overflow-y-auto"}>
          <span className={"text-creator-text font-medium text-[22px]"}>
            {selectedProp.name}
          </span>
          <div className={"bg-[#DAE0E6] rounded-[5px] p-[16px] flex"}>
            {propPreview}
          </div>
          <div className={"w-full flex flex-col gap-[16px]"}>
            <div className={"text-[16px] font-bold text-creator-text"}>
              Categories
            </div>
            <div className={"flex gap-[10px]"}>{myCategoriesMapped}</div>
          </div>
          <div className={"w-full flex flex-col gap-[16px]"}>
            <div className={"text-[16px] font-bold text-creator-text"}>
              Available Interactions
            </div>
            <div className={"flex gap-[10px]"}>{myInteractionsMapped}</div>
          </div>
        </div>
      )}
      {selectedProp && (
        <div
          className={
            "absolute bottom-[16px] left-1/2 w-[183px] transform -translate-x-1/2 h-[40px] "
          }
        >
          <PiButton variant={"piBlue"} onClick={onAddPropClick}>
            Add Prop
          </PiButton>
        </div>
      )}
    </div>
  );
}
