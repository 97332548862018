import classNames from "classnames";
import CheckIcon from "@mui/icons-material/Check";

export default function PiCheckbox(props: {
  checked: boolean;
  onToggle: (checked: boolean) => void;
  shape?: "square" | "rounded";
}) {
  const { checked, onToggle, shape = "square" } = props;

  return (
    <div
      className={classNames({
        "w-[20px] h-[20px] flex justify-center items-center cursor-pointer ":
          true,
        "text-white bg-creator-primary hover:bg-creator-primary-hover text-[16px]":
          checked,
        "border-2 border-solid border-creator-primary": !checked,
        "rounded-[5px]": shape === "square",
        "rounded-full": shape === "rounded",
      })}
      onClick={() => onToggle(!checked)}
    >
      {checked && <CheckIcon color="inherit" fontSize="inherit" />}
    </div>
  );
}
