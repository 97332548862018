import InteractionPointPreview, {
  EmptyPropPointStrategy,
  PropPointStrategy,
} from "./InteractionPointPreview";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPropStateToSelectedTake,
  removePropStateFromSelectedTake,
  selectSelectedTake3D,
} from "../../app/slices/TrainingSlice";
import usePropHelper from "../../hooks/PropHelper";
import PropPresetPopover from "../prop_presets/PropPresetPopover";
import usePropPointPreview from "./PropPointPreview.hook";
import { selectSelectedPropId } from "../../app/slices/PropLibrarySlice";

interface PropPointPreviewProps {
  x: number;
  y: number;
  disabled?: boolean;
  positionId: string;
}

export default function PropPointPreview(props: PropPointPreviewProps) {
  const { x, y, positionId, disabled } = { ...props };

  const dispatch = useDispatch();
  const selectedPropPickerId = useSelector(selectSelectedPropId);
  const selectedTake = useSelector(selectSelectedTake3D);
  const { getUsedPropByInstanceId } = usePropHelper();
  const {
    addNewPropFromPropLibrary,
    openPropLibrary,
    closePropLibrary,
    isPropLibraryOpen,
  } = usePropPointPreview();

  const myPropTakeState = useMemo(() => {
    const props = selectedTake?.props;
    return props?.find((prop) => prop.positionId === positionId);
  }, [selectedTake?.props]);
  const myStrategy = useMemo(() => {
    return myPropTakeState ? PropPointStrategy : EmptyPropPointStrategy;
  }, [myPropTakeState]);

  const myPropDTO = useMemo(() => {
    if (myPropTakeState)
      return getUsedPropByInstanceId(myPropTakeState?.instanceId ?? "");
    else return undefined;
  }, [myPropTakeState]);

  const onAddProp = (instanceId: string, propId: string) => {
    dispatch(
      addPropStateToSelectedTake({
        instanceId: instanceId,
        positionId: positionId,
      })
    );
    //setIsOpen(false);
  };

  const onNodeClick = () => {
    if (myPropTakeState || disabled) return;
    //setIsOpen(true);
  };

  const onDeletePropFromPosition = () => {
    if (!myPropTakeState) return;
    dispatch(removePropStateFromSelectedTake(myPropTakeState!.id));
  };

  const popoverContent = (
    <PropPresetPopover
      canDelete={myPropDTO !== undefined}
      onClick={onAddProp}
      onDelete={onDeletePropFromPosition}
      includedGrabbedProps={false}
      onAddNewPropClick={() => openPropLibrary(positionId)}
    />
  );

  return (
    <>
      <InteractionPointPreview
        strategy={myStrategy}
        x={x}
        y={y}
        prop={myPropDTO}
        onClick={onNodeClick}
        popoverContent={popoverContent}
        disabled={disabled}
      />
    </>
  );
}
