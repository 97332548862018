import { useSelector } from "react-redux";
import PropPagedGridView from "./PropPagedGridView";
import {
  selectIsPropSliceInitialized,
  selectProps,
  selectSelectedPropId,
  setSelectedProp,
} from "../../app/slices/PropLibrarySlice";
import { TranslatedPropDTO } from "../../dto/TranslatedPropDTO";
import usePropLibraryPagination from "./PropLibraryPagination.hooks";
import { useDispatch } from "react-redux";
import UsePropLibrary from "./PropLibrary.hooks";
import { useEffect } from "react";

export default function PropStrategyItems() {
  const dispatch = useDispatch();

  const allProps = useSelector(selectProps);
  const selectedPropId = useSelector(selectSelectedPropId);
  const { onRerender, LoadFirstPage } = usePropLibraryPagination();
  const { loadPropContent } = UsePropLibrary();
  const initialized = useSelector(selectIsPropSliceInitialized);

  const onCardClick = (prop: TranslatedPropDTO) => {
    dispatch(setSelectedProp(prop.id));
    loadPropContent(prop.id);
  };

  useEffect(() => {
    if (!initialized) LoadFirstPage();
  });

  return (
    <PropPagedGridView
      allProps={allProps}
      selectedPropId={selectedPropId}
      onCardClick={onCardClick}
      onRerender={onRerender}
    />
  );
}
