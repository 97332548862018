import { useSelector } from "react-redux";
import {
  LanguageData,
  selectAvailableLanguagesNotAdded,
} from "../../app/slices/TrainingLocalizationSlice";
import AddLanguageDialogOption from "./AddLanguageDialogOption";
import { useState } from "react";
import PiButton from "../../components/PiButton";
import { useAddLanguage } from "./AddLanguageHook";

export default function AddLanguageDialog(props: { onConfirm: () => void }) {
  const { onConfirm } = props;
  const availableLanguages = useSelector(selectAvailableLanguagesNotAdded);

  const [selectedLanguages, setSelectedLanguages] = useState<LanguageData[]>(
    []
  );

  const { addMultipleLanguages } = useAddLanguage();

  const onToggleLanguage = (langData: LanguageData) => {
    if (selectedLanguages.includes(langData)) {
      setSelectedLanguages(selectedLanguages.filter((l) => l !== langData));
    } else {
      setSelectedLanguages([...selectedLanguages, langData]);
    }
  };

  const onAddLanguages = () => {
    onConfirm();
    addMultipleLanguages(selectedLanguages);
  };

  const options = availableLanguages.map((lang, index) => {
    return (
      <AddLanguageDialogOption
        key={index}
        language={lang}
        onToggle={onToggleLanguage}
        selected={selectedLanguages.includes(lang)}
      />
    );
  });

  const message = () => {
    if (selectedLanguages.length === 0) return <></>;
    if (selectedLanguages.length === 1) return <>(1 language)</>;
    return <>({selectedLanguages.length} languages)</>;
  };

  const addButton = (
    <div className="h-[40px] w-full flex justify-end items-center absolute bottom-[0px]">
      <PiButton
        variant="piBlue"
        onClick={onAddLanguages}
        disabled={selectedLanguages.length === 0}
      >
        <div className="flex items-center gap-[8px] justify-center w-full h-full">
          <div className="text-[18px] align-middle">Add</div>
          <div className="text-[14px] font-normal align-middle">
            {message()}
          </div>
        </div>
      </PiButton>
    </div>
  );

  return (
    <div className="w-full h-full flex flex-col gap-[16px] relative">
      <div className="flex flex-col h-[calc(100%-52px)] gap-[12px]  pit-creator-scrollbar overflow-y-auto">
        {options}
      </div>
      {addButton}
    </div>
  );
}
