import { useTranslation } from "react-i18next";
import PiButton from "../../components/PiButton";
import PiInputfield from "../../components/PiInputField";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
export default function TrainingLocalizationDialogHeader(props: {
  hasCheckedLanguages: boolean;
  onAddLanguageClicked: () => void;
  onTranslateAllClicked: () => void;
  onTranslateMissingClicked: () => void;
  onDownloadExcelClicked: () => void;
  onUploadExcelClicked: () => void;
  setFilter: (filter: string) => void;
}) {
  const {
    onAddLanguageClicked,
    onTranslateAllClicked,
    onTranslateMissingClicked,
    onDownloadExcelClicked,
    onUploadExcelClicked,
    hasCheckedLanguages,
    setFilter,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="w-full h-[42px] flex gap-[8px] items-center relative">
      <div className="w-[230px] h-[36px] flex items-center ">
        <PiInputfield
          label=""
          placeholder="Search"
          onChange={setFilter}
          showSearchIcon
        />
      </div>
      <div className="w-[156px] h-[36px] flex">
        <PiButton
          variant="piBlueOutlined"
          onClick={onUploadExcelClicked}
          disabled={false}
        >
          <div className="flex items-center justify-center gap-[4px]  text-[20px]">
            <FileUploadOutlinedIcon color="inherit" fontSize="inherit" />

            <div className="text-[14px]">{t("localization_menu.upload")}</div>
          </div>
        </PiButton>
      </div>
      <div className="w-[156px] h-[36px] flex">
        <PiButton
          variant="piBlueOutlined"
          onClick={onDownloadExcelClicked}
          disabled={!hasCheckedLanguages}
        >
          <div className="flex items-center justify-center gap-[4px]  text-[20px]">
            <FileDownloadOutlinedIcon color="inherit" fontSize="inherit" />
            <div className="text-[14px]">{t("localization_menu.download")}</div>
          </div>
        </PiButton>
      </div>

      <div className="w-[192px] h-[36px] flex">
        <PiButton
          variant="piBlueOutlined"
          onClick={onTranslateAllClicked}
          disabled={!hasCheckedLanguages}
        >
          <div className="flex items-center justify-center gap-[4px]  text-[20px]">
            <TranslateOutlinedIcon color="inherit" fontSize="inherit" />
            <div className=" text-[14px]">
              {t("localization_menu.translate_all")}
            </div>
          </div>
        </PiButton>
      </div>
      <div className="w-[192px] h-[36px] flex">
        <PiButton
          variant="piBlueOutlined"
          onClick={onTranslateMissingClicked}
          disabled={!hasCheckedLanguages}
        >
          <div className="flex items-center justify-center gap-[4px] text-[20px]">
            <TranslateOutlinedIcon color="inherit" fontSize="inherit" />

            <div className="text-[14px]">
              {t("localization_menu.translate_missing")}
            </div>
          </div>
        </PiButton>
      </div>
      <div className="w-[192px] h-[36px] flex items-center absolute right-0">
        <PiButton variant="piBlue" onClick={onAddLanguageClicked}>
          <div className="flex items-center justify-center gap-[4px] text-[14px]">
            <div className="text-[20px] text-white flex justify-center items-center">
              <AddIcon fontSize="inherit" color="inherit" />
            </div>
            {t("add_language")}
          </div>
        </PiButton>
      </div>
    </div>
  );
}
