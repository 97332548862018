import { useTranslation } from "react-i18next";
import { LanguageData } from "../../app/slices/TrainingLocalizationSlice";
import PiCheckbox from "../../components/PiCheckbox";
import PiTag from "../../components/PiTag";
import PiProgressbar from "../../components/PiProgressbar";
import { useTrainingLocalizationTable } from "./TrainingLocalizationTableHooks";
import TrainingLocalizationTableCellMenu from "./TrainingLocalizationTableCellMenu";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectReferenceLanguage } from "../../app/slices/TrainingSlice";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import TranslationprogressbarTooltip from "./TranslationProgressbarTooltip";
import React from "react";

export default function TrainingLocalizationTableCell(props: {
  languageData: LanguageData;
  checked: boolean;
  onToggleChecked: (checked: boolean, languageData: LanguageData) => void;
}) {
  const { languageData, checked, onToggleChecked } = props;
  const { t } = useTranslation();
  const {
    getPercentageTranslated,
    getPercentageAutoTranslated,
    getPercentageNotTranslated,
  } = useTrainingLocalizationTable();
  const refLanguage = useSelector(selectReferenceLanguage);

  //  <TranslationProgressbarTooltip
  //    manuallyTranslated={getPercentageTranslated(languageData.shortcode)}
  //    autoTranslated={getPercentageAutoTranslated(languageData.shortcode)}
  //    notTranslated={getPercentageNotTranslated(languageData.shortcode)}
  //  />;

  const tooltip = (
    <Tooltip
      title={
        <React.Fragment>
          <TranslationprogressbarTooltip
            manuallyTranslated={getPercentageTranslated(languageData.shortcode)}
            autoTranslated={getPercentageAutoTranslated(languageData.shortcode)}
            notTranslated={getPercentageNotTranslated(languageData.shortcode)}
          />
        </React.Fragment>
      }
      placement="top"
      arrow
      followCursor
    >
      <div className="w-[80%] h-[32px] flex items-center">
        <div className="h-[8px] w-full rounded-full">
          <PiProgressbar
            progressBlue={getPercentageTranslated(languageData.shortcode)}
            progressYellow={getPercentageAutoTranslated(languageData.shortcode)}
          />
        </div>
      </div>
    </Tooltip>
  );

  return (
    <div
      className={classNames({
        "flex w-full border-b-2 border-solid border-creator-border/50 h-[49px] items-center px-[12px] text-[14px] text-creator-text-sub font-semibold gap-[12px] relative hover:bg-creator-childActive":
          true,
        "bg-creator-childHover": checked,
      })}
    >
      <div className="w-[40px] h-[32px] flex items-center">
        <PiCheckbox
          checked={checked}
          onToggle={() => onToggleChecked(!checked, languageData)}
        />
      </div>

      <div className="w-[156px] h-[32px] flex items-center gap-[8px]">
        <PiTag text={languageData.shortcode} variant="blue" />
        {refLanguage === languageData.shortcode && (
          <PiTag text="REF" variant="green" />
        )}
      </div>
      {tooltip}
      <div className="absolute right-[12px]">
        <TrainingLocalizationTableCellMenu languageData={languageData} />
      </div>
    </div>
  );
}
