import { useEffect, useState } from "react";
import { IAnswer } from "../../../models/Quiz";
import {
  IConnection,
  IConnectQuiz,
} from "../../../models/interactions/ConnectQuiz";
import {
  selectIsPlaying,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { useDispatch } from "react-redux";
import { GetNewGuid } from "../../../helpers/GuidHelper";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import { AssetDetailsDTO } from "../../../dto/AssetDTO";
import LineTo from "react-lineto";
import { useSelector } from "react-redux";
import { selectIsMenuExpanded } from "../../../app/slices/GlobalSlice";

export default function useConnectQuiz(quiz: IConnectQuiz) {
  const dispatch = useDispatch();

  const { getNewKey } = useTrainingLocalization();
  const [selectedAnswerA, setSelectedAnswerA] = useState<string>("");
  const [selectedAnswerB, setSelectedAnswerB] = useState<string>("");
  const [connections, setConnections] = useState<React.JSX.Element[]>([]);

  const isPlaying = useSelector(selectIsPlaying);
  const isMenuExpanded = useSelector(selectIsMenuExpanded);

  // ==== connections ====
  useEffect(() => {
    generateConnections();
  }, [quiz]);

  useEffect(() => {
    window.addEventListener("resize", regenerateConnectionsWithTimout);

    return () =>
      window.removeEventListener("resize", regenerateConnectionsWithTimout);
  });

  useEffect(() => {
    if (!isPlaying) {
      regenerateConnectionsWithTimout();
    }
  }, [isPlaying]);

  useEffect(() => {
    regenerateConnectionsWithTimout();
  }, [isMenuExpanded]);

  const generateConnections = () => {
    console.log("generateConnections");
    const conns = quiz.connections.map((connection, index) => {
      return (
        <LineTo
          zIndex={1000}
          key={index}
          from={connection.idA}
          to={connection.idB}
          borderColor="var(--pi-game-blue)"
          borderWidth={8}
        />
      );
    });
    setConnections(conns);
  };

  const regenerateConnectionsWithTimout = () => {
    const timeoutId = setTimeout(() => {
      generateConnections();
    }, 100);

    return () => clearTimeout(timeoutId);
  };

  // ==== quiz logic ====
  const onConnectionClick = (answer: IAnswer, side: "A" | "B") => {
    if (side === "A") {
      if (selectedAnswerB) {
        createConnection(answer.id, selectedAnswerB);
      } else {
        if (selectedAnswerA === answer.id) setSelectedAnswerA("");
        else setSelectedAnswerA(answer.id);
      }
    }

    if (side === "B") {
      if (selectedAnswerA) {
        createConnection(selectedAnswerA, answer.id);
      } else {
        if (selectedAnswerB === answer.id) setSelectedAnswerB("");
        else setSelectedAnswerB(answer.id);
      }
    }
  };

  const createConnection = (answerA: string, answerB: string) => {
    const quizCopy = { ...quiz };
    var connectionsCopy: IConnection[] = [...quizCopy.connections];

    // check if connection already exists
    var alreadyExists = connectionsCopy.find(
      (conn) => conn.idA === answerA && conn.idB === answerB
    );

    if (alreadyExists) {
      var index = connectionsCopy.indexOf(alreadyExists);
      connectionsCopy.splice(index, 1);
    } else {
      connectionsCopy.push({ idA: answerA, idB: answerB });
    }

    quizCopy.connections = connectionsCopy;

    setSelectedAnswerA("");
    setSelectedAnswerB("");

    dispatch(updateSelectedInteraction(quizCopy));
  };

  const onDeleteAnswer = (answer: IAnswer, side: "A" | "B") => {
    const quizcopy = { ...quiz };
    const connectionsCopy = [...quizcopy.connections];

    //delete all connections w/ that answer
    const filtered = connectionsCopy.filter(
      (conn) => conn.idA !== answer.id && conn.idB !== answer.id
    );

    if (side === "A") {
      const af = quizcopy.answersA.filter((a) => answer.id !== a.id);
      quizcopy.answersA = af;
    } else {
      const af = quizcopy.answersB.filter((b) => answer.id !== b.id);
      quizcopy.answersB = af;
    }

    quizcopy.connections = filtered;
    dispatch(updateSelectedInteraction(quizcopy));
  };

  const addAnswerA = () => {
    const quizcopy = { ...quiz };
    const answerscopy = [...quiz.answersA];
    answerscopy.push({
      id: GetNewGuid(),
      text: getNewKey(),
      correct: false,
      assetId: "",
    });

    quizcopy.answersA = answerscopy;
    dispatch(updateSelectedInteraction(quizcopy));
  };

  const onAssetSelect = (
    answer: IAnswer,
    side: "A" | "B",
    asset: AssetDetailsDTO
  ) => {
    const quizcopy = { ...quiz };
    if (side === "A") {
      // side A cannot have image asset
    } else {
      const sideBCopy = [...quizcopy.answersB];
      const index = sideBCopy.indexOf(
        sideBCopy.find((a) => a.id === answer.id)!
      );
      const acopy = { ...answer };
      acopy.assetId = asset.assetId;
      sideBCopy[index] = acopy;
      quizcopy.answersB = sideBCopy;
    }

    dispatch(updateSelectedInteraction(quizcopy));
  };

  const addAnswerB = () => {
    const quizcopy = { ...quiz };
    const answerscopy = [...quiz.answersB];
    answerscopy.push({
      id: GetNewGuid(),
      text: getNewKey(),
      correct: false,
      assetId: "",
    });

    quizcopy.answersB = answerscopy;
    dispatch(updateSelectedInteraction(quizcopy));
  };

  return {
    selectedAnswerA,
    selectedAnswerB,
    onConnectionClick,
    onDeleteAnswer,
    addAnswerA,
    addAnswerB,
    onAssetSelect,
    connections,
  } as const;
}
