import { useDispatch, useSelector } from "react-redux";
import { InteractionType } from "../models/interactions/Interaction";
import { useAddInteraction } from "../features/training_timeline/AddInteractionHook";
import { addInteractionToSelectedTake } from "../app/slices/TrainingSlice";
import usePropHelper from "./PropHelper";
import useValidation from "../features/validation/Validation.hooks";
import {
  addMostRecentInteraction,
  selectSelectedInteractionDefinition,
  selectSelectedInteractionPropStateId,
} from "../app/slices/InteractionPickerSlice";
import { addInteractionWarning } from "../app/slices/ValidationSlice";

export default function useInteractionPicker() {
  const { createInteraction, createPropInteraction } = useAddInteraction();
  const dispatch = useDispatch();
  const interactionDef = useSelector(selectSelectedInteractionDefinition);
  const selectedPropId = useSelector(selectSelectedInteractionPropStateId);

  const { getUsedPropByInstanceId } = usePropHelper();
  const { getInteractionWarnings } = useValidation();

  const addNewInteraction = () => {
    if (interactionDef.type === InteractionType.DynamicInteraction) {
      // get prop that is selected in the interaction picker
      const propDto = getUsedPropByInstanceId(selectedPropId);
      if (
        !propDto ||
        !propDto.metadata ||
        !propDto.metadata.dynamicInteractions
      )
        return;

      // get the matching dynamic interaction
      const dynInteraction = propDto.metadata.dynamicInteractions.find(
        (interaction) => interaction.title === interactionDef.name
      );
      if (!dynInteraction) return;

      // create the dynamic interaction
      const newInteraction = createPropInteraction(
        dynInteraction,
        selectedPropId
      );

      // add the interaction to the selected take
      dispatch(addInteractionToSelectedTake(newInteraction));

      // 4. add warnings to validation slice
      const warnings = getInteractionWarnings(newInteraction);
      dispatch(
        addInteractionWarning({ interactionId: newInteraction.id, warnings })
      );
    } else {
      // 1. create new interaction
      const interaction = createInteraction(interactionDef.type);
      // 2. add interaction to the selected take
      dispatch(addInteractionToSelectedTake(interaction));
      // 3. add the interaction to most recent
      dispatch(addMostRecentInteraction(interactionDef));
      // 4. add warnings to validation slice
      const warnings = getInteractionWarnings(interaction);
      dispatch(
        addInteractionWarning({ interactionId: interaction.id, warnings })
      );
    }
  };

  return { addNewInteraction };
}
