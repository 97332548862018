export default function PiInteractionNumberInputField(props: {
  label: string;
  value: number;
  onChange: (value: number) => void;
}) {
  const { label, value, onChange } = { ...props };

  return (
    <div className="flex flex-col w-full">
      <div className="text-interaction-primary text-base font-bold h-[30px]">
        {label}
      </div>
      <div className="w-full h-[42px] piXl:h-[68px] piLg:h-[58px] flex bg-interaction-softBackground border-b-2 border-solid border-interaction-primary px-[18px] hover:bg-interaction-primary-washed">
        <input
          type="number"
          value={props.value}
          onChange={(e) => onChange(Number(e.target.value))}
          className="w-full text-base font-bold bg-transparent text-interaction-primary focus:outline-none"
        />
      </div>
    </div>
  );
}
