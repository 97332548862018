import { setReferenceLanguage } from "../../app/slices/TrainingSlice";
import { LanguageData } from "../../app/slices/TrainingLocalizationSlice";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import PiMenuButton from "../menu/PiMenuButton";
import { useDispatch, useSelector } from "react-redux";
import { selectReferenceLanguage } from "../../app/slices/TrainingSlice";
import { useTranslation } from "react-i18next";
import useAutotranslation from "./AutoTranslationHook";

export default function TrainingLocalizationTableCellMenu(props: {
  languageData: LanguageData;
}) {
  const { languageData } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const refLanguage = useSelector(selectReferenceLanguage);
  const { translateMultipleMissing, translateMultipleAll } =
    useAutotranslation();

  return (
    <PiPopoverMenu>
      <PiMenuButton
        text="Translate All"
        onClick={() => translateMultipleAll([languageData])}
        label={"AI"}
        disabled={languageData.shortcode === refLanguage}
      />
      <PiMenuButton
        text="Translate Missing"
        onClick={() => translateMultipleMissing([languageData])}
        label={"AI"}
        disabled={languageData.shortcode === refLanguage}
      />
      <PiMenuButton
        text={t("localization_menu.set_as_ref")}
        onClick={() => {
          dispatch(setReferenceLanguage(languageData.shortcode));
        }}
      />
    </PiPopoverMenu>
  );
}
