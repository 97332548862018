import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTrainingLocalizationState } from "../../app/slices/TrainingLocalizationSlice";
import PiIndeterminateCheckbox from "../../components/PiIndeterminateCheckbox";

export default function TrainingLocalizationTableHeader(props: {
  checkedLangs: string[];
  onToggleAll: (checked: boolean) => void;
}) {
  const { t } = useTranslation();
  const { checkedLangs, onToggleAll } = props;

  const locState = useSelector(selectTrainingLocalizationState);

  const checkedState = () => {
    if (checkedLangs.length === 0) return "false";
    if (checkedLangs.length === locState.localizedLanguages.length)
      return "true";
    return "indeterminate";
  };

  return (
    <div className="flex w-full border-b-2 border-solid border-creator-border h-[42px] items-center px-[12px] text-[14px] text-creator-text-sub font-semibold gap-[12px]">
      <div className="w-[40px] h-[32px] flex items-center">
        <PiIndeterminateCheckbox
          checked={checkedState()}
          onToggle={(c) => onToggleAll(c)}
        />
      </div>
      <div className="w-[156px] h-[32px] flex items-center ">
        {t("language")}
      </div>
      <div className="w-[80%] h-[32px] flex items-center">
        {t("translated")}
      </div>
    </div>
  );
}
