import { useSelector } from "react-redux";
import {
  selectAllPropPresets,
  selectSelectedTake3D,
} from "../../../app/slices/TrainingSlice";
import { useMemo } from "react";
import InteractionPickerAccordion from "../InteractionPickerAccordion";
import usePropHelper from "../../../hooks/PropHelper";
import { InteractionDefintion } from "../InteractionDefinitions";
import { DynamicInteractionPickerButton } from "../InteractionPickerButton";
import useSituationHelper from "../../../hooks/SituationHelper.hook";

export default function InteractionPickerPropsAccordions() {
  const take = useSelector(selectSelectedTake3D);

  const { getInvolvedPropInteractions, getUsedPropByInstanceId } =
    usePropHelper();

  const { getAllInvolvedSituationInteractions } = useSituationHelper();
  const propPresets = useSelector(selectAllPropPresets);

  const getPickerAccordion = (
    propStateId: string,
    title: string,
    interactionDefinitions: InteractionDefintion[],
    subtitle?: string
  ) => {
    return (
      <InteractionPickerAccordion
        key={propStateId}
        title={title}
        interactionDefinitions={[]}
        onClose={() => {}}
        subTitle={subtitle}
      >
        {interactionDefinitions.map(
          (interactionDef: InteractionDefintion, index: number) => {
            return (
              <DynamicInteractionPickerButton
                key={index}
                interactionDefinition={interactionDef}
                onClose={() => {}}
                propStateId={propStateId}
              />
            );
          }
        )}
      </InteractionPickerAccordion>
    );
  };

  const accordionsMapped = useMemo(() => {
    if (!take) return;
    const defs = {
      ...getInvolvedPropInteractions(take),
      ...getAllInvolvedSituationInteractions(),
    };

    const accordions = [];

    for (const propStateId in defs) {
      const interactions = defs[propStateId]; // interaction of the prop
      const propDto = getUsedPropByInstanceId(propStateId); // the used prop that contains the DTO + metadata
      const preset = propPresets.find((p) => p.id === propStateId);
      const name = preset?.name; // name of the prop preset
      let subtitle = undefined;
      // add subtitle
      if (preset?.link) {
        const parent = propPresets.find((p) => p.id === preset.link?.parentId)!;
        subtitle = parent.name;
      }

      if (propDto) {
        const title = propDto.propContent.name + " (" + propStateId + ")";
        accordions.push(
          getPickerAccordion(
            propStateId,
            name ?? "no preset found",
            interactions,
            subtitle
          )
        );
      }
    }

    return accordions;
  }, [take]);

  return <div className={"h-full w-full "}>{accordionsMapped}</div>;
}
