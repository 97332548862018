import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedElement,
  updateSelectedInteraction,
  updateSelectedInteractionProperty,
} from "../../app/slices/TrainingSlice";
import Interaction from "../../models/interactions/Interaction";
import { InteractionProperty } from "../../models/InteractionProperty";
import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import InteractionPropertyView from "./InteractionPropertyView";
import InteractionWarningsView from "../validation/InteractionWarningsView";
import PiInputfield from "../../components/PiInputField";
import { useTranslation } from "react-i18next";
import { selectWarnings } from "../../app/slices/ValidationSlice";
import useErrorValidation from "../validation/ErrorValidation.hook";
import InteractionErrorsView from "../validation/error_log/InteractionErrorsView";
import useValidation from "../validation/Validation.hooks";

export default function InteractionPropertiesColumn() {
  const interaction = useSelector(selectSelectedElement) as Interaction;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getErrorsForInteraction } = useErrorValidation();
  const { getWarningsForInteraction } = useValidation();

  const warnings = useMemo(() => {
    return getWarningsForInteraction(interaction.id);
  }, [getWarningsForInteraction, interaction]);

  const errors = useMemo(() => {
    return getErrorsForInteraction(interaction.id);
  }, [interaction, getErrorsForInteraction]);

  const onChange = (interactionProperty: InteractionProperty) => {
    dispatch(updateSelectedInteractionProperty(interactionProperty));
  };

  const views = interaction?.properties?.map((property, index) => {
    return (
      <InteractionPropertyView
        key={index}
        interactionProperty={property}
        onChange={onChange}
      />
    );
  });

  // renaming
  const onRenameInteraction = (value: string) => {
    dispatch(updateSelectedInteraction({ ...interaction, name: value }));
  };

  const renameField = (
    <div className="pb-[12px]">
      <PiInputfield
        label={t("name")}
        value={interaction.name}
        placeholder={"Interaction name"}
        onChange={onRenameInteraction}
      />
    </div>
  );

  return (
    <div className="interactionpropertiescontent-root">
      <PropertiesColumnHeader text={interaction?.name} />
      <div className="px-[14px] py-[20px] flex flex-col gap-[8px]">
        {errors?.length > 0 && <InteractionErrorsView errors={errors} />}
        {warnings?.length > 0 && (
          <InteractionWarningsView warnings={warnings} />
        )}
        <div>
          {renameField}
          {views}
        </div>
      </div>
    </div>
  );
}
