import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { Button, Dialog, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTrainingName } from "../../app/slices/TrainingSlice";
import { PublishStep, usePublishCourse } from "../../hooks/PublishCourseHook";
import CourseTranslationCard from "./CourseTranslationCard";
import PublishCompleteCard from "./PublishCompleteCard";
import "./PublishDialog.css";
interface PublishDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function PublishDialog(props: PublishDialogProps) {
  const { open, onClose } = { ...props };
  const trainingName = useSelector(selectTrainingName);
  const {
    currentStep,
    getCourseDetails,
    translations,
    onChangeDto,
    publishTraining,
    playUrl,
    isInputInvalid,
  } = usePublishCourse();
  const height = "90vh";
  const width = "70vw";
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      getCourseDetails();
    }
  }, [open]);

  const translationCards = translations.map((t) => {
    return (
      <CourseTranslationCard
        key={t.languageId}
        lang={t.languageId}
        shortcode={t.languageId}
        dto={t}
        onChange={onChangeDto}
      />
    );
  });

  const fillInCourseDetails = (
    <>
      <div className="publishdialog-course-translations pit-creator-scrollbar">
        {translationCards}
      </div>
      <div className="publishdialog-button">
        <Button
          variant="piBlue"
          onClick={publishTraining}
          disabled={isInputInvalid}
        >
          {t("publish")}
        </Button>
      </div>
    </>
  );

  const finishedCard = (
    <>
      <div className="publishdialog-completed-card">
        <PublishCompleteCard courseName={translations[0]?.name} />
      </div>
      <div className="publishdialog-button">
        <Button variant="piBlue" onClick={onClose}>
          {t("finish")}
        </Button>
      </div>
    </>
  );

  const loadingIndicator = (
    <div className="publishdialog-loading-indicator">
      <div>
        <CircularProgress color="inherit" size="100px" />
      </div>
    </div>
  );

  const publishingIndicator = (
    <>
      <div className="publishdialog-loading-indicator">
        <div>
          <CircularProgress color="inherit" size="100px" />
        </div>
        <div className="publishdialog-loading-publish-icon">
          <PublishIcon fontSize="inherit" color="inherit" />
        </div>

        <div className="publishdialog-loading-publish-text">
          Publishing Course...
        </div>
      </div>
    </>
  );

  const publishingFailed = (
    <>
      <div className="publishdialog-failed">
        <div className="publishdialog-failed-icon">
          <ReportProblemOutlinedIcon fontSize="inherit" />
        </div>
        <div className="publishdialog-failed-text">
          {t("publish_page.failed")}
        </div>
      </div>
      <div className="publishdialog-button">
        <Button variant="piBlue" onClick={onClose}>
          {t("finish")}
        </Button>
      </div>
    </>
  );

  const publishingStillProcessing = (
    <>
      <div className="publishdialog-failed">
        <div className="publishdialog-processing-icon">
          <ReportProblemOutlinedIcon fontSize="inherit" />
        </div>
        <div className="publishdialog-processing-text">
          {t("publish_page.processing")}
        </div>
      </div>
      <div className="publishdialog-button">
        <Button variant="piBlue" onClick={onClose}>
          {t("finish")}
        </Button>
      </div>
    </>
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case PublishStep.Initialize:
        return loadingIndicator;
      case PublishStep.EnterCourseDetails:
        return fillInCourseDetails;
      case PublishStep.Submitting:
        return publishingIndicator;
      case PublishStep.Finished:
        return finishedCard;
      case PublishStep.Failed:
        return publishingFailed;
      case PublishStep.Processing:
        return publishingStillProcessing;
    }
  };

  const dialog = (
    <Dialog
      open={open}
      maxWidth={"xs"}
      fullWidth
      PaperProps={{
        style: {
          minHeight: height,
          maxHeight: height,
          minWidth: width,
          maxWidth: width,
          borderRadius: "10px",
        },
      }}
    >
      <div className="publishdialog-closebutton">
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="publishdialog-root">
        <div className="publishdialog-banner">
          <img src="/logo_verticaal.png" loading="lazy" />
        </div>
        <div className="publishdialog-body">
          <div className="publishdialog-content">
            <div className="publishdialog-title">
              <span>{t("publish")}</span>
              <div className="publishdialog-training-name">
                {" "}
                | {trainingName}
              </div>
            </div>
            {renderCurrentStep()}
          </div>
        </div>
      </div>
    </Dialog>
  );

  return <>{dialog}</>;
}
