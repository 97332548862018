import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectRefreshToken,
  selectToken,
} from "../../app/slices/AuthenticationSlice";
import {
  selectTestData,
  selectTrainingType,
  TrainingType,
} from "../../app/slices/TrainingSlice";
import { selectCurrentOrganization } from "../../app/slices/UserSlice";
import "./GameWindow.css";
import classNames from "classnames";
import {
  selectCurrentEnvironmentId,
  selectCurrentGameEnvironment,
} from "../../app/slices/EnvironmentSlice";

export default function GameWindow() {
  const baseUrl = process.env.REACT_APP_PLAYIT_GAMEPREVIEWURL;
  const token = useSelector(selectToken);
  const refreshToken = useSelector(selectRefreshToken);
  const org = useSelector(selectCurrentOrganization);
  const [url, setUrl] = useState<string>("");
  const windowRef = useRef<HTMLIFrameElement>(null);

  const testData = useSelector(selectTestData);
  const trainingType = useSelector(selectTrainingType);
  const environmentName = useSelector(selectCurrentGameEnvironment);
  const environmentGuid = useSelector(selectCurrentEnvironmentId);

  useEffect(() => {
    window.addEventListener("message", onMessageReceived);
    return () => window.removeEventListener("message", onMessageReceived);
  });

  useEffect(() => {
    if (trainingType === TrainingType.Training360) {
      setUrl(
        baseUrl +
          "token=" +
          token +
          "&refreshtoken=" +
          refreshToken +
          "&creator=360&orgid=" +
          org?.id
      );
    } else if (trainingType === TrainingType.Training3D) {
      setUrl(
        baseUrl +
          "token=" +
          token +
          "&refreshtoken=" +
          refreshToken +
          "&creator=simple&orgid=" +
          org?.id +
          "&environmentName=" +
          environmentName +
          "&environmentGuid=" +
          environmentGuid
      );
    }
  }, [token, refreshToken]);

  const onMessageReceived = async (event: MessageEvent) => {
    console.log("message received from: " + event.origin);
    console.log("source url: ", url);
    if (windowRef.current) {
      if (testData !== "")
        windowRef.current.contentWindow?.postMessage(testData, "*");
    }
  };

  return (
    <div
      className={classNames({
        "gamewindow-root": true,
        "rounded-[10px]": trainingType === TrainingType.Training360,
      })}
    >
      <iframe ref={windowRef} src={url}></iframe>
    </div>
  );
}
