import AddIcon from "@mui/icons-material/Add";
import React from "react";
import "./PiBasicQuizAddButton.css";

interface PiBasicQuizAddButtonProps {
  onClick: () => void;
  text?: string;
}

const PiBasicQuizAddButton: React.FC<PiBasicQuizAddButtonProps> = ({
  onClick,
  text,
}) => {
  return (
    <div className="pibasicquizaddbutton-root" onClick={() => onClick()}>
      <div className="pibasicquizaddbutton-icon">
        <AddIcon fontSize="inherit" color="inherit" />
      </div>

      <div className="pibasicquizaddbutton-text">{text}</div>
    </div>
  );
};

PiBasicQuizAddButton.defaultProps = {
  text: "Add Answer",
};

export default PiBasicQuizAddButton;
