import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import React, { useState } from "react";
import { AssetDetailsDTO, AssetKey } from "../../../dto/AssetDTO";
import { useAssetPreview } from "../../../hooks/AssetPreviewHook";
import FileExplorerDialog from "../../file_explorer/FileExplorerDialog";
import { ConnectQuizAnswerProps } from "../connect_quiz/ConnectQuizAnswer";
import "./ConnectGraphicsQuizAnswer.css";
import classNames from "classnames";

export default function ConnectQuizGraphicsAnswer(
  props: ConnectQuizAnswerProps
) {
  const {
    answer,
    side,
    isSelected,
    onConnectionClick,
    canDelete,
    onDelete,
    horizontal,
    onAssetSelect,
  } = { ...props };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getAssetPreview } = useAssetPreview(answer.assetId);
  const onSelect = (asset: AssetDetailsDTO) => {
    setIsOpen(false);
    //handleAnswerChange(answer.id, "assetId", asset.id);
    if (onAssetSelect) onAssetSelect(answer, side, asset);
  };

  const dotButton = (
    <div
      className={classNames({
        "connectquizgraphicsanswer-dot-button": horizontal,
      })}
    >
      <button
        className={
          answer.id +
          " connectquizanswer-button " +
          (isSelected ? "connectquizanswer-selected" : "")
        }
        onClick={() => onConnectionClick(answer, side)}
      ></button>
    </div>
  );

  const deleteButton = (
    <div
      className="connectquizgraphicsanswer-delete-button"
      onClick={() => onDelete(answer, side)}
    >
      <ClearIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const selectImageBody = (
    <div
      className={classNames({
        "connectquizgraphicsanswer-select-image": horizontal,
        "w-full h-full flex justify-center items-center text-[32px] text-interaction-primary cursor-pointer":
          !horizontal,
      })}
      onClick={() => setIsOpen(true)}
    >
      <AddPhotoAlternateOutlinedIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const imgDisplayBody = (
    <div
      className={classNames({
        "connectquizgraphicsanswer-display-image": horizontal,
        "relative flex justify-center items-center w-[100%] h-[100%]":
          !horizontal,
      })}
    >
      <img
        className="absolute max-w-full max-h-full p-[12px]"
        src={getAssetPreview()}
      />
    </div>
  );

  const selectImageButton = (
    <div
      className={classNames({
        "connectquizgraphicsanswer-select-image-button": horizontal,
        "w-[32px] h-[32px] absolute bottom-[8px] right-[8px] bg-interaction-primary rounded-full flex justify-center items-center text-white cursor-pointer hover:opacity-75":
          !horizontal,
      })}
      onClick={() => setIsOpen(true)}
    >
      <ImageSearchOutlinedIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const imgBody = answer.assetId ? (
    <>
      {imgDisplayBody}
      {selectImageButton}
    </>
  ) : (
    selectImageBody
  );

  const imgDisplay = (
    <div
      className={classNames({
        "bg-interaction-primary-washed": true,
        " connectquizgraphicsanswer-image-wrapper ": horizontal,
        "border-[2px] border-solid border-interaction-primary rounded-[5px] h-[100%] min-h-[100%] max-h-[100%] w-full ":
          !horizontal,
      })}
    >
      {canDelete && deleteButton}
      {imgBody}
    </div>
  );

  return (
    <div
      className={classNames({
        "w-full h-full": true,
        "connectquizgraphicsanswer-root": horizontal,
        "flex justify-center items-center gap-[16px] h-full w-full relative ":
          !horizontal,
      })}
    >
      {dotButton}
      {imgDisplay}
      <FileExplorerDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        type={AssetKey.Image}
        onSelect={onSelect}
      />
    </div>
  );
}
