import { useSelector } from "react-redux";
import {
  selectActors,
  selectAllPropPresets,
  selectSelectedElement,
  selectSelectedTake3D,
  selectTakes,
  selectTrainingType,
  TrainingType,
} from "../../app/slices/TrainingSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { selectIsLocalizationInitialized } from "../../app/slices/TrainingLocalizationSlice";
import Interaction from "../../models/interactions/Interaction";
import { getInteractionFromFactory } from "../interaction_factory/InteractionFactory";
import Take from "./../../models/Take";
import {
  InitializeErrors,
  selectErrors,
} from "../../app/slices/ValidationSlice";

export default function useErrorValidation() {
  const selectedTake3D = useSelector(selectSelectedTake3D);
  const selectedTake360 = useSelector(selectSelectedTake3D);
  const trainingType = useSelector(selectTrainingType);
  const allTakes = useSelector(selectTakes);
  const selectedInteraction = useSelector(selectSelectedElement);
  const initialized = useSelector(selectIsLocalizationInitialized);
  const actors = useSelector(selectActors);
  const allPropPresets = useSelector(selectAllPropPresets);
  const allErrors = useSelector(selectErrors);

  const dispatch = useDispatch();

  // validate when loading a training
  useEffect(() => {
    if (!initialized) return;
    validateArrayOfTakes();
  }, [initialized]);

  // we need to add re-calculate errors when a take changes & when the interaction changes
  useEffect(() => {
    validateArrayOfTakes();
  }, [allTakes, selectedInteraction, selectedTake360, selectedTake3D]);

  const getInteractionErrors = (interaction: Interaction, parentTake: Take) => {
    const entity = getInteractionFromFactory(interaction.type);
    const take =
      trainingType === TrainingType.Training3D
        ? selectedTake3D
        : selectedTake360;

    if (!take) return [];

    return (
      entity.ValidateErrors?.(
        interaction,
        parentTake,
        take,
        actors,
        allPropPresets
      ) ?? []
    );
  };

  const validateArrayOfTakes = () => {
    const errors: Record<string, string[]> = {};
    allTakes.forEach((take) => {
      take.interactions.forEach((interaction) => {
        errors[interaction.id] = getInteractionErrors(interaction, take);
      });
    });

    dispatch(InitializeErrors(errors));
  };

  const getErrorsForInteraction = (interactionId: string) => {
    return allErrors[interactionId] ?? [];
  };

  return { getErrorsForInteraction } as const;
}
