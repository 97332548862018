import { useMemo, useState } from "react";
import ErrorLogCard from "./ErrorLogCard";
import useErrorLogColumn from "./ErrorLogColumn.hook";
import {
  ErrorToggleButton,
  WarningToggleButton,
} from "./ErrorLogFilterButtons";
import LogData from "./LogData";
import { setSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { useDispatch } from "react-redux";

export default function ErrorLogColumn() {
  const { getLogData } = useErrorLogColumn();
  const dispatch = useDispatch();

  const logMemo = useMemo(() => getLogData(), [getLogData]);
  const countErrors = logMemo.filter((log) => log.logLevel === "error").length;
  const countWarnings = logMemo.length - countErrors;

  const [errorsEnabled, setErrorsEnabled] = useState(true);
  const [warningsEnabled, setWarningEnabled] = useState(false);

  const [selectedKey, setSelectedKey] = useState<string>("");

  const onClick = (key: string, log: LogData) => {
    setSelectedKey(key);
    dispatch(setSelectedInteraction(log.interactionId));
  };

  const buttons = (
    <div className="flex gap-[12px] py-[6px]">
      <ErrorToggleButton
        onClick={() => setErrorsEnabled(!errorsEnabled)}
        count={countErrors}
        enabled={errorsEnabled}
      />
      <WarningToggleButton
        onClick={() => setWarningEnabled(!warningsEnabled)}
        count={countWarnings}
        enabled={warningsEnabled}
      />
    </div>
  );

  const views = logMemo.map((log, index) => {
    if (log.logLevel === "error" && !errorsEnabled) return null;
    if (log.logLevel === "warning" && !warningsEnabled) return null;
    const uniqueKey = log.interactionId + log.message;
    return (
      <ErrorLogCard
        key={uniqueKey}
        log={log}
        isSelected={selectedKey === uniqueKey}
        onClick={() => onClick(uniqueKey, log)}
      />
    );
  });

  return (
    <div className="flex flex-col w-full h-full p-[12px] gap-[8px] bg-white">
      {buttons}
      <div className="flex flex-col items-center">{views}</div>
    </div>
  );
}
