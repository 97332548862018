import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PropMetadata } from "../../dto/PropMetadata";
import { RootState } from "../Store";
import { TranslatedPropCategoryDTO } from "../../dto/TranslatedPropCategoryDTO";
import { TranslatedPropDTO } from "../../dto/TranslatedPropDTO";
import { PropInteractionDTO } from "../../dto/PropInteractionDTO";
import { TranslatedPropContentDTO } from "../../dto/TranslatedPropContentDTO";
import { UsedProp } from "../../models/UsedProp";

interface PropLibraryState {
  isInitialized: boolean;
  selectedPropId: string;
  categories: TranslatedPropCategoryDTO[];
  interactions: PropInteractionDTO[];
  filter: string;
  filteredCategory: string;
  pagedProps: TranslatedPropDTO[];
  selectedPropContent: TranslatedPropContentDTO | null;
  selectedPropMetadata: PropMetadata | null;
  usedProps: Record<string, UsedProp>; // [propId: string]: UsedProp;
}

const initialPropLibraryState: PropLibraryState = {
  isInitialized: false,
  selectedPropId: "",
  categories: [],
  interactions: [],
  filter: "",
  filteredCategory: "",
  pagedProps: [],
  selectedPropContent: null,
  selectedPropMetadata: null,
  usedProps: {},
};

export const propLibrarySlice = createSlice({
  name: "propLibrary",
  initialState: initialPropLibraryState,
  reducers: {
    initializePropLibrary: (
      state,
      action: PayloadAction<{
        totalCount: number;
        firstPage: TranslatedPropDTO[];
      }>
    ) => {
      const { totalCount, firstPage } = action.payload;
      state.isInitialized = true;
      state.pagedProps = new Array(totalCount).fill({
        id: "unloaded",
        name: "unloaded",
        downloadUri: "",
        categories: [],
      } as TranslatedPropDTO);

      for (let i = 0; i < action.payload.firstPage.length; i++) {
        state.pagedProps[i] = action.payload.firstPage[i];
      }
    },
    addPagedProps: (
      state,
      action: PayloadAction<{ pageNumber: number; assets: TranslatedPropDTO[] }>
    ) => {
      var si = action.payload.pageNumber * 10;
      if (si > state.pagedProps.length) return;

      for (var i = 0; i < action.payload.assets.length; i++) {
        state.pagedProps[si + i] = action.payload.assets[i];
      }
    },
    setSelectedProp: (state, action: PayloadAction<string>) => {
      state.selectedPropId = action.payload;
    },
    setPropCategories: (
      state,
      action: PayloadAction<TranslatedPropCategoryDTO[]>
    ) => {
      state.categories = action.payload;
    },
    setPropInteractions: (
      state,
      action: PayloadAction<PropInteractionDTO[]>
    ) => {
      state.interactions = action.payload;
    },
    setPropFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    setPropCategoryFilter: (state, action: PayloadAction<string>) => {
      state.filteredCategory = action.payload;
    },
    setSelectedPropContent: (
      state,
      action: PayloadAction<{
        content: TranslatedPropContentDTO;
        metadata: PropMetadata;
      }>
    ) => {
      state.selectedPropContent = action.payload.content;
      state.selectedPropMetadata = action.payload.metadata;
    },
    addSelectedPropAsUsedProp: (state) => {
      if (!state.selectedPropContent || !state.selectedPropMetadata) return;

      state.usedProps[state.selectedPropContent.id] = {
        propContent: state.selectedPropContent,
        metadata: state.selectedPropMetadata,
      };
    },
    addSituationAsUsedProp: (
      state,
      action: PayloadAction<{
        id: string;
        propContent: TranslatedPropContentDTO;
        metadata: PropMetadata;
      }>
    ) => {
      state.usedProps[action.payload.id] = {
        propContent: action.payload.propContent,
        metadata: action.payload.metadata,
      };
    },
    addUsedProps: (state, action: PayloadAction<UsedProp[]>) => {
      for (let i = 0; i < action.payload.length; i++) {
        state.usedProps[action.payload[i].propContent.id] = action.payload[i];
      }
    },
  },
});

export const {
  initializePropLibrary,
  addPagedProps,
  setSelectedProp,
  setPropCategories,
  setPropInteractions,
  setPropFilter,
  setPropCategoryFilter,
  setSelectedPropContent,
  addSelectedPropAsUsedProp,
  addUsedProps,
  addSituationAsUsedProp,
} = propLibrarySlice.actions;

export const selectIsPropSliceInitialized = (state: RootState) =>
  state.propLibrary.isInitialized;
export const selectPropCategories = (state: RootState) =>
  state.propLibrary.categories;
export const selectPropInteractions = (state: RootState) =>
  state.propLibrary.interactions;
export const selectPropFilter = (state: RootState) => state.propLibrary.filter;
export const selectFilteredCategory = (state: RootState) =>
  state.propLibrary.filteredCategory;
export const selectSelectedPropContent = (state: RootState) =>
  state.propLibrary.selectedPropContent;
export const selectProps = (state: RootState) => state.propLibrary.pagedProps;
export const selectUsedProps = (state: RootState) =>
  state.propLibrary.usedProps;

export const selectSelectedProp = (state: RootState) =>
  state.propLibrary.pagedProps.find((prop, index) => {
    return prop.id === state.propLibrary.selectedPropId;
  });
export const selectSelectedPropMetadata = (state: RootState) =>
  state.propLibrary.selectedPropMetadata;
export const selectSelectedPropId = (state: RootState) =>
  state.propLibrary.selectedPropId;
export const selectPropById = (state: RootState, propId: string) => {
  //return state.propLibrary.props.find(prop => prop.id === propId);
  return state.propLibrary.pagedProps.find((prop) => prop.id === propId);
};

export default propLibrarySlice.reducer;
