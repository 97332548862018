import { v4 as uuidv4 } from "uuid";
import { ActorState } from "../../../models/ActorState";
import Interaction, {
  InteractionType,
  Marker,
} from "../../../models/interactions/Interaction";
import { InteractionProperty } from "../../../models/InteractionProperty";
import Take360, { CreateTake360Copy } from "../../../models/Take360";
import {
  TrainingState,
  selectInteractionIndexById,
  selectSelectedTake,
  selectTakeIndexById,
} from "../TrainingSlice";
import { TrainingSliceStrategy } from "./TrainingSliceStrategy";

export const TrainingSliceStrategy360: TrainingSliceStrategy = {
  setSelectedTake(state, takeId) {
    if (!state.takes360.find((take) => take.id === takeId)) return;

    state.selectedTake = takeId;
    state.selectedElementId = takeId;
    state.selectedElementIsInteraction = false;
    state.selectedElementIsTake = true;
  },
  addNewTake(state, name, assetId) {
    const newTake: Take360 = {
      assetId: assetId!,
      name: name!,
      id: uuidv4(),
      interactions: [],
      rotation: 0,
      display360: false,
      originalRotation: {
        id: uuidv4(),
        name: "Teleport Marker",
        type: InteractionType.Marker,
        xPos: 0.5,
        yPos: 0.5,
      },
      teleportMarker: {
        id: uuidv4(),
        name: "Teleport Marker",
        type: InteractionType.TeleportMarker,
        xPos: 0.5,
        yPos: 0.5,
      },
      automaticTransition: false,
      is360Image: true,
      isExpanded: true,
    };
    state.takes360.push(newTake);
    state.selectedTake = newTake.id; // automatically select new take
    state.selectedElementId = newTake.id;
    state.isDirty = true;
  },
  addTakeCopy(state, take) {
    const originalTake = take as Take360;

    const newTake = CreateTake360Copy(
      originalTake.name,
      originalTake.assetId,
      originalTake.rotation,
      { ...originalTake.originalRotation },
      { ...originalTake.teleportMarker },
      originalTake.automaticTransition,
      originalTake.is360Image,
      originalTake.display360
    );

    const indexSelectedTake: number = state.selectedElementIsTake
      ? state.takes360.findIndex((t) => t.id === state.selectedElementId)
      : -1;

    if (indexSelectedTake !== -1) {
      state.takes360.splice(indexSelectedTake + 1, 0, newTake);
    } else {
      state.takes360.push(newTake);
    }

    state.selectedTake = newTake.id; // automatically select new take
    state.selectedElementId = newTake.id;
    state.isDirty = true;
  },
  setSelectedInteraction(state, interactionId) {
    const parent = state.takes360.find(
      (take) =>
        take.interactions.findIndex(
          (interaction) => interaction.id === interactionId
        ) !== -1 || take.teleportMarker.id === interactionId
    );

    if (parent) {
      state.selectedTake = parent.id;
      state.selectedElementId = interactionId;
      state.selectedElementIsTake = false;
      state.selectedElementIsInteraction = true;
    }
  },
  addInteractionToTake(state, interaction: Interaction, takeId) {
    const take = state.takes360.find((take) => take.id === takeId);
    if (take) {
      take.interactions.push(interaction);
      state.selectedTake = takeId;
      state.selectedElementId = interaction.id;
      state.selectedElementIsTake = false;
      state.selectedElementIsInteraction = true;
      state.isDirty = true;
    }
  },
  addInteractionToSelectedTake(state, interaction) {
    const take: Take360 =
      state.takes360[selectTakeIndexById(state.takes360, state.selectedTake)];
    take.interactions.push(interaction);
    state.selectedElementId = interaction.id;
    state.selectedElementIsTake = false;
    state.selectedElementIsInteraction = true;
    state.isDirty = true;
  },
  addInteractionCopy(state, interaction) {
    const take: Take360 | undefined = state.takes360.find(
      (t) => t.id === state.selectedTake
    );

    if (!take) {
      return;
    }

    if (state.selectedElementIsInteraction) {
      const interactionIndex = take.interactions.findIndex(
        (i) => i.id === state.selectedElementId
      );
      take.interactions.splice(interactionIndex + 1, 0, interaction);
    } else {
      take.interactions.push(interaction);
    }

    state.selectedElementId = interaction.id;
    state.selectedElementIsTake = false;
    state.selectedElementIsInteraction = true;
    state.isDirty = true;
  },
  deleteInteraction(state, interaction) {
    // todo: remove keys here
    const parent = state.takes360.find((take) =>
      take.interactions.find((i) => i.id === interaction.id)
    );
    if (parent) {
      parent.interactions.splice(
        parent.interactions.findIndex((i) => i.id === interaction.id),
        1
      );
      state.isDirty = true;
    }
  },
  updateSelectedTake(state, take) {
    const ti = selectTakeIndexById(state.takes360, take.id);
    state.takes360[ti] = { ...(take as Take360) };
    state.isDirty = true;
  },
  setTakes(state, takes) {
    state.takes360 = takes as Take360[];
    if (state.takes360.length > 0) {
      state.selectedTake = state.takes360[0].id;
      state.selectedElementId = state.takes360[0].id;
    }
  },
  selectSelectedElement(state) {
    // take 360
    const take = selectSelectedTake(state);

    if (state.training.selectedElementIsTake) {
      return take;
    } else {
      if (take?.teleportMarker.id === state.training.selectedElementId)
        return take.teleportMarker;

      const interaction = take?.interactions.find(
        (interaction) => interaction.id === state.training.selectedElementId
      );

      return interaction;
    }
  },
  updateSelectedInteraction(state, interaction) {
    const ti = selectTakeIndexById(state.takes360, state.selectedTake);

    if (state.takes360[ti].teleportMarker.id === interaction.id) {
      state.takes360[ti].teleportMarker = { ...(interaction as Marker) };
    } else if (state.takes360[ti].originalRotation.id === interaction.id) {
      state.takes360[ti].originalRotation = { ...(interaction as Marker) };
    } else {
      const si = selectInteractionIndexById(state.takes360[ti], interaction.id);
      state.takes360[ti].interactions[si] = { ...interaction };
    }

    state.isDirty = true;
  },
  updateSelectedInteractionProperty: function (
    state: TrainingState,
    interactionProperty: InteractionProperty
  ): void {
    const ti = selectTakeIndexById(state.takes360, state.selectedTake);
    const si = selectInteractionIndexById(
      state.takes360[ti],
      state.selectedElementId
    );

    const properties = state.takes360[ti].interactions[si].properties!;
    const pi = properties.findIndex((p) => p.name === interactionProperty.name);
    properties[pi] = interactionProperty;

    state.takes360[ti].interactions[si].properties = [...properties];

    state.isDirty = true;
  },
  deleteTake: (state, takeId) => {
    const newTakes = state.takes360.filter((take) => take.id !== takeId);
    state.takes360 = newTakes;

    // if there is another take, select that one
    if (state.takes360.length > 0) {
      state.selectedTake = state.takes360[0].id;
      state.selectedElementId = state.takes360[0].id;
      state.selectedElementIsTake = true;
      state.selectedElementIsInteraction = false;
    }
    state.isDirty = true;
  },
  reorderTakes: (state, startIndex, endIndex) => {
    const [removed] = state.takes360.splice(startIndex, 1);
    state.takes360.splice(endIndex, 0, removed);
    state.isDirty = true;
  },
  reorderInteractions: (
    state,
    startTakeId,
    destinationTakeId,
    startIndex,
    endIndex
  ) => {
    const startTake =
      state.takes360[selectTakeIndexById(state.takes360, startTakeId)];
    const destTake =
      state.takes360[selectTakeIndexById(state.takes360, destinationTakeId)];
    const [removed] = startTake.interactions.splice(startIndex, 1);
    destTake.interactions.splice(endIndex, 0, removed);

    state.selectedTake = destTake.id;
    state.isDirty = true;
  },
  updateSelectedTakeProperty: function (
    state: TrainingState,
    takeProperty: InteractionProperty
  ): void {
    throw new Error("Function not implemented.");
  },
  selectSelectedElementType: (state: TrainingState) => {
    // take
    const selectedTake = state.takes360.find(
      (take) => take.id === state.selectedElementId
    );
    if (selectedTake) return "take";

    // interaction
    const selectedInteraction = state.takes360.find((take) =>
      take.interactions.find(
        (interaction) => interaction.id === state.selectedElementId
      )
    );
    if (selectedInteraction) return "interaction";

    return undefined;
  },
  updateSelectedActorState: function (
    state: TrainingState,
    actorState: ActorState
  ): void {
    throw new Error("Function not implemented.");
  },
  addPropToSelectedTake(
    state: TrainingState,
    propId: string,
    locationId: string
  ): void {
    throw new Error("Function not implemented.");
  },
  removePropFromSelectedTake(state: TrainingState, id: string): void {
    throw new Error("Function not implemented.");
  },
  addSituationToTakeLocation(
    state: TrainingState,
    situationId: string,
    takeLocationId: string
  ): void {
    throw new Error("Function not implemented.");
  },
  removeSituationFromTakeLocation(state, takeLocationId) {
    throw new Error("Function not implemented.");
  },
  addAttachmentToTake(state, actorId, propId) {
    throw new Error("Function not implemented.");
  },
};
