import { Prop } from "../../models/Prop";
import usePropHelper from "../../hooks/PropHelper";
import { useMemo } from "react";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
export default function PropPresetPopoverCard(props: {
  prop: Prop;
  onClick: () => void;
}) {
  const { prop, onClick } = { ...props };
  const { getPropDTO } = usePropHelper();
  const dto = useMemo(() => getPropDTO(prop.propId), [prop.propId]);

  return (
    <div
      className={
        "h-[46px] flex gap-[10px] py-[6px] px-[12px] cursor-pointer rounded-[5px] hover:bg-interaction-text-hoverBackground"
      }
      onClick={onClick}
    >
      <div
        className={
          "my-auto w-[36px] h-[36px] p-[4px] rounded-full border-solid border-[2px] border-creator-primary text-[20px] flex items-center justify-center text-creator-text-sub"
        }
      >
        {/* <img className={"w-full h-full object-contain"} src={dto.propContent.downloadUri} alt={prop.id}/> */}
        <ViewInArIcon color="inherit" fontSize="inherit" />
      </div>
      <span
        className={
          "my-auto text-[14px] text-creator-text-sub truncate font-medium"
        }
      >
        {prop.name}
      </span>
    </div>
  );
}
