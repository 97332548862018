import PropPresetPopover from "../../prop_presets/PropPresetPopover";
import InteractionPointPreview, {
  EmptyPropPointStrategy,
  PropPointStrategy,
} from "../InteractionPointPreview";
import usePropPointPreview from "../PropPointPreview.hook";
import useSituationPropPoint from "./SituationPropPoint.hook";

import {
  addPropStateToSelectedTake,
  removePropStateFromSelectedTake,
} from "../../../app/slices/TrainingSlice";
import { useDispatch } from "react-redux";
export default function SituationPropPoint(props: {
  situationId: string;
  positionId: string;
  x: number;
  y: number;
  disabled: boolean;
}) {
  const dispatch = useDispatch();
  const { x, y, disabled, positionId, situationId } = { ...props };
  const { doesSituationNodeHaveChild, getSpawnedProp } =
    useSituationPropPoint();
  const { openPropLibrary } = usePropPointPreview();

  const hasChild = doesSituationNodeHaveChild(positionId);
  const myPropTakeState = getSpawnedProp(situationId + "/" + positionId);

  const onAddProp = (instanceId: string, propId: string) => {
    dispatch(
      addPropStateToSelectedTake({
        instanceId: instanceId,
        positionId: situationId + "/" + positionId,
      })
    );
  };

  const onDeletePropFromPosition = () => {
    if (!myPropTakeState) return;
    dispatch(removePropStateFromSelectedTake(myPropTakeState.id));
  };

  const popoverContent = (
    <PropPresetPopover
      canDelete={myPropTakeState !== undefined}
      onClick={onAddProp}
      onDelete={onDeletePropFromPosition}
      includedGrabbedProps={false}
      onAddNewPropClick={() => openPropLibrary(situationId + "/" + positionId)}
    />
  );

  return (
    <>
      <InteractionPointPreview
        x={x}
        y={y}
        strategy={
          hasChild || myPropTakeState
            ? PropPointStrategy
            : EmptyPropPointStrategy
        }
        disabled={disabled}
        popoverContent={!hasChild ? popoverContent : undefined}
      />
    </>
  );
}
