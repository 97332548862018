import { useSelector } from "react-redux";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { ILookat, ILookatConfig } from "../../../models/interactions/Lookat";
import { useDispatch } from "react-redux";
import MessageBubbleActorDropdown from "../message_bubble/MessageBubbleActorDropdown";
import ActorPropDropdown from "./ActorPropDropdown";
import PiBasicQuizAddButton from "../components/PiBasicQuizAddButton";
import PiDeleteButton from "../../../components/PiDeleteButton";

export default function LookatPreviewBody() {
  const interaction = useSelector(selectSelectedElement) as ILookat;
  const dispatch = useDispatch();

  // TODO: exclude npcs that are already being used

  const actorsToExclude = interaction.lookats.map(
    (lookat) => lookat.sourceNpcId
  );

  const addLookatEntry = () => {
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        lookats: [...interaction.lookats, { sourceNpcId: "", targetId: "" }],
      } as ILookat)
    );
  };

  const deleteEntry = (index: number) => {
    const lookats = interaction.lookats.filter((_, i) => i !== index);
    dispatch(updateSelectedInteraction({ ...interaction, lookats } as ILookat));
  };

  const updateLookat = (index: number, lookat: ILookatConfig) => {
    const lookats = [...interaction.lookats];
    lookats[index] = lookat;
    dispatch(updateSelectedInteraction({ ...interaction, lookats } as ILookat));
  };

  const views = interaction.lookats.map((lookat, index) => {
    return (
      <div className="flex w-full gap-[12px]">
        <MessageBubbleActorDropdown
          label="Actor"
          onClick={(id) => updateLookat(index, { ...lookat, sourceNpcId: id })}
          selectedTargetActorId={lookat.sourceNpcId}
          excludeActors={actorsToExclude}
        />
        <ActorPropDropdown
          label="Target"
          onClick={(id) => updateLookat(index, { ...lookat, targetId: id })}
          selectedObjectId={lookat.targetId}
        />
        {interaction.lookats.length > 1 && (
          <div className="pt-[32px]">
            <PiDeleteButton onClick={() => deleteEntry(index)} />
          </div>
        )}
      </div>
    );
  });

  const addButton = (
    <div className="h-[64px]">
      <PiBasicQuizAddButton text="Add Lookat" onClick={addLookatEntry} />
    </div>
  );

  return (
    <div className="h-full w-full flex flex-col gap-[24px]">
      <div className="h-[calc(100%-64px)] overflow-y-auto pit-scrollbar flex flex-col gap-y-[12px]">
        {views}
      </div>
      {addButton}
    </div>
  );
}
