import { useSelector } from "react-redux";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import PiInteractionNumberInputField from "../../../components/interaction/PiInteractionNumberInputField";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import { IWait } from "../../../models/interactions/Wait";
import { useDispatch } from "react-redux";

export default function WaitPreview() {
  const dispatch = useDispatch();
  const wait = useSelector(selectSelectedElement) as IWait;

  const updateSeconds = (seconds: number) => {
    dispatch(updateSelectedInteraction({ ...wait, seconds } as IWait));
  };

  const body = (
    <div className="flex flex-col w-full h-full">
      <PiInteractionNumberInputField
        label="Seconds"
        value={wait.seconds}
        onChange={updateSeconds}
      />
    </div>
  );

  return (
    <PiPopupWindowPreview
      title={<PiPopupWindowStaticTitle title="Wait" />}
      body={body}
      size="small"
    />
  );
}
