import RedoOutlinedIcon from "@mui/icons-material/RedoOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import StopIcon from "@mui/icons-material/Stop";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import { Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentLanguage,
  selectLocalizedLanguages,
  setCurrentLanguage,
} from "../../app/slices/TrainingLocalizationSlice";
import {
  selectIsDirty,
  selectIsPlaying,
  selectTakes,
  selectTakes3D,
  selectTakes360,
  selectTrainingName,
  setIsPlaying,
} from "../../app/slices/TrainingSlice";
import PiSplitButton, {
  PiSplitButtonData,
} from "../../components/PiSplitButton";
import { useTestProject } from "../../hooks/TestProjectHook";
import TrainingLocalizationDialog from "../trainingLocalization/TrainingLocalizationDialog";
import "./TrainingActionbar.css";
import TrainingActionbarTitle from "./TrainingActionbarTitle";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";
import PiDropdown from "../../components/PiDropdown";
import TrainingActionbarSaveButton from "./TrainingActionbarSaveButton";
import { SelectedTab, setSelectedTab } from "../../app/slices/GlobalSlice";
import { selectErrors } from "../../app/slices/ValidationSlice";
import TestTrainingButton from "./TestTrainingButton";

export default function TrainingActionbar() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTestButtonIndex, setSelectedTestButtonIndex] =
    useState<number>(0);
  const trainingName = useSelector(selectTrainingName);

  const localizedLanguages = useSelector(selectLocalizedLanguages);
  const selectedLanguage = useSelector(selectCurrentLanguage);
  const isPlaying = useSelector(selectIsPlaying);
  const takesCount = useSelector(selectTakes360).length;
  const takes3DCount = useSelector(selectTakes3D).length;

  const isDirty = useSelector(selectIsDirty);
  const { getCurrentProjectTestData, testCurrentBubble, testFromCurrentTake } =
    useTestProject();

  const stopPlayingClicked = () => {
    dispatch(setIsPlaying({ isPlaying: false, data: "" }));
    dispatch(setSelectedTab(SelectedTab.Timeline));
  };

  const onChangeSelectedTestButtonIndex = (newIndex: number) =>
    setSelectedTestButtonIndex(newIndex);

  const langOptions = localizedLanguages.map((value, index) => {
    return <option key={index}>{value.full}</option>;
  });

  const onChangeLanguageDropdown = (newLang: string) => {
    dispatch(
      setCurrentLanguage(
        localizedLanguages.find((lang) => lang.full === newLang)!
      )
    );
  };

  const newDropdown = (
    <PiDropdown
      options={localizedLanguages.map((option) => {
        return {
          text: option.full,
          onClick: () => onChangeLanguageDropdown(option.full),
        };
      })}
      selectedText={selectedLanguage.full}
    />
  );

  const localizationButtons = (
    <div className="trainingactionbar-localization">
      <div className="trainingactionbar-localization-dropdown">
        {newDropdown}
      </div>
      <div className="trainingactionbar-localization-button">
        <IconButton color="inherit" onClick={() => setIsOpen(true)}>
          <TranslateIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );

  const locDialog = (
    <TrainingLocalizationDialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
    />
  );

  const saveButton = <TrainingActionbarSaveButton />;

  return (
    <div className="trainingactionbar-root">
      <div className="trainingactionbar-name px-[12px]">
        <SchoolOutlinedIcon color="inherit" />
        <div className="trainingactionbar-training-wrapper">
          <TrainingActionbarTitle />
          {isDirty && (
            <div className="trainingactionbar-training-unsaved">
              {t("unsaved_changes")}
            </div>
          )}
        </div>
      </div>
      {/* {saveButton} */}
      <TestTrainingButton />
      {localizationButtons}
      {locDialog}
    </div>
  );
}
