import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../Store";

export enum SelectedTab {
  Timeline = 0,
  ActorPresets = 1,
  PropPresets = 2,
}

export enum CollapsableColumn {
  Search = 0,
  ErrorLog = 1,
}

interface GlobalState {
  isLoading: boolean;
  isPropertiesColumnOpen: boolean;
  isMenuExpanded: boolean;
  selectedTab: SelectedTab;
  showCameraWidget: boolean;
  isPropLibraryOpen: boolean;
  isCollapsableColumnOpen: boolean;
  selectedCollapsableColumn: CollapsableColumn;
  propLibraryTargetPosition: string;
  isQuickActorModalOpen: boolean;
  quickActorModalTargetPosition: string;
}

const initialState: GlobalState = {
  isLoading: false,
  isPropertiesColumnOpen: true,
  isMenuExpanded: true,
  selectedTab: SelectedTab.Timeline,
  showCameraWidget: localStorage.getItem("showCameraWidget") === "true",
  isPropLibraryOpen: false,
  isCollapsableColumnOpen: false,
  selectedCollapsableColumn: CollapsableColumn.Search,
  propLibraryTargetPosition: "",
  isQuickActorModalOpen: false,
  quickActorModalTargetPosition: "",
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsPropertiesColumnOpen: (state, action: PayloadAction<boolean>) => {
      state.isPropertiesColumnOpen = action.payload;
    },
    setIsMenuExpanded: (state, action: PayloadAction<boolean>) => {
      state.isMenuExpanded = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<SelectedTab>) => {
      state.selectedTab = action.payload;
    },
    setShowCameraWidget: (state, action: PayloadAction<boolean>) => {
      state.showCameraWidget = action.payload;
      localStorage.setItem("showCameraWidget", action.payload.toString());
    },
    setIsPropLibraryOpen: (
      state,
      action: PayloadAction<{ open: boolean; positionId: string }>
    ) => {
      state.isPropLibraryOpen = action.payload.open;
      state.propLibraryTargetPosition = action.payload.positionId;
    },
    setIsCollapsableColumnOpen: (
      state,
      action: PayloadAction<{ open: boolean; column: CollapsableColumn }>
    ) => {
      state.isCollapsableColumnOpen = action.payload.open;
      state.selectedCollapsableColumn = action.payload.column;
    },
    setPropLibraryTargetPosition: (state, action: PayloadAction<string>) => {
      state.propLibraryTargetPosition = action.payload;
    },
    setIsQuickActorModalOpen: (
      state,
      action: PayloadAction<{ open: boolean; positionId: string }>
    ) => {
      state.isQuickActorModalOpen = action.payload.open;
      state.quickActorModalTargetPosition = action.payload.positionId;
    },
  },
});

// reducers
export const {
  setIsLoading,
  setIsPropertiesColumnOpen,
  setIsMenuExpanded,
  setSelectedTab,
  setShowCameraWidget,
  setIsPropLibraryOpen,
  setIsCollapsableColumnOpen,
  setPropLibraryTargetPosition,
  setIsQuickActorModalOpen,
} = globalSlice.actions;

export const selectIsLoading = (state: RootState) => state.global.isLoading;
export const selectIsPropertiesColumnOpen = (state: RootState) =>
  state.global.isPropertiesColumnOpen;
export const selectIsMenuExpanded = (state: RootState) =>
  state.global.isMenuExpanded;
export const selectSelectedTab = (state: RootState) => state.global.selectedTab;
export const selectShowCameraWidget = (state: RootState) =>
  state.global.showCameraWidget;
export const selectIsPropLibraryOpen = (state: RootState) =>
  state.global.isPropLibraryOpen;
export const selectIsCollapsableColumnOpen = (state: RootState) =>
  state.global.isCollapsableColumnOpen;
export const selectSelectedCollapsableColumn = (state: RootState) =>
  state.global.selectedCollapsableColumn;
export const selectPropLibraryTargetPosition = (state: RootState) =>
  state.global.propLibraryTargetPosition;
export const selectIsQuickActorModalOpen = (state: RootState) =>
  state.global.isQuickActorModalOpen;
export const selectQuickActorModalTargetPosition = (state: RootState) =>
  state.global.quickActorModalTargetPosition;

export default globalSlice.reducer;
