import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { IAttach } from "../../../models/interactions/Attach";
import MessageBubbleActorDropdown from "../message_bubble/MessageBubbleActorDropdown";
import AttachPropDropdown from "./AttachPropDropdown";
import { Prop } from "../../../models/Prop";
import AttachTagDropdown from "./AttachTagDropdown";
import usePropHelper from "../../../hooks/PropHelper";

export default function AttachPreview() {
  return (
    <PiPopupWindowPreview
      body={<AttachPreviewBody />}
      title={<PiPopupWindowStaticTitle title={"Attach"} />}
    />
  );
}

function AttachPreviewBody() {
  const attach = useSelector(selectSelectedElement) as IAttach;
  const dispatch = useDispatch();
  const { getUsedPropByInstanceId } = usePropHelper();
  const onChange = (field: keyof IAttach, value: any) => {
    dispatch(updateSelectedInteraction({ ...attach, [field]: value }));
  };

  const onChangeDropdown = (prop: Prop) => {
    // we need a good default tag
    let tag = attach.tag;

    if (prop.propId !== attach.propGuid) {
      const usedProp = getUsedPropByInstanceId(prop.id);
      if (usedProp) {
        tag = usedProp.metadata.attachmentTags[0].tag;
      }
    }

    dispatch(
      updateSelectedInteraction({
        ...attach,
        propGuid: prop.id,
        prefabName: prop.prefabName,
        tag: tag,
      } as IAttach)
    );
  };

  const actorDropdown = (
    <MessageBubbleActorDropdown
      label={"Actor"}
      selectedTargetActorId={attach.playerGuid}
      onClick={(actorId) => onChange("playerGuid", actorId)}
      hideActorsWithProp={true}
    />
  );

  const attachDropdown = (
    <AttachPropDropdown
      selectedPropId={attach.propGuid}
      onChange={onChangeDropdown}
    />
  );

  const tagDropdown = (
    <AttachTagDropdown
      selectedProp={attach.propGuid}
      selectedTag={attach.tag}
      onChange={(tag) => onChange("tag", tag)}
    />
  );

  return (
    <div className={"w-full h-full flex flex-col gap-y-[24px]"}>
      <div className="flex w-full">{actorDropdown}</div>
      <div className="flex w-full gap-x-[14px]">
        {attachDropdown}
        {tagDropdown}
      </div>
    </div>
  );
}
