import { useSelector } from "react-redux";
import { selectTakes3D } from "../app/slices/TrainingSlice";
import { InteractionType } from "../models/interactions/Interaction";
import { IAttach } from "../models/interactions/Attach";
import { IShowUIProp } from "../models/interactions/ShowUIProp";
import { Prop } from "../models/Prop";

export default function useProjectSafety() {
  const allTakes = useSelector(selectTakes3D);

  const propHasReferencesInProject = (target: Prop) => {
    return allTakes.some((take) => {
      // check if prop is spawned on the map
      const inProps = take.props.some((prop) => prop.instanceId === target.id);
      if (inProps) return true;

      let hasReferences = false;

      // check if prop is in attaches OR show UI prop
      hasReferences = take.interactions.some((i) => {
        if (i.type === InteractionType.Attach) {
          const converted = i as IAttach;
          return converted.propGuid === target.id;
        } else if (i.type === InteractionType.ShowUIProp) {
          return (i as IShowUIProp).propGuid === target.id;
        }
      });

      if (hasReferences) return true;

      // check if prop is a situation and is spawned in a take
      hasReferences = Object.values(take.situations).some(
        (situationInstanceId) => {
          return situationInstanceId === target.id;
        }
      );

      return hasReferences;
    });
  };

  const actorHasReferencesInProject = (npcInstanceId: string) => {
    return allTakes.some((take) => {
      return take.actors.some((actor) => actor.actorId === npcInstanceId);
    });
  };

  return { propHasReferencesInProject, actorHasReferencesInProject };
}
