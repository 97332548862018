import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActors,
  selectSelectedTake3D,
} from "../../app/slices/TrainingSlice";
import { useHelper3D } from "../../hooks/HelperHooks3D";
import { Actor } from "../../models/Actor";
import InteractionPointPreview, {
  ActorPointStrategy,
  InteractionPointPreviewProps,
  NpcPointStrategy,
  PlayerPointStrategy,
} from "./InteractionPointPreview";
import { NpcPopoverButton } from "./NpcPopover";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import classNames from "classnames";
import NpcPointPreviewPopover from "./NpcPointPreviewPopover";
import useViewport3D from "./Viewport3D.hook";

interface NpcPointPreviewProps extends InteractionPointPreviewProps {
  positionId: string;
}

export default function NpcPointPreview(props: NpcPointPreviewProps) {
  const dispatch = useDispatch();
  const { x, y, positionId, disabled } = { ...props };
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const { openQuickActorModal } = useViewport3D();
  const {
    getActorAtPositionIdInSelectedTake,
    addActorToSelectedTake,
    removeActorFromSelectedTake,
  } = useHelper3D();

  const take = useSelector(selectSelectedTake3D);
  const actors = useSelector(selectActors);
  const myActor = useMemo(
    () => getActorAtPositionIdInSelectedTake(positionId),
    [getActorAtPositionIdInSelectedTake, positionId]
  );

  const myStrategy = () => {
    if (!myActor) return NpcPointStrategy;
    if (myActor.id === "player") return PlayerPointStrategy;
    return ActorPointStrategy;
  };

  const onClick = (event: MouseEvent) => {
    if (disabled) return;
    //setAnchorEl(event.currentTarget);
    //setAnchorEl(event.currentTarget as Element);
    //setOpenPopover(true);
  };

  const onActorClick = (actor: Actor) => {
    setOpenPopover(false);

    addActorToSelectedTake(take, actor.id, positionId, myActor?.id);
  };

  const onActorDelete = () => {
    if (!take) return;
    if (!myActor) return;
    setOpenPopover(false);
    removeActorFromSelectedTake(take, myActor.id);
  };

  const options = actors.map((actor) => {
    return (
      <NpcPopoverButton
        key={actor.id}
        actor={actor}
        onClick={() => onActorClick(actor)}
        checked={actor.id === myActor?.id}
      />
    );
  });

  const deleteButton = (
    <div
      onClick={onActorDelete}
      className={classNames({
        " w-full flex cursor-pointer border-t-2 border-solid border-creator-primary-disabled pt-[8px]":
          true,
      })}
    >
      <div
        className={classNames({
          "my-auto text-[32px] px-[8px] w-full rounded-[5px] flex h-[42px]":
            true,
          "text-creator-error hover:bg-interaction-primary-selected":
            myActor !== undefined,
          "text-creator-primary-disabled": myActor === undefined,
        })}
      >
        <DeleteOutlineOutlinedIcon
          className={"my-auto"}
          color="inherit"
          fontSize="inherit"
        />
        <span className={"my-auto ml-[8px] text-[16px] font-medium"}>
          Delete
        </span>
      </div>
    </div>
  );

  const popoverContent = disabled ? undefined : (
    <>
      {options}
      {deleteButton}
    </>
  );

  return (
    <>
      <InteractionPointPreview
        strategy={myStrategy()}
        x={x}
        y={y}
        actor={myActor}
        disabled={disabled}
        popoverContent={
          <NpcPointPreviewPopover
            selectedActor={myActor}
            onActorClick={onActorClick}
            onDelete={onActorDelete}
            onAddNewActor={() => openQuickActorModal(positionId)}
          />
        }
      />
    </>
  );
}
