import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ButtonsQuizPreview from "../../features/previews/buttons_quiz/ButtonsQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import { InteractionProperty } from "../InteractionProperty";
import { IAnswer, IFormQuiz } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export const buttonsQuizInteraction = (): InteractionFactoryEntity => {
  const component = <ButtonsQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IFormQuiz => {
    const keys = getKeys(2);

    return {
      id: GetNewGuid(),
      name: "Buttons Quiz",
      type: InteractionType.ButtonsQuiz,
      title: keys[0],
      answers: [{ id: GetNewGuid(), correct: false, text: keys[1] }],
      properties: [...requiredGlobalQuizProperties],
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IFormQuiz => {
    const casted = interaction as IFormQuiz;
    if (casted.properties === undefined) {
      console.log(
        "[Buttons Quiz] migrated " +
          requiredGlobalQuizProperties.length +
          " properties"
      );
      return { ...casted, properties: requiredGlobalQuizProperties };
    }

    var newProperties: InteractionProperty[] = GetMigratedProperties(
      requiredGlobalQuizProperties,
      casted.properties
    );

    console.log(
      "[Buttons Quiz] migrated " +
        (newProperties.length - casted.properties.length) +
        " properties"
    );

    return { ...casted, properties: newProperties };
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IFormQuiz => {
    const formQuiz = interaction as IFormQuiz;
    const newKeys = getKeys(formQuiz.answers.length + 1);
    replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);

    if (interaction.properties === undefined) {
      return { ...formQuiz, properties: requiredGlobalQuizProperties };
    }

    var newProperties: InteractionProperty[] = GetMigratedProperties(
      requiredGlobalQuizProperties,
      interaction.properties
    );

    return {
      id: GetNewGuid(),
      name: interaction.name,
      type: InteractionType.MultipleChoiceQuiz,
      title: newKeys[newKeys.length - 1],
      answers: getAnswerArray(formQuiz.answers, newKeys),
      properties: newProperties,
      assetId: formQuiz.assetId,
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IFormQuiz;
    const warnings: string[] = [];

    ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

    let hasCorrectAnswer = false;

    // validate all answers + validate we have at least one answer
    for (let i = 0; i < converted.answers.length; i++) {
      const tempAnswer = converted.answers[i];
      ValidationHelper.ValidateKey(
        tempAnswer.text,
        getValue,
        warnings,
        `Answer ${i + 1}`
      );
      if (tempAnswer.correct) hasCorrectAnswer = true;
    }

    if (!hasCorrectAnswer) warnings.push("No correct answer selected");

    return warnings;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {
    const converted = interaction as IFormQuiz;
    const searchableStrings: string[] = [
      converted.name,
      converted.title,
      ...converted.answers.map((item) => item.text),
    ];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType
    );
  };

  const filterType: string = "Buttons Quiz";

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
