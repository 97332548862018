import { useSelector } from "react-redux";
import {
  selectAllPropPresets,
  selectSelectedTake3D,
} from "../../../app/slices/TrainingSlice";

export default function useSituationPropPoint() {
  const selectedTake = useSelector(selectSelectedTake3D);
  const allProps = useSelector(selectAllPropPresets);

  const doesSituationNodeHaveChild = (situationPropId: string) => {
    return allProps.some((prop) => prop.link?.positionId === situationPropId);
  };

  const getSpawnedProp = (situationPropId: string) => {
    return selectedTake?.props.find(
      (prop) => prop.positionId === situationPropId
    );
  };

  return { doesSituationNodeHaveChild, getSpawnedProp };
}
