import { useSelector } from "react-redux";
import { selectSelectedTakeActors } from "../../../app/slices/TrainingSlice";
import { PiInteractionDropdown } from "../../../components/PiInteractionDropdown";
import ActorAvatar from "../../../components/actors/ActorAvatar";
import usePropHelper from "../../../hooks/PropHelper";

export default function MessageBubbleActorDropdown(props: {
  label: string;
  selectedTargetActorId: string;
  onClick: (actorId: string) => void;
  hideActorsWithProp?: boolean;
  excludeActors?: string[];
}) {
  const {
    label,
    selectedTargetActorId,
    onClick,
    hideActorsWithProp,
    excludeActors,
  } = {
    ...props,
  };
  const actors = useSelector(selectSelectedTakeActors);
  const { getActorsNotAttachedToProps } = usePropHelper();

  const selectedTargetActor = actors.find(
    (actor) => actor.id === selectedTargetActorId
  );

  const filteredActors = hideActorsWithProp
    ? getActorsNotAttachedToProps()
    : actors;

  const actorsDropdownItems = filteredActors
    .filter((actor) => !excludeActors?.some((a) => actor.id === a))
    .map((actor) => {
      return (
        <PiInteractionDropdown.Item
          key={actor.id}
          onClick={() => onClick(actor.id)}
        >
          <div className={"flex justify-center gap-4 w-full"}>
            <ActorAvatar actor={actor} />
            <div className={"my-auto truncate"}>{actor.workingName}</div>
          </div>
        </PiInteractionDropdown.Item>
      );
    });

  const actorDropdown = (
    <PiInteractionDropdown label={label}>
      <PiInteractionDropdown.Trigger>
        <div className={"flex gap-x-4 w-full"}>
          <ActorAvatar actor={selectedTargetActor} />
          <div className={"my-auto truncate"}>
            {selectedTargetActor?.workingName ?? "No Actor"}
          </div>
        </div>
      </PiInteractionDropdown.Trigger>
      {actorsDropdownItems}
    </PiInteractionDropdown>
  );

  return <>{actorDropdown}</>;
}
