import { useSelector } from "react-redux";
import {
  SelectedTab,
  selectIsCollapsableColumnOpen,
  selectSelectedTab,
  setIsCollapsableColumnOpen,
} from "../../app/slices/GlobalSlice";
import { selectIsPlaying } from "../../app/slices/TrainingSlice";
import CollapsableColumnToggleButton from "./CollapsableColumnToggleButton";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import CollapsableColumnContent from "./CollapsableColumnContent";

export default function CollapsableColumn() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsCollapsableColumnOpen);
  const isPlaying = useSelector(selectIsPlaying);
  const timelineSelected =
    useSelector(selectSelectedTab) === SelectedTab.Timeline;

  const shouldRenderHeader = !isPlaying && timelineSelected && !isOpen;

  const header = (
    <motion.div
      initial={{ visibility: "hidden" }}
      animate={{
        visibility: "visible",
        transition: { delay: 0.05 },
      }}
      exit={{ visibility: "hidden", transition: { delay: 1.1 } }}
    >
      <CollapsableColumnToggleButton layout="absolute" />
    </motion.div>
  );

  const content = (
    <motion.div
      className="h-full"
      initial={{ width: 0, minWidth: 0 }}
      animate={{
        width: "292px",
        minWidth: "292px",
        transition: { duration: 0.05 },
      }}
      exit={{ width: 0, minWidth: 0, transition: { duration: 0.05 } }}
    >
      <CollapsableColumnContent />
    </motion.div>
  );

  return (
    <>
      {shouldRenderHeader && header}
      <AnimatePresence initial={false}>
        {isOpen && timelineSelected && content}
      </AnimatePresence>
    </>
  );
}
