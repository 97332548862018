import { useSelector } from "react-redux";
import { selectSelectedTake3D } from "../app/slices/TrainingSlice";
import { TakePosition } from "../app/slices/EnvironmentSlice";
import useViewport3DSituationLayer from "../features/viewport3d/situation_layer/Viewport3DSituationLayer.hook";
import InteractionPointPreview, {
  CameraPointStrategy,
  SelectedCameraPointStrategy,
} from "../features/viewport3d/InteractionPointPreview";

export default function useCameraHelper() {
  const currentTake = useSelector(selectSelectedTake3D);
  const { getAllSituationCameraViews, getSituationSelectedCameraView } =
    useViewport3DSituationLayer();

  const getAllCameraPointsForTakeLocation = (
    currentTakeLocation: TakePosition,
    currentCamId: string,
    onCamPointClick: (cameraId: string) => void
  ) => {
    if (!currentTake || !currentTakeLocation) return <> </>;

    // === situations ===
    if (currentTake.situations[currentTakeLocation!.id] !== undefined) {
      return getAllSituationCameraViews(
        currentTake.situations[currentTakeLocation!.id],
        currentCamId ?? "",
        currentTakeLocation.id,
        onCamPointClick
      );
    }

    // === normal takes ===
    return currentTakeLocation?.camera_positions.map((cam, index) => {
      return (
        <InteractionPointPreview
          strategy={
            currentCamId === cam.id
              ? SelectedCameraPointStrategy
              : CameraPointStrategy
          }
          x={cam.position.x}
          y={cam.position.y}
          key={cam.id}
          onClick={() => onCamPointClick(cam.id)}
        />
      );
    });
  };

  const getSelectedCamForTakeLocation = (
    currentTakeLocation: TakePosition,
    currentCamId: string,
    onSelectedCamClick: () => void
  ) => {
    if (!currentTake || !currentTakeLocation) return <></>;

    // === situations ===
    if (currentTake.situations[currentTakeLocation!.id] !== undefined) {
      return getSituationSelectedCameraView(
        currentTake.situations[currentTakeLocation!.id],
        currentTakeLocation!.id,
        currentCamId.split("/")[1],
        onSelectedCamClick
      );
    }
    // === normal takes ===
    else {
      const camPos = currentTakeLocation?.camera_positions.find(
        (cam) => cam.id === currentCamId
      );

      if (camPos)
        return (
          <InteractionPointPreview
            key={currentTake.mainCamera.cameraId}
            strategy={SelectedCameraPointStrategy}
            x={camPos.position.x}
            y={camPos.position.y}
            onClick={onSelectedCamClick}
          />
        );
      else return <></>;
    }
  };

  return {
    getAllCameraPointsForTakeLocation,
    getSelectedCamForTakeLocation,
  } as const;
}
