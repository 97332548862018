import { useCallback, useMemo } from "react";
import InteractionPointPreview, {
  CurrentTakeSituationPointStrategy,
  PlacedSituationPointStrategy,
  SituationPointStrategy,
} from "../InteractionPointPreview";
import { Viewport3DLayerProps } from "../Viewport3DEnvironmentMetadata";
import { useSelector } from "react-redux";
import { selectSelectedTake3D } from "../../../app/slices/TrainingSlice";
import SituationPointPopover from "./SituationPointPopover";
import useViewport3DSituationLayer from "./Viewport3DSituationLayer.hook";
import { TakePosition } from "../../../app/slices/EnvironmentSlice";

export default function Viewport3DSituationLayer(props: Viewport3DLayerProps) {
  const { currentTakeLocation, takePositions } = { ...props };
  const currentTake = useSelector(selectSelectedTake3D);
  const {
    onSelectSituationForLocation,
    onRemoveSituationForLocation,
    GetAllNodesForAllUsedSituations,
  } = useViewport3DSituationLayer();

  const situationTakes = useMemo(() => {
    if (!currentTake) return [];

    return takePositions.filter((point) => point.allow_situation);
  }, [takePositions, currentTake]);

  const getPointStrategy = useCallback(
    (takePosition: TakePosition) => {
      const occupied = currentTake?.situations[takePosition.id];
      const isCurrentTakeLocation =
        currentTakeLocation?.id &&
        currentTake?.situations[currentTakeLocation?.id] !== undefined &&
        takePosition.id === currentTakeLocation?.id;

      if (isCurrentTakeLocation) return CurrentTakeSituationPointStrategy;
      if (occupied) return PlacedSituationPointStrategy;

      return SituationPointStrategy;
    },
    [currentTake?.situations, currentTakeLocation?.id]
  );

  const selectCallback = useCallback(
    (point: TakePosition, situationId: string) =>
      onSelectSituationForLocation(point, situationId),
    [onSelectSituationForLocation]
  );

  const situationsMapped = useMemo(() => {
    return situationTakes.map((point, index) => {
      const occupied = currentTake?.situations[point.id];

      return (
        <InteractionPointPreview
          strategy={getPointStrategy(point)}
          x={point.position.x}
          y={point.position.y}
          key={point.id}
          popoverContent={
            <SituationPointPopover
              onClick={(situationId) => selectCallback(point, situationId)}
              onRemove={() => onRemoveSituationForLocation(point)}
              canRemove={!!occupied}
              assignedSituation={occupied}
            />
          }
        />
      );
    });
  }, [
    situationTakes,
    currentTake,
    getPointStrategy,
    onRemoveSituationForLocation,
    selectCallback,
  ]);

  return (
    <>
      {currentTakeLocation &&
        currentTake?.interactionPointId &&
        situationsMapped}
      {currentTakeLocation &&
        currentTake?.interactionPointId &&
        GetAllNodesForAllUsedSituations()}
    </>
  );
}
