import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectEnvironmentInteractionPoints,
  TakePosition,
} from "../../app/slices/EnvironmentSlice";
import {
  selectIsCameraSelected,
  selectSelectedTake3D,
} from "../../app/slices/TrainingSlice";
import "./Viewport3DEnvironmentMetadata.css";
import Viewport3DNpcLayer from "./Viewport3DNpcLayer";
import Viewport3DTakeLocationLayer from "./ViewportTakeLocationLayer";
import Viewport3DPropLayer from "./Viewport3DPropLayer";
import Viewport3DCameraLayer from "./Viewport3DCameraLayer";
import Viewport3DSituationLayer from "./situation_layer/Viewport3DSituationLayer";

export interface Viewport3DLayerProps {
  currentTakeLocation?: TakePosition;
  takePositions: TakePosition[];
  isCameraSelected?: boolean;
}

export default function EnvironmentMetadataView() {
  const take = useSelector(selectSelectedTake3D);
  const takePositions = useSelector(selectEnvironmentInteractionPoints);
  const isTakeCameraSelected = useSelector(selectIsCameraSelected);

  const currentTakeLocation = useMemo(() => {
    return take?.interactionPointId
      ? takePositions.find((point) => point.id === take.interactionPointId)
      : undefined;
  }, [take, takePositions]);

  return (
    <>
      <Viewport3DNpcLayer
        currentTakeLocation={currentTakeLocation}
        takePositions={takePositions}
        isCameraSelected={isTakeCameraSelected}
      />
      <Viewport3DTakeLocationLayer
        currentTakeLocation={currentTakeLocation}
        takePositions={takePositions}
        isCameraSelected={isTakeCameraSelected}
      />
      <Viewport3DPropLayer
        currentTakeLocation={currentTakeLocation}
        takePositions={takePositions}
        isCameraSelected={isTakeCameraSelected}
      />
      <Viewport3DSituationLayer
        currentTakeLocation={currentTakeLocation}
        takePositions={takePositions}
        isCameraSelected={isTakeCameraSelected}
      />
      <Viewport3DCameraLayer
        currentTakeLocation={currentTakeLocation}
        takePositions={takePositions}
        isCameraSelected={isTakeCameraSelected}
      />
    </>
  );
}
