import React, { useState } from "react";
import classNames from "classnames";
import { tab } from "@testing-library/user-event/dist/tab";

export interface PiTabSettings {
  icon: JSX.Element;
  onClick: () => void;
  text?: string;
  orientation?: "horizontal" | "vertical";
}

interface PiTabViewProps {
  tabSettings: PiTabSettings[];
  onTabClick: (index: number) => void;
  startIndex?: number;
}

export default function PiTabView(props: PiTabViewProps) {
  const { tabSettings, onTabClick, startIndex } = props;

  const [selectedIndex, setSelectedIndex] = useState<number>(startIndex ?? 0);

  const tabsVisual = tabSettings.map((tabSetting, index) => {
    return (
      <div
        aria-label="pitabview-tab"
        className={classNames({
          "w-full h-full text-[22px] cursor-pointer items-center justify-center border-b-2":
            true,
          "text-creator-primary border-creator-primary bg-creator-primary-washed font-medium":
            index === selectedIndex,
          "border-creator-border": index !== selectedIndex,
          "flex flex-col":
            tabSetting.orientation === "vertical" ||
            tabSetting.orientation === undefined,
          "flex gap-[6px]": tabSetting.orientation === "horizontal",
        })}
        key={index}
        onClick={() => {
          onTabClick(index);
          setSelectedIndex(index);
          tabSetting.onClick();
        }}
      >
        <div
          className={classNames({
            "flex justify-center justify-items-center items-center h-[28px] font-[24px]  fill-creator-primary":
              true,
            "fill-creator-primary": index === selectedIndex,
            "fill-creator-text-sub": index !== selectedIndex,
            "mx-auto": tabSetting.orientation === "vertical",
          })}
        >
          {tabSetting.icon}
        </div>
        <div
          className={`flex leading-[22px] text-[12px] ${
            index === selectedIndex ? " font-semibold" : " font-medium"
          }`}
        >
          {tabSetting.text}
        </div>
      </div>
    );
  });

  return (
    <div
      aria-label="pitabview-root"
      className="w-full text-creator-text-sub bg-creator-primary-background h-[60px] box-border select-none"
    >
      <div aria-label="pitabview-content" className="flex items-center h-full">
        {tabsVisual}
      </div>
    </div>
  );
}
