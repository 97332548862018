import ClearIcon from "@mui/icons-material/Clear";

export default function PiDeleteButton(props: { onClick: () => void }) {
  const { onClick } = { ...props };
  return (
    <div
      className="h-full text-creator-error text-[32px] flex justify-center items-center cursor-pointer hover:text-creator-error-hover"
      onClick={onClick}
    >
      <ClearIcon fontSize="inherit" color="inherit" />
    </div>
  );
}
