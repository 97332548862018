import PiModal, { PiModalProps } from "../../components/PiModal";
import PropLibraryCategories from "./PropLibraryCategories";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsPropSliceInitialized,
  setPropFilter,
} from "../../app/slices/PropLibrarySlice";
import usePropLibraryPagination from "./PropLibraryPagination.hooks";
import { useDebouncedCallback } from "use-debounce";
import PiTabView from "../../components/PiTabView";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ShapeLineOutlinedIcon from "@mui/icons-material/ShapeLineOutlined";
import {
  defaultPropLibraryStrategy,
  environmentPropLibraryStrategy,
  PropLibraryStrategy,
  situationPropLibraryStrategy,
} from "./PropLibraryStrategy";

interface PropLibraryProps extends PiModalProps {
  onAddProp: () => void;
}

export default function PropLibrary(props: PropLibraryProps) {
  const { isOpen, onClose, title, width, height, onAddProp } = { ...props };
  const dispatch = useDispatch();
  const { LoadFirstPage } = usePropLibraryPagination();

  const isInitalized = useSelector(selectIsPropSliceInitialized);
  const [strategy, setStrategy] = useState<PropLibraryStrategy>(
    defaultPropLibraryStrategy
  );

  useEffect(() => {
    if (isOpen) setStrategy(defaultPropLibraryStrategy);
  }, [isOpen]);
  const tabs = (
    <PiTabView
      onTabClick={(index: number) => {}}
      tabSettings={[
        {
          icon: (
            <CollectionsBookmarkOutlinedIcon
              fontSize="inherit"
              color="inherit"
            />
          ),
          onClick: () => setStrategy(defaultPropLibraryStrategy),
          text: "Props",
          orientation: "horizontal",
        },
        {
          icon: <MapOutlinedIcon fontSize="inherit" color="inherit" />,
          onClick: () => setStrategy(environmentPropLibraryStrategy),
          text: "From Scene",
          orientation: "horizontal",
        },
        {
          icon: <ShapeLineOutlinedIcon fontSize="inherit" color="inherit" />,
          onClick: () => setStrategy(situationPropLibraryStrategy),
          text: "Situations",
          orientation: "horizontal",
        },
      ]}
    />
  );

  return (
    <PiModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      width={width}
      height={height}
    >
      <div className={"w-full h-full flex flex-col"}>
        <div className={"w-full h-full flex gap-[12px]"}>
          <div className={"w-[250px] h-full"}>
            <PropLibraryCategories />
          </div>
          <div className="flex flex-col w-full h-full gap-[12px]">
            {tabs}
            <div className="flex w-full h-full">
              <div className={"flex-1 h-full"}>{strategy?.GetContent()}</div>
              <div className={"w-[500px]"}>{strategy?.GetDetails()}</div>
            </div>
          </div>
        </div>
      </div>
    </PiModal>
  );
}
