import Interaction from "./Interaction";
import { InteractionType } from "./Interaction";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import WaitPreview from "../../features/previews/wait/WaitPreview";

export interface IWait extends Interaction {
  seconds: number;
}

export const waitInteraction = (): InteractionFactoryEntity => {
  const create = (getKeys: (amount: number) => string[]): IWait => {
    return {
      id: GetNewGuid(),
      name: "Wait",
      type: InteractionType.Wait,
      seconds: 0,
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IWait => {
    return interaction as IWait;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void
  ): IWait => {
    return {
      id: GetNewGuid(),
      name: "Wait",
      type: InteractionType.Wait,
      seconds: (interaction as IWait).seconds,
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string
  ): string[] => {
    const wait = interaction as IWait;
    const warnings: string[] = [];

    return warnings;
  };

  const filterType = "Wait";

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {
    return getMatchingResults(
      [interaction.name],
      takeName,
      interaction,
      filterType
    );
  };

  return {
    View: <WaitPreview />,
    Create: create,
    FilterType: filterType,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
