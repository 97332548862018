import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Popover } from "@mui/material";
import React from "react";
import { Actor, ProfilePictures } from "../../models/Actor";

interface NpcPopoverProps {
  element: Element | null;
  isOpen: boolean;
  onClose: () => void;

  actors: Actor[];
  selectedActor?: Actor;
  onActorClick: (actor: Actor) => void;
  onActorDelete?: () => void;

  hideDelete?: boolean;
}

export function NpcPopoverButton(props: {
  actor: Actor;
  onClick?: () => void;
  checked?: boolean;
}) {
  const { actor, onClick, checked } = { ...props };

  return (
    <div
      className={
        "flex w-[234px] min-w-[234px] mt-[5px] mx-auto items-center gap-2  h-[46px] cursor-pointer rounded-[5px] pr-2 pl-2 text-interaction-text-primary text-14 font-poppins" +
        " font-medium" +
        " hover:bg-interaction-text-hoverBackground " +
        (checked ? "bg-interaction-text-selectedBackground" : "")
      }
      onClick={onClick}
    >
      {actor.id !== "player" && (
        <img
          className="flex items-center justify-center border-2 rounded-circle min-w-9 min-h-9 max-w-9 max-h-9 border-creator-primary"
          src={ProfilePictures[actor.profilePicture]}
          alt="pfp"
        />
      )}
      {actor.id === "player" && (
        <div className="flex items-center justify-center font-medium bg-creator-primary w-9 h-9 rounded-circle text-interaction-text-inverted text-14 ">
          P
        </div>
      )}
      <div className="text-creator-text-sub">{actor.workingName}</div>
    </div>
  );
}

export default function NpcPopover(props: NpcPopoverProps) {
  const {
    element,
    isOpen,
    onClose,
    onActorClick,
    onActorDelete,
    selectedActor,
    actors,
    hideDelete,
  } = { ...props };

  const actorVisuals = actors.map((actor, index) => {
    return (
      <NpcPopoverButton
        key={index}
        actor={actor}
        onClick={() => onActorClick(actor)}
        checked={selectedActor && selectedActor.id === actor.id}
      />
    );
  });

  // if it has an actor, allow delete as well
  const deleteClass =
    "h-[42px] flex items-center text-interaction-secondary font-8 m-2 rounded-[5px] cursor-pointer hover:bg-interaction-text-hoverBackground" +
    (selectedActor ? "" : "disabled:text-interaction-disabled ");

  const deleteButton = (
    <div className="border-t-2 border-interaction-primary-washed">
      <div className={deleteClass} onClick={onActorDelete}>
        <DeleteOutlineOutlinedIcon color="inherit" fontSize="inherit" />
        <span>Delete</span>
      </div>
    </div>
  );

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={element}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        className: "w-[250px] rounded-[5px] shadow-base ",
      }}
    >
      {actorVisuals}
      {!hideDelete && deleteButton}
    </Popover>
  );
}
