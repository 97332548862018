import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectRefreshToken,
  selectToken,
} from "../app/slices/AuthenticationSlice";
import { selectCurrentTrainingId } from "../app/slices/ProjectsSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { TranslationWithDescriptionDTO } from "../dto/TranslationWithDescriptionDTO";
import { TrainingService } from "../services/TrainingService";
import { useSaveTraining } from "./SaveTrainingHook";
import { selectCurrentEnvironmentId } from "../app/slices/EnvironmentSlice";

export enum PublishStep {
  Initialize = 0,
  EnterCourseDetails = 1,
  Submitting = 2,
  Finished = 3,
  Failed = 4,
  Processing = 5,
}

export const usePublishCourse = () => {
  const orgId = useSelector(selectCurrentOrganization)?.id;
  const token = useSelector(selectToken);
  const refreshToken = useSelector(selectRefreshToken);
  const trainingId = useSelector(selectCurrentTrainingId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<PublishStep>(
    PublishStep.Initialize
  );
  const [playUrl, setPlayUrl] = useState<string>("");
  const { saveTraining } = useSaveTraining();

  // mandatory translations
  const [translations, setTranslations] = useState<
    TranslationWithDescriptionDTO[]
  >([]);
  const [isInputInvalid, setIsInputInvalid] = useState<boolean>(true);

  const environmentId: string | undefined = useSelector(
    selectCurrentEnvironmentId
  );

  useEffect(() => {
    setIsInputInvalid(CheckIsInputInvalid());
  }, [translations]);

  const getCourseDetails = async () => {
    if (!orgId) return;

    setIsLoading(true);
    var details = await TrainingService.GetCourseDetails(
      orgId,
      token,
      trainingId
    );

    if (!details) {
      setTranslations([
        {
          name: "",
          description: "",
          languageId: "nl",
        },
        {
          name: "",
          description: "",
          languageId: "fr",
        },
        {
          name: "",
          description: "",
          languageId: "en",
        },
      ]);
    } else {
      setTranslations(details!.translations);
    }

    setCurrentStep(PublishStep.EnterCourseDetails);
    setIsLoading(false);
  };

  const onChangeDto = (dto: TranslationWithDescriptionDTO) => {
    var ct = [...translations];
    var i = ct.indexOf(ct.find((t) => t.languageId === dto.languageId)!);
    ct[i] = { ...dto };

    setTranslations(ct);
  };

  const publishTraining = async () => {
    if (!orgId) return;
    setCurrentStep(PublishStep.Submitting);

    // save training first
    await saveTraining();

    //publish training
    var result = await TrainingService.PublishCourse(
      orgId,
      token,
      trainingId,
      translations,
      environmentId === "not-set" ? undefined : environmentId
    );

    // CODES
    // 201 = success
    // 409 = still processing assets OR failed processing an asset (no way to differentiate atm)
    // other = failed

    if (result <= 201) {
      setCurrentStep(PublishStep.Finished);
    } else if (result === 409) {
      setCurrentStep(PublishStep.Processing);
    } else {
      setCurrentStep(PublishStep.Failed);
    }
  };

  const CheckIsInputInvalid = () => {
    // if one of the names is empty, return false
    var isInvalid = false;
    translations.forEach((t) => {
      if (!t.name || t.name.trim() === "") {
        isInvalid = true;
        return;
      }
    });

    return isInvalid;
  };

  return {
    currentStep,
    getCourseDetails,
    translations,
    onChangeDto,
    publishTraining,
    playUrl,
    isInputInvalid,
  } as const;
};
