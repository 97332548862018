import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, IconButton } from "@mui/material";
import React from "react";
import "./PiConfirmationDialog.css";
import PiDialog, { DialogProps } from "./PiDialog";
import classNames from "classnames";
import PiButton from "./PiButton";
interface PiConfirmationDialogProps extends DialogProps {
  text: string;
  title: string;
  onConfirm: () => void;
  confirmText: string;
  cancelText: string;
  confirmButtonVariant: "piBlue" | "piRed";
  height?: string;
  hideCancelButton?: boolean;
}

export default function PiConfirmationDialog(props: PiConfirmationDialogProps) {
  const {
    open,
    onClose,
    text,
    title,
    onConfirm,
    confirmText,
    height,
    confirmButtonVariant,
    hideCancelButton,
    cancelText,
  } = { ...props };

  const body = (
    <div className="piconfirmationdialog-body">
      <div className="piconfirmationdialog-title">{title}</div>
      <span>{text}</span>
      <div className="piconfirmationdialog-buttons">
        {!hideCancelButton && (
          <div>
            <Button variant="piBlueOutlined" onClick={onClose}>
              {cancelText}
            </Button>
          </div>
        )}
        <div className={classNames({ "!max-w-[128px]": hideCancelButton })}>
          {/* <Button className="w-full" variant={confirmButtonVariant} onClick={onConfirm}>
            {confirmText}
          </Button> */}
          <div className="w-full h-[36px]">
            <PiButton onClick={onConfirm} variant={confirmButtonVariant}>
              {confirmText}
            </PiButton>
          </div>
        </div>
      </div>
      <div className="piconfirmationdialog-close-button">
        <IconButton onClick={() => onClose()}>
          <CloseOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );

  const header = (
    <div
      className={
        "piconfirmationdialog-header piconfirmationdialog-header-" +
        confirmButtonVariant
      }
    >
      <InfoOutlinedIcon color="inherit" fontSize="inherit" />
    </div>
  );

  return (
    <PiDialog
      open={open}
      onClose={onClose}
      width="400px"
      height={!height ? "248px" : height}
      header={header}
      body={body}
      hideCloseButton
    />
  );
}
