import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../Store";

interface ValidationState {
  warnings: Record<string, string[]>;
  errors: Record<string, string[]>;
}

const initialState: ValidationState = {
  warnings: {},
  errors: {},
};

export const validationSlice = createSlice({
  name: "validation",
  initialState,
  reducers: {
    InitializeWarnings: (
      state,
      action: PayloadAction<Record<string, string[]>>
    ) => {
      state.warnings = action.payload;
    },
    InitializeErrors: (
      state,
      action: PayloadAction<Record<string, string[]>>
    ) => {
      state.errors = action.payload;
    },
    addInteractionWarning: (
      state,
      action: PayloadAction<{ interactionId: string; warnings: string[] }>
    ) => {
      state.warnings[action.payload.interactionId] = action.payload.warnings;
    },
  },
});

export const { InitializeWarnings, InitializeErrors, addInteractionWarning } =
  validationSlice.actions;

export const selectWarnings = (state: RootState) => state.validation.warnings;
export const selectErrors = (state: RootState) => state.validation.errors;

export default validationSlice.reducer;
