import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedElement,
  selectSelectedElementType,
  selectSelectedTake3D,
  updateSelectedInteraction,
} from "../../app/slices/TrainingSlice";
import { Viewport3DLayerProps } from "./Viewport3DEnvironmentMetadata";
import { useMapEvents } from "react-leaflet";
import { ISetCamera } from "../../models/interactions/SetCamera";
import useCameraHelper from "../../hooks/CameraHelper.hook";

export default function Viewport3DSetCameraLayer(props: Viewport3DLayerProps) {
  const dispatch = useDispatch();
  const { currentTakeLocation, isCameraSelected } = { ...props };
  const currentTake = useSelector(selectSelectedTake3D);
  const selectedElementType = useSelector(selectSelectedElementType);
  const setCam = useSelector(selectSelectedElement) as ISetCamera;
  const currentCamId = setCam.cameraId;
  const { getAllCameraPointsForTakeLocation, getSelectedCamForTakeLocation } =
    useCameraHelper();

  const map = useMapEvents({
    click: (e) => {
      if (setCam.isSelecting)
        dispatch(
          updateSelectedInteraction({
            ...setCam,
            isSelecting: false,
          } as ISetCamera)
        );
    },
  });

  const onCamPointClick = (cameraId: string) => {
    // if (!currentTake) return;
    if (cameraId === currentCamId) return;

    const updatedInteraction: ISetCamera = {
      ...setCam,
      cameraId: cameraId,
      takeLocationId: currentTakeLocation!.id,
    };
    dispatch(updateSelectedInteraction(updatedInteraction));
  };

  const onSelectedCamClick = () => {
    dispatch(
      updateSelectedInteraction({ ...setCam, isSelecting: true } as ISetCamera)
    );
  };

  // const cams = useMemo(() => {
  //   // handle situations here

  //   return currentTakeLocation?.camera_positions.map((cam, index) => {
  //     return (
  //       <InteractionPointPreview
  //         strategy={
  //           currentCamId === cam.id
  //             ? SelectedCameraPointStrategy
  //             : CameraPointStrategy
  //         }
  //         x={cam.position.x}
  //         y={cam.position.y}
  //         key={index}
  //         onClick={() => onCamPointClick(cam.id)}
  //       />
  //     );
  //   });
  // }, [currentTake, setCam.id]);

  // const selectedCam = useMemo(() => {
  //   if (!currentTake) return <></>;

  //   const cid = setCam.cameraId;

  //   const camPos = currentTakeLocation?.camera_positions.find(
  //     (cam) => cam.id === cid
  //   );

  //   if (camPos)
  //     return (
  //       <InteractionPointPreview
  //         key={camPos.id}
  //         strategy={SelectedCameraPointStrategy}
  //         x={camPos.position.x}
  //         y={camPos.position.y}
  //         onClick={onSelectedCamClick}
  //       />
  //     );
  //   else return <></>;
  // }, [setCam, setCam.id]);

  const cams = useMemo(() => {
    if (!currentTakeLocation) return <></>;

    return getAllCameraPointsForTakeLocation(
      currentTakeLocation,
      setCam.cameraId,
      onCamPointClick
    );
  }, [getAllCameraPointsForTakeLocation, currentTakeLocation, onCamPointClick]);

  const selectedCam = useMemo(() => {
    if (!currentTakeLocation) return <></>;

    return getSelectedCamForTakeLocation(
      currentTakeLocation,
      setCam.cameraId,
      onSelectedCamClick
    );
  }, [getSelectedCamForTakeLocation, currentTakeLocation, onSelectedCamClick]);

  return (
    <>
      {setCam.isSelecting && cams}
      {setCam.cameraId && selectedCam}
    </>
  );
}
