import { Viewport3DLayerProps } from "./Viewport3DEnvironmentMetadata";
import PropPointPreview from "./PropPointPreview";
import { useSelector } from "react-redux";
import { selectSelectedTake3D } from "../../app/slices/TrainingSlice";

export default function Viewport3DPropLayer(props: Viewport3DLayerProps) {
  const { currentTakeLocation, takePositions, isCameraSelected } = { ...props };
  const situationsInTake = useSelector(selectSelectedTake3D)?.situations;

  const propPreviews = takePositions
    .filter((pos) => situationsInTake && !(pos.id in situationsInTake))
    .map((point) => {
      if (point.prop_positions)
        return point.prop_positions.map((propPos) => {
          return (
            <PropPointPreview
              key={propPos.id}
              x={propPos.position.x}
              y={propPos.position.y}
              positionId={propPos.id}
              disabled={isCameraSelected}
            />
          );
        });
    });

  return <>{currentTakeLocation && propPreviews}</>;
}
