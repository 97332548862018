import { useTranslation } from "react-i18next";
import PiSplitButton, {
  PiSplitButtonData,
} from "../../components/PiSplitButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import { useSelector } from "react-redux";
import {
  selectIsPlaying,
  selectTakes,
  setIsPlaying,
} from "../../app/slices/TrainingSlice";
import { useTestProject } from "../../hooks/TestProjectHook";
import { useDispatch } from "react-redux";
import {
  CollapsableColumn,
  SelectedTab,
  setIsCollapsableColumnOpen,
  setSelectedTab,
} from "../../app/slices/GlobalSlice";
import { useMemo, useState } from "react";
import { selectErrors } from "../../app/slices/ValidationSlice";
import ErrorIcon from "@mui/icons-material/Error";
export default function TestTrainingButton() {
  const dispatch = useDispatch();
  const isPlaying = useSelector(selectIsPlaying);
  const takesCount = useSelector(selectTakes).length;
  const allErrors = useSelector(selectErrors);

  const hasErrors = useMemo(() => {
    return Object.values(allErrors).some((errors) => errors.length > 0);
  }, [allErrors]);

  const { getCurrentProjectTestData, testCurrentBubble, testFromCurrentTake } =
    useTestProject();
  const { t } = useTranslation();

  const [selectedTestButtonIndex, setSelectedTestButtonIndex] =
    useState<number>(0);

  const stopPlayingClicked = () => {
    dispatch(setIsPlaying({ isPlaying: false, data: "" }));
    dispatch(setSelectedTab(SelectedTab.Timeline));
  };

  const splitButtonDataPlay: PiSplitButtonData[] = [
    {
      text: t("current_project"),
      icon: <PlayArrowIcon />,
      onClick: getCurrentProjectTestData,
    },
    {
      text: t("current_bubble"),
      icon: <PlayArrowIcon />,
      onClick: testCurrentBubble,
    },
    {
      text: t("from_current_bubble"),
      icon: <PlayArrowIcon />,
      onClick: testFromCurrentTake,
    },
  ];

  const splitButtonDataStop: PiSplitButtonData[] = [
    {
      text: t("current_project"),
      icon: <StopIcon />,
      onClick: stopPlayingClicked,
    },
    {
      text: t("current_bubble"),
      icon: <StopIcon />,
      onClick: stopPlayingClicked,
    },
    {
      text: t("from_current_bubble"),
      icon: <StopIcon />,
      onClick: stopPlayingClicked,
    },
  ];

  const blockedOptions: PiSplitButtonData[] = [
    {
      text: "Resolve Errors",
      icon: <ErrorIcon color="inherit" />,
      onClick: () => {
        console.log("resolve errors");
        dispatch(
          setIsCollapsableColumnOpen({
            open: true,
            column: CollapsableColumn.ErrorLog,
          })
        );
      },
    },
  ];

  const getTestButtonLayout = () => {
    if (hasErrors)
      return (
        <div className="cursor-not-allowed">
          <PiSplitButton
            variant="piRedOutlined"
            options={blockedOptions}
            onChangeSelectedIndex={(index) => {}}
            selectedIndex={0}
            disabled={false}
          />
        </div>
      );

    if (!isPlaying)
      return (
        <PiSplitButton
          variant="piBlueOutlined"
          selectedIndex={selectedTestButtonIndex}
          onChangeSelectedIndex={(index) => setSelectedTestButtonIndex(index)}
          options={splitButtonDataPlay}
          disabled={takesCount === 0}
        />
      );
    return (
      <PiSplitButton
        variant="piRedOutlined"
        selectedIndex={selectedTestButtonIndex}
        onChangeSelectedIndex={(index) => setSelectedTestButtonIndex(index)}
        options={splitButtonDataStop}
        disableDropdown
      />
    );
  };

  const testButton = <div className="">{getTestButtonLayout()}</div>;

  return <div className="w-[292px] flex justify-center ">{testButton}</div>;
}
