import axios from "axios";
import { TranslatedPropDTO } from "../dto/TranslatedPropDTO";
import { TranslatedPropContentDTO } from "../dto/TranslatedPropContentDTO";
import { SituationMetadata } from "../dto/SituationMetadata";

var CREATOR_URL = process.env.REACT_APP_PLAYIT_CREATORAPI;

export class SituationService {
  static async GetSituationsFromPage(
    orgId: string,
    token: string,
    pageNumber: number,
    pageSize: number,
    category: string,
    filter: string
  ): Promise<{ totalCount: number; props: TranslatedPropDTO[] }> {
    const path = "/organizations/" + orgId + "/translated-situations";
    const url = CREATOR_URL + path;
    const params = {
      PageSize: pageSize,
      PageNumber: pageNumber,
      Category: category,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    };

    let result: TranslatedPropDTO[] = [];
    let totalCount = 0;

    await axios
      .get(url, config)
      .then((response) => {
        result = response.data;
        totalCount = JSON.parse(response.headers["x-pagination"])["totalCount"];
      })
      .catch((error) => console.log(error.response));

    return {
      totalCount: totalCount,
      props: result,
    };
  }

  static async GetSituationsContent(
    orgId: string,
    token: string,
    situationId: string
  ): Promise<TranslatedPropContentDTO | undefined> {
    const path =
      "/organizations/" + orgId + "/translated-situations/" + situationId;
    const url = CREATOR_URL + path;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let result: TranslatedPropContentDTO | undefined = undefined;

    await axios
      .get(url, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => console.log(error.response));

    return result;
  }

  static async GetSituationMetadata(
    url: string
  ): Promise<SituationMetadata | undefined> {
    let result: SituationMetadata | undefined = undefined;

    await axios
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => console.log(error.response));

    return result;
  }
}
