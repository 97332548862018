import { useDispatch, useSelector } from "react-redux";
import PiButton from "../../components/PiButton";
import PropLibrary from "../prop_library/PropLibrary";
import {
  addSelectedPropAsUsedProp,
  selectSelectedPropContent,
  selectSelectedPropId,
  selectSelectedPropMetadata,
} from "../../app/slices/PropLibrarySlice";
import usePropHelper from "../../hooks/PropHelper";
import { GetNewGuid } from "../../helpers/GuidHelper";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { PropType } from "../../models/Prop";
import {
  addPropPreset,
  selectAllPropPresets,
} from "../../app/slices/TrainingSlice";
import {
  selectIsPropLibraryOpen,
  setIsPropLibraryOpen,
} from "../../app/slices/GlobalSlice";
import PropPresetList from "./PropPresetList";
export default function PropPresetsColumn() {
  const allProps = useSelector(selectAllPropPresets);
  const isOpen = useSelector(selectIsPropLibraryOpen);

  const selectedPropPickerId = useSelector(selectSelectedPropId);
  const { getPropDTO } = usePropHelper();
  const dispatch = useDispatch();
  const selectedPropContent = useSelector(selectSelectedPropContent);
  const selectPropMetadata = useSelector(selectSelectedPropMetadata);

  const onAddProp = () => {
    if (!selectedPropContent || !selectPropMetadata) return;

    dispatch(
      addPropPreset({
        id: GetNewGuid(),
        propId: selectedPropContent.id,
        name: selectedPropContent.name,
        prefabName: selectPropMetadata.prefab_name,
        propType: PropType.Default,
      })
    );
    dispatch(addSelectedPropAsUsedProp()); // add prop to cached props
    dispatch(setIsPropLibraryOpen({ open: false, positionId: "" }));
  };

  const addButton = (
    <div className={"px-[18px] h-[40px]"}>
      <PiButton
        variant={"piBlue"}
        onClick={() => {
          dispatch(setIsPropLibraryOpen({ open: true, positionId: "" }));
        }}
      >
        <div
          className={"h-full flex justify-center mx-auto justify-items-center"}
        >
          <div className={"flex gap-[8px] my-auto text-[24px]"}>
            <PersonAddAltOutlinedIcon className={""} fontSize={"inherit"} />
            <div className={"text-[14px] my-auto"}>Add Prop</div>
          </div>
        </div>
      </PiButton>
    </div>
  );

  return (
    <div className={"w-full h-full flex flex-col gap-y-[10px]"}>
      <PropPresetList />
      {addButton}
      <PropLibrary
        title={"Prop Library"}
        isOpen={isOpen}
        onClose={() =>
          dispatch(setIsPropLibraryOpen({ open: false, positionId: "" }))
        }
        width={"90vw"}
        height={"90vh"}
        onAddProp={onAddProp}
      />
    </div>
  );
}
