import { selectSelectedPropContent } from "../../app/slices/PropLibrarySlice";
import { selectSelectedPropMetadata } from "../../app/slices/PropLibrarySlice";
import {
  selectIsPropLibraryOpen,
  selectIsQuickActorModalOpen,
  selectPropLibraryTargetPosition,
  selectQuickActorModalTargetPosition,
  setIsQuickActorModalOpen,
} from "../../app/slices/GlobalSlice";
import { useSelector } from "react-redux";
import { setIsPropLibraryOpen } from "../../app/slices/GlobalSlice";
import { addSelectedPropAsUsedProp } from "../../app/slices/PropLibrarySlice";
import {
  addActor,
  addPropPreset,
  addPropStateToSelectedTake,
  deleteActor,
  selectSelectedActor,
  selectSelectedTake3D,
} from "../../app/slices/TrainingSlice";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { PropType } from "../../models/Prop";
import { useDispatch } from "react-redux";
import { ActorHelper } from "../actor_presets/ActorHelper";
import { useHelper3D } from "../../hooks/HelperHooks3D";

export default function useViewport3D() {
  const dispatch = useDispatch();

  const selectedPropContent = useSelector(selectSelectedPropContent);
  const selectPropMetadata = useSelector(selectSelectedPropMetadata);
  const isPropLibraryOpen = useSelector(selectIsPropLibraryOpen);
  const propLibraryTargetPosition = useSelector(
    selectPropLibraryTargetPosition
  );

  const isQuickActorModalOpen = useSelector(selectIsQuickActorModalOpen);
  const quickActorModalPositionId = useSelector(
    selectQuickActorModalTargetPosition
  );
  const { addActorToSelectedTake } = useHelper3D();
  const currentTake = useSelector(selectSelectedTake3D);
  const selectedActor = useSelector(selectSelectedActor);

  const addNewPropFromPropLibrary = () => {
    console.log("addNewPropFromPropLibrary");
    if (!selectedPropContent || !selectPropMetadata) return;

    const id = GetNewGuid();
    dispatch(
      addPropPreset({
        id: id,
        propId: selectedPropContent.id,
        name: selectedPropContent.name,
        prefabName: selectPropMetadata.prefab_name,
        propType: PropType.Default,
      })
    );

    dispatch(addSelectedPropAsUsedProp()); // add prop to cached props

    // if there is a target position, add the prop to the take
    console.log("test");

    if (propLibraryTargetPosition) {
      dispatch(
        addPropStateToSelectedTake({
          instanceId: id,
          positionId: propLibraryTargetPosition,
        })
      );
    }

    dispatch(setIsPropLibraryOpen({ open: false, positionId: "" }));
  };

  const openPropLibrary = (positionId: string) => {
    dispatch(setIsPropLibraryOpen({ open: true, positionId: positionId }));
  };

  const closePropLibrary = () => {
    dispatch(setIsPropLibraryOpen({ open: false, positionId: "" }));
  };

  const openQuickActorModal = (positionId: string) => {
    // add new actor
    dispatch(addActor(ActorHelper.GetNewRandomActor()));

    // open the modal
    dispatch(setIsQuickActorModalOpen({ open: true, positionId: positionId }));
  };

  const closeQuickActorModal = () => {
    dispatch(setIsQuickActorModalOpen({ open: false, positionId: "" }));
  };

  const saveQuickActorModal = () => {
    addActorToSelectedTake(
      currentTake,
      selectedActor!.id,
      quickActorModalPositionId
    );
    dispatch(setIsQuickActorModalOpen({ open: false, positionId: "" }));
  };

  const cancelQuickActorModal = () => {
    dispatch(setIsQuickActorModalOpen({ open: false, positionId: "" }));
    dispatch(deleteActor(selectedActor!.id));
  };

  return {
    addNewPropFromPropLibrary,
    openPropLibrary,
    closePropLibrary,
    isPropLibraryOpen,
    isQuickActorModalOpen,
    closeQuickActorModal,
    openQuickActorModal,
    saveQuickActorModal,
    cancelQuickActorModal,
  };
}
