import { useMemo } from "react";
import { IConnectQuiz } from "../../../models/interactions/ConnectQuiz";
import PiBasicQuizAddButton from "../components/PiBasicQuizAddButton";
import ConnectQuizGraphicsAnswer from "../connect_graphics_quiz/ConnectGraphicsQuizAnswer";
import ConnectQuizAnswer from "./ConnectQuizAnswer";
import useConnectQuiz from "./ConnectQuiz.hook";
import PiBasicGraphicsQuizAddButton from "../components/PiBasicGraphicsQuizAddButton";
import classNames from "classnames";

export default function ConnectGraphicsQuizView(props: { quiz: IConnectQuiz }) {
  const { quiz } = { ...props };

  const vertical = useMemo(() => {
    return quiz.properties?.find((p) => p.id === "verticalLayout")?.value;
  }, [quiz.properties]);

  const {
    selectedAnswerA,
    selectedAnswerB,
    onConnectionClick,
    onDeleteAnswer,
    addAnswerA,
    addAnswerB,
    onAssetSelect,
    connections,
  } = useConnectQuiz(quiz);

  const answersA = quiz.answersA.map((answer, index) => {
    return (
      <ConnectQuizAnswer
        key={answer.id}
        answer={answer}
        side="A"
        isSelected={selectedAnswerA === answer.id}
        onConnectionClick={onConnectionClick}
        canDelete={quiz.answersA.length > 2}
        onDelete={onDeleteAnswer}
        horizontal={!vertical}
      />
    );
  });

  // connect graphics quiz
  const rowA = (
    <div
      className={classNames({
        "flex-1": true,
        "flex items-center justify-center gap-[16px] h-[30%]": !vertical,
        "flex flex-col items-center justify-center gap-[10%]": vertical,
      })}
    >
      {answersA}
      {quiz.answersA.length < 5 && (
        <PiBasicQuizAddButton onClick={addAnswerA} />
      )}
    </div>
  );

  const rowB = (
    <div
      className={classNames({
        "flex-1 ": true,
        "flex items-center justify-center  gap-[16px] min-h-[70%] max-h-[70%]":
          !vertical,
        "flex flex-col items-center justify-center gap-[16px]": vertical,
      })}
    >
      {quiz.answersB.map((answer, index) => {
        return (
          <ConnectQuizGraphicsAnswer
            key={answer.id}
            answer={answer}
            side="B"
            isSelected={selectedAnswerB === answer.id}
            onConnectionClick={onConnectionClick}
            canDelete={quiz.answersB.length > 2}
            onDelete={onDeleteAnswer}
            onAssetSelect={onAssetSelect}
            horizontal={!vertical}
          />
        );
      })}
      {quiz.answersB.length < 5 && (
        <div
          className={classNames({
            "connectquizbody-add-answer-gfx": !vertical,
            "w-full": vertical,
          })}
        >
          <PiBasicGraphicsQuizAddButton onClick={addAnswerB} />
        </div>
      )}
    </div>
  );

  return (
    <>
      <div
        className={classNames({
          " w-full h-full relative": true,
          "flex flex-col justify-center": !vertical,
          "flex gap-[16px]": vertical,
        })}
      >
        {rowA}
        {rowB}
      </div>
      {connections}
    </>
  );
}
