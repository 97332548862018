import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "@mui/icons-material/Close";

export interface PiModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  width: string;
  height: string;
  children?: React.ReactNode;
}

export default function PiModal(props: PiModalProps) {
  const { isOpen, onClose, width, height, title, children } = { ...props };

  const closeButton = (
    <div
      className={
        "absolute top-[22px] right-[10px] rounded-full text-creator-text hover:bg-interaction-primary-selected"
      }
    >
      <button onClick={onClose} className="p-2">
        <CloseIcon color={"inherit"} />
      </button>
    </div>
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog className={"absolute z-[10000]"} onClose={onClose}>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center w-screen">
          <Dialog.Panel
            className="relative rounded-[5px] bg-white p-[20px] flex flex-col"
            style={{ width: width, height: height }}
          >
            <Dialog.Title
              className={
                "h-[64px] w-full text-creator-text text-[28px] font-bold border-b-2 border-solid border-creator-border"
              }
            >
              {title}
            </Dialog.Title>
            {closeButton}
            <div className={"flex-1 overflow-y-hidden pt-[12px]"}>
              {children}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}
