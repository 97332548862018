import { useSelector } from "react-redux";
import {
  selectSelectedSituationId,
  selectSituations,
  selectSituationsInitialized,
  setSelectedSituation,
} from "../../app/slices/SituationSlice";
import UsePropLibrary from "../prop_library/PropLibrary.hooks";
import useSituationPagination from "./SituationPagination.hook";
import PropPagedGridView from "../prop_library/PropPagedGridView";
import { TranslatedPropDTO } from "../../dto/TranslatedPropDTO";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export default function SituationStrategyItems() {
  const allProps = useSelector(selectSituations);
  const selectedSituationId = useSelector(selectSelectedSituationId);
  const { LoadFirstPage, onRerender } = useSituationPagination();
  const { loadSituationContent } = UsePropLibrary();
  const dispatch = useDispatch();
  const initialized = useSelector(selectSituationsInitialized);

  useEffect(() => {
    if (!initialized) {
      LoadFirstPage();
    }
  });

  const onCardClick = (prop: TranslatedPropDTO) => {
    dispatch(setSelectedSituation(prop.id));
    loadSituationContent(prop.id);
  };

  return (
    <PropPagedGridView
      allProps={allProps}
      selectedPropId={selectedSituationId}
      onCardClick={onCardClick}
      onRerender={onRerender}
    />
  );
}
