import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useDispatch } from "react-redux";
import {
  selectIsCollapsableColumnOpen,
  selectSelectedCollapsableColumn,
  setIsCollapsableColumnOpen
} from "../../app/slices/GlobalSlice";
import classNames from "classnames";
import { useSelector } from "react-redux";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";

export default function CollapsableColumnToggleButton(props: {
  layout: "absolute" | "relative";
}) {
  const { layout } = { ...props };
  const isOpen = useSelector(selectIsCollapsableColumnOpen);
  const selectedCollapsableColumn = useSelector(selectSelectedCollapsableColumn);
  const dispatch = useDispatch();
  const onClick = () =>
    dispatch(
      setIsCollapsableColumnOpen({
        open: !isOpen,
        column: selectedCollapsableColumn,
      })
    );

  return (
    <div
      className={classNames({
        "z-[100]  h-[60px] bg-creator-primary-background text-creator-text-sub text-[24px] flex justify-center items-center cursor-pointer":
          true,
        "absolute top-0 left-[292px] rounded-br-5  w-[64px]":
          layout === "absolute",
        "border-solid border-b-2 border-l-2 border-creator-border min-w-[64px] ":
          layout === "relative",
      })}
      onClick={onClick}
    >
      {!isOpen && (
        <KeyboardDoubleArrowRightIcon color="inherit" fontSize="inherit" />
      )}
      {isOpen && (
        <KeyboardDoubleArrowLeftOutlinedIcon
          color="inherit"
          fontSize="inherit"
        />
      )}
    </div>
  );
}
