import classNames from "classnames";
import { LanguageData } from "../../app/slices/TrainingLocalizationSlice";
import PiCheckbox from "../../components/PiCheckbox";
import PiTag from "../../components/PiTag";

export default function AddLanguageDialogOption(props: {
  language: LanguageData;
  onToggle: (language: LanguageData) => void;
  selected: boolean;
}) {
  const { language, onToggle, selected } = props;
  return (
    <div
      className={classNames({
        " flex items-center gap-[8px] w-full hover:bg-creator-childActive rounded-[5px] pl-[8px] pr-[12px] py-[12px] cursor-pointer":
          true,
        "bg-creator-childHover": selected,
      })}
      onClick={() => onToggle(language)}
    >
      <PiCheckbox
        checked={selected}
        onToggle={() => onToggle(language)}
        shape="rounded"
      />
      <div className="text-[14px] flex-1">{language.full}</div>
      <PiTag text={language.shortcode} variant="blue" />
    </div>
  );
}
