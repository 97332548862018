import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../app/slices/GlobalSlice";
import {
  LanguageData,
  selectAvailableLanguagesNotAdded,
} from "../../app/slices/TrainingLocalizationSlice";
import { selectTrainingName } from "../../app/slices/TrainingSlice";
import PiDialog from "../../components/PiDialog";
import PiMenuButton from "../menu/PiMenuButton";
import { useAddLanguage } from "./AddLanguageHook";
import "./TrainingLocalizationDialog.css";
import TrainingLocalizationTable from "./TrainingLocalizationTable";
import { useTranslation } from "react-i18next";
import TrainingLocalizationDialogHeader from "./TrainingLocalizationDialogHeader";
import AddLanguageDialog from "./AddLanguageDialog";
import PiModal from "../../components/PiModal";
interface TrainingLocalizationDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function TrainingLocalizationDialog(
  props: TrainingLocalizationDialogProps
) {
  const { open, onClose } = { ...props };
  const [isAddLanguageOpen, setIsAddLanguageOpen] = useState<boolean>(false);
  const isLoading = useSelector(selectIsLoading);
  const { t } = useTranslation();

  const trainingName = useSelector(selectTrainingName);
  const availableLanguages = useSelector(selectAvailableLanguagesNotAdded);

  const header = (
    <div className="traininglocalizationpicker-header">
      <span>{t("translations")}</span>
      <span className="traininglocalizationpicker-title">| {trainingName}</span>
    </div>
  );
  const body = (
    <div className="relative flex w-full h-full">
      {isLoading && (
        <div className="m-auto translate-x-[-50%] translate-y-[-50%]">
          <CircularProgress size="120px" />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="w-full h-full flex flex-col gap-[16px] pb-[20px]">
            <TrainingLocalizationTable
              setIsAddLanguageOpen={setIsAddLanguageOpen}
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <div>
      <PiDialog
        open={open}
        onClose={onClose}
        width="90vw"
        height="90vh"
        header={header}
        body={body}
      />
      {/* <PiDialog
        open={isAddLanguageOpen}
        onClose={() => setIsAddLanguageOpen(false)}
        width="30vw"
        height="50vh"
        header={addLanguageHeader}
        body={<AddLanguageDialog />}
      /> */}
      <PiModal
        isOpen={isAddLanguageOpen}
        onClose={() => setIsAddLanguageOpen(false)}
        title="Add Language"
        width="450px"
        height="750px"
      >
        <AddLanguageDialog onConfirm={() => setIsAddLanguageOpen(false)} />
      </PiModal>
    </div>
  );
}
