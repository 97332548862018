import { useSelector } from "react-redux";
import { Prop } from "../../../models/Prop";
import { selectAllSituationPropPresets } from "../../../app/slices/TrainingSlice";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import classNames from "classnames";

export function SituationPopoverButton(props: {
  situationPreset: Prop;
  onClick: () => void;
  checked: boolean;
}) {
  const { situationPreset, onClick, checked } = { ...props };

  return (
    <div>
      <div
        className={
          "flex w-[234px] min-w-[234px] mx-auto items-center gap-2  h-[46px] cursor-pointer rounded-[5px] px-[10px]  text-creator-text-sub text-[14px] font-poppins" +
          " font-medium" +
          " hover:bg-interaction-text-hoverBackground " +
          (checked ? "bg-interaction-text-selectedBackground" : "")
        }
        onClick={onClick}
      >
        <div className="flex items-center justify-center font-medium bg-white border-2 border-solid text-creator-secondary w-9 h-9 rounded-circle text-14 border-creator-secondary ">
          <ViewInArIcon fontSize={"inherit"} color={"inherit"} />
        </div>
        {situationPreset.name}
      </div>
    </div>
  );
}

export default function SituationPointPopover(props: {
  onClick: (situationId: string) => void;
  onRemove: () => void;
  canRemove: boolean;
  assignedSituation: string | undefined;
}) {
  const { onClick, onRemove, assignedSituation } = { ...props };
  const allSituationPresets = useSelector(selectAllSituationPropPresets);
  const checked = (situationId: string) =>
    !!assignedSituation && assignedSituation === situationId;

  const buttons = allSituationPresets.map((situation) => {
    return (
      <SituationPopoverButton
        key={situation.id}
        situationPreset={situation}
        onClick={() => onClick(situation.id)}
        checked={checked(situation.id)}
      />
    );
  });

  const deleteButton = (
    <div
      className={classNames({
        "h-[46px] flex items-center rounded-[5px] px-[10px] font-poppins": true,
        "cursor-pointer text-creator-error  hover:bg-interaction-text-hoverBackground":
          assignedSituation !== undefined,
        "cursor-not-allowed text-creator-primary-disabled":
          assignedSituation === undefined,
      })}
      onClick={onRemove}
    >
      <div className={"flex gap-[10px] text-[24px]"}>
        <div className="w-[36px] h-[36px] justify-center items-center flex">
          <DeleteOutlineOutlinedIcon color="inherit" fontSize="inherit" />
        </div>
        <div className="text-[14px] font-medium my-auto">Delete</div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-[10px]">
      {buttons}
      {deleteButton}
    </div>
  );
}
