import { addSelectedPropAsUsedProp } from "../../app/slices/PropLibrarySlice";
import { selectSelectedPropContent } from "../../app/slices/PropLibrarySlice";
import { selectSelectedPropMetadata } from "../../app/slices/PropLibrarySlice";
import { useDispatch, useSelector } from "react-redux";
import { addPropPreset } from "../../app/slices/TrainingSlice";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { PropType } from "../../models/Prop";
import {
  selectIsPropLibraryOpen,
  setIsPropLibraryOpen,
} from "../../app/slices/GlobalSlice";

export default function usePropPointPreview() {
  const dispatch = useDispatch();

  const selectedPropContent = useSelector(selectSelectedPropContent);
  const selectPropMetadata = useSelector(selectSelectedPropMetadata);
  const isPropLibraryOpen = useSelector(selectIsPropLibraryOpen);

  const addNewPropFromPropLibrary = () => {
    if (!selectedPropContent || !selectPropMetadata) return;

    dispatch(
      addPropPreset({
        id: GetNewGuid(),
        propId: selectedPropContent.id,
        name: selectedPropContent.name,
        prefabName: selectPropMetadata.prefab_name,
        propType: PropType.Default,
      })
    );
    dispatch(addSelectedPropAsUsedProp()); // add prop to cached props
    dispatch(setIsPropLibraryOpen({ open: false, positionId: "" }));
  };

  const openPropLibrary = (positionId: string) => {
    dispatch(setIsPropLibraryOpen({ open: true, positionId: positionId }));
  };

  const closePropLibrary = () => {
    dispatch(setIsPropLibraryOpen({ open: false, positionId: "" }));
  };

  return {
    addNewPropFromPropLibrary,
    openPropLibrary,
    closePropLibrary,
    isPropLibraryOpen,
  };
}
