import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIcon from "@mui/icons-material/DragIndicator";
import React, { useRef, useState, useEffect } from "react";
import { CSS } from "@dnd-kit/utilities";
import PiEditMenu from "./PiEditMenu";
import { useSortable } from "@dnd-kit/sortable";
import "./PiDragButton.css";
import { UniqueIdentifier } from "@dnd-kit/core";

interface PiDragButtonProps {
  uniqueId: UniqueIdentifier;
  isDragging?: boolean;
  text?: string;
  placeholder?: string;
  highlight?: boolean;
  error?: boolean;
  notifyChangeSentence?: () => void;
  onChange?: (text: string) => void;
  canDelete?: boolean;
  onDelete?: () => void;
  canToggle?: boolean;
  isToggled?: boolean;
  onToggle?: () => void;
}

export default function PiDragButton(props: PiDragButtonProps) {
  const {
    text,
    placeholder,
    onChange,
    highlight,
    error,
    notifyChangeSentence,
    canDelete,
    onDelete,
    canToggle,
    isToggled,
    onToggle,
  } = { ...props };
  const {
    isDragging,
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
  } = useSortable({ id: props.uniqueId });
  const parentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const dragStyle = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    opacity: isDragging ? 0.5 : 1,
  };

  useEffect(() => {
    setNodeRef(parentRef.current);
  }, [parentRef, setNodeRef]);

  const onFocus = () => {
    if (canDelete) {
      setIsFocused(true);
    }
  };

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
      //inputRef.current.setSelectionRange(text?.length ?? 0, text?.length ?? 0); // Set cursor to the end
    }
  }, [isFocused, text?.length]);

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const focusedElement = event.relatedTarget || document.activeElement;

    if (!parentRef?.current?.contains(focusedElement)) {
      setIsFocused(false);
    }
  };

  const editMenu = (
    <PiEditMenu
      buttonSettings={[
        {
          icon: <DeleteIcon color="inherit" fontSize="inherit" />,
          onClick: () => {
            setIsFocused(false);
            onDelete?.();
          },
          highlight: false,
        },
        {
          icon: <CheckIcon fontSize="inherit" color="inherit" />,
          onClick: onToggle!,
          highlight: isToggled,
        },
      ]}
    />
  );

  const editMenuNoToggle = (
    <PiEditMenu
      buttonSettings={[
        {
          icon: <DeleteIcon color="inherit" fontSize="inherit" />,
          onClick: () => {
            setIsFocused(false);
            onDelete?.();
          },
          highlight: false,
        },
      ]}
    />
  );

  return (
    <div
      className="pidragbutton-root"
      ref={parentRef}
      tabIndex={-1}
      onBlur={handleBlur}
      style={dragStyle}
    >
      <button
        className="pidragbutton-drag-icon"
        {...listeners}
        {...attributes}
        tabIndex={-1}
      >
        <DragIcon color="inherit" fontSize="large" />
      </button>

      <input
        className={
          "pidragbutton " +
          (highlight
            ? "pidragbutton-text-correct"
            : "pidragbutton-text-wrong") +
          (error ? "pidragbutton-error" : "")
        }
        placeholder={placeholder}
        ref={inputRef}
        value={text}
        onFocus={onFocus}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
          if (notifyChangeSentence) notifyChangeSentence();
        }}
      />

      {isFocused && !canToggle && canDelete && (
        <div className="pidragbutton-toggle">{editMenuNoToggle}</div>
      )}
      {isFocused && canToggle && (
        <div className="pidragbutton-toggle">{editMenu}</div>
      )}
    </div>
  );
}
