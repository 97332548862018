export default function PiCreatorDropdownItem(props: {
  icon?: JSX.Element;
  text: string;
  onClick: () => void;
}) {
  const { icon, text, onClick } = { ...props };

  return (
    <div className="flex gap-[10px] items-center h-full" onClick={onClick}>
      {icon && <div className="text-[24px] text-creator-text">{icon}</div>}
      <div className="my-auto text-[14px] text-creator-text font-medium h-[36px] flex items-center">
        {text}
      </div>
    </div>
  );
}
