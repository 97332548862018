import { useSelector } from "react-redux";
import {
  LanguageData,
  deleteLocalizedLanguage,
  selectTrainingLocalizationState,
} from "../../app/slices/TrainingLocalizationSlice";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  selectReferenceLanguage,
  selectTrainingName,
} from "../../app/slices/TrainingSlice";

export const useTrainingLocalizationTable = () => {
  const dispatch = useDispatch();
  const locState = useSelector(selectTrainingLocalizationState);
  const projName = useSelector(selectTrainingName);
  const referenceLanguage = useSelector(selectReferenceLanguage);
  const inputRef = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState("");

  const [checkedLangs, setCheckedLangs] = useState<string[]>([]);

  const getPercentageTranslated = (shortcode: string) => {
    var total = 0;
    var translated = 0;
    var entries = locState.localizedEntries;
    for (var key in entries) {
      total += 1;
      if (
        entries[key][shortcode].value !== "" &&
        !entries[key][shortcode].isAutoTranslated
      ) {
        translated += 1;
      }
    }

    if (total === 0) return "0%";
    return Math.round((translated / total) * 100) + "%";
  };

  const getPercentageAutoTranslated = (shortcode: string) => {
    var total = 0;
    var translated = 0;
    var entries = locState.localizedEntries;
    for (var key in entries) {
      total += 1;
      if (
        entries[key][shortcode].value !== "" &&
        entries[key][shortcode].isAutoTranslated
      ) {
        translated += 1;
      }
    }
    if (total === 0) return "0%";
    return Math.round((translated / total) * 100) + "%";
  };

  const getPercentageNotTranslated = (shortcode: string) => {
    var total = 0;
    var translated = 0;
    var entries = locState.localizedEntries;
    for (var key in entries) {
      total += 1;
      if (entries[key][shortcode].value === "") {
        translated += 1;
      }
    }
    if (total === 0) return "0%";
    return Math.trunc((translated / total) * 100) + "%";
  };

  const removeLocalizedLanguage = (lang: LanguageData) => {
    dispatch(deleteLocalizedLanguage(lang));
  };

  const getCheckedState = () => {
    if (checkedLangs.length === 0) return "none";
    if (checkedLangs.length === locState.localizedLanguages.length)
      return "all";
    return "some";
  };

  const selectAllLanguages = (select: boolean) => {
    console.log("selectAllLanguages", select);
    if (select) {
      const test = locState.localizedLanguages.map((lang) => lang.shortcode);
      setCheckedLangs(test);
    } else {
      setCheckedLangs([]);
    }
  };

  const checkLanguage = (shortcode: string, checked: boolean) => {
    if (checked) {
      setCheckedLangs([...checkedLangs, shortcode]);
    } else {
      setCheckedLangs(checkedLangs.filter((code) => code !== shortcode));
    }
  };

  const getCheckLanguagesData = () => {
    const languages = locState.localizedLanguages.filter((lang) =>
      checkedLangs.includes(lang.shortcode)
    );
    return languages;
  };

  return {
    getPercentageTranslated,
    getPercentageAutoTranslated,
    removeLocalizedLanguage,
    getPercentageNotTranslated,
    checkedLangs,
    checkLanguage,
    getCheckedState,
    selectAllLanguages,
    getCheckLanguagesData,
    filter,
    setFilter,
  } as const;
};
