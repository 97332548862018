import InfoIcon from "@mui/icons-material/Info";

export default function InteractionErrorsView(props: { errors: string[] }) {
  const { errors } = { ...props };

  const errorsMapped = errors.map((error) => {
    return (
      <li key={error} className={"list-disc text-[14px] text-creator-text-sub"}>
        {error}
      </li>
    );
  });
  return (
    <div
      className={
        "w-full p-[16px] flex flex-col gap-[8px] rounded-[5px] border-2 border-solid border-creator-error bg-creator-error-washed"
      }
    >
      <div className={"text-creator-error flex gap-[8px]"}>
        <InfoIcon className={"my-auto"} color={"inherit"} />
        <div className={"my-auto font-medium text-[16px]"}>Error</div>
      </div>
      <ul className={"px-[24px]"}>{errorsMapped}</ul>
    </div>
  );
}
