import { useSelector } from "react-redux";
import { selectTakes } from "../../../app/slices/TrainingSlice";
import {
  selectErrors,
  selectWarnings,
} from "../../../app/slices/ValidationSlice";
import { useCallback } from "react";
import Take from "../../../models/Take";
import Interaction from "../../../models/interactions/Interaction";
import LogData from "./LogData";

export default function useErrorLogColumn() {
  const allTakes = useSelector(selectTakes);
  const allWarnings = useSelector(selectWarnings);
  const allErrors = useSelector(selectErrors);

  const getLogData = useCallback(() => {
    return allTakes
      .map((take) => {
        return getLogDataForTake(take);
      })
      .flat();
  }, [allTakes, allWarnings, allErrors]);

  const getLogDataForTake = (take: Take) => {
    return take.interactions
      .map((interaction) => {
        return getLogDataForInteraction(take, interaction);
      })
      .flat();
  };

  const getLogDataForInteraction = (parent: Take, interaction: Interaction) => {
    const logs: LogData[] = [];

    // errors
    allErrors[interaction.id]?.map((error) => {
      logs.push({
        interactionId: interaction.id,
        interactionName: interaction.name,
        logLevel: "error",
        message: error,
        parentName: parent.name,
      });
    });

    // warnings
    allWarnings[interaction.id]?.map((warning) => {
      logs.push({
        interactionId: interaction.id,
        interactionName: interaction.name,
        logLevel: "warning",
        message: warning,
        parentName: parent.name,
      });
    });

    return logs;
  };

  return { getLogData } as const;
}
