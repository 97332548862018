import {
  selectCurrentEnvironmentBounds,
  selectEnvironmentInteractionPoints,
  TakePosition,
} from "../../../app/slices/EnvironmentSlice";
import {
  addSituationToTakeLocation,
  removeSituationFromTakeLocation,
  selectAllPropPresets,
  selectIsCameraSelected,
  selectSelectedTake3D,
} from "../../../app/slices/TrainingSlice";
import { PropType } from "../../../models/Prop";
import { selectUsedProps } from "../../../app/slices/PropLibrarySlice";
import { SituationMetadata } from "./../../../dto/SituationMetadata";
import NpcPointPreview from "../NpcPointPreview";
import useSituationHelper from "../../../hooks/SituationHelper.hook";
import { GetNewGuid } from "../../../helpers/GuidHelper";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import InteractionPointPreview, {
  CameraPointStrategy,
  NpcPointStrategy,
  SelectedCameraPointStrategy,
} from "../InteractionPointPreview";
import { useCallback } from "react";
import SituationPropPoint from "./SituationPropPoint";

export default function useViewport3DSituationLayer() {
  const dispatch = useDispatch();
  const takePositions = useSelector(selectEnvironmentInteractionPoints);
  const situations = useSelector(selectAllPropPresets).filter(
    (p) => p.propType === PropType.Situation
  );
  const usedProps = useSelector(selectUsedProps);
  const bounds = useSelector(selectCurrentEnvironmentBounds);
  const currentTake = useSelector(selectSelectedTake3D);
  const isCameraSelected = useSelector(selectIsCameraSelected);

  const { getSituationMetadata } = useSituationHelper();

  const onSelectSituationForLocation = useCallback(
    (takePosition: TakePosition, situationInstanceId: string) => {
      if (!currentTake) return;

      // todo: remove actors that are in take location already
      // get npc positions for this take location
      const targetTakePosition = takePositions.find(
        (point) => point.id === takePosition.id
      );
      const actorsToRemove = currentTake.actors
        .filter((actor) =>
          targetTakePosition?.actor_positions.some((ap) =>
            actor.positionId.includes(ap.id)
          )
        )
        .map((actor) => actor.id);

      const metadata = getSituationMetadata(situationInstanceId);

      const fallbackCameraId =
        situationInstanceId + "/" + metadata.camera_positions[0].id;
      dispatch(
        addSituationToTakeLocation({
          takePosition: takePosition.id,
          situationInstanceId: situationInstanceId,
          fallbackCameraId: fallbackCameraId,
          actorsToRemove: actorsToRemove,
        })
      );
    },
    [getSituationMetadata, currentTake, dispatch, takePositions]
  );

  const onRemoveSituationForLocation = (takePosition: TakePosition) => {
    if (!currentTake) return;

    // Do some magic here
    // all npcs that are assigned to take pos here, remove them
    const situationId = currentTake.situations[takePosition.id];

    const actorsToRemove = currentTake.actors
      .filter((actor) => actor.positionId.includes(situationId))
      .map((actor) => actor.id);

    dispatch(
      removeSituationFromTakeLocation({
        takePositionId: takePosition.id,
        fallbackCameraId: takePosition.camera_positions[0].id,
        actorsToRemove: actorsToRemove,
      })
    );

    // get situation metadata
    // get all actor positions
  };

  const GetAllNodesForAllUsedSituations = () => {
    if (currentTake === undefined) return <></>;
    let views: JSX.Element[] = [];

    for (const [takePositionId, situationId] of Object.entries(
      currentTake.situations
    )) {
      views = [
        ...views,
        ...getAllSituationNodeViews(takePositionId, situationId),
      ];
    }

    return views;
  };

  const getAllSituationNodeViews = (
    takePositionId: string,
    situationId: string
  ) => {
    const nodes: JSX.Element[] = [];

    // 1. get the take position where the situation takes place
    const myTakePosition = takePositions.find(
      (point) => point.id === takePositionId
    );
    // 2. get the situation preset
    const mySituationPreset = situations.find((s) => s.id === situationId);

    if (myTakePosition === undefined || mySituationPreset === undefined) {
      throw new Error("Could not find take position or situation");
    }

    // 3. use the situation preset id to get the meta-data
    const mySituationMetadata = usedProps[mySituationPreset.propId]
      .metadata as SituationMetadata;
    if (mySituationMetadata === undefined) {
      throw new Error("Could not find situation metadata");
    }

    // 4. all actors
    mySituationMetadata.actor_positions.map((actorPosition) => {
      const x =
        actorPosition.position.x / bounds.width + myTakePosition.position.x;
      const y =
        (actorPosition.position.y * -1) / bounds.height +
        myTakePosition.position.y;

      nodes.push(
        <NpcPointPreview
          x={x}
          y={y}
          strategy={NpcPointStrategy}
          positionId={situationId + "/" + actorPosition.id}
          key={takePositionId + "/" + actorPosition.id}
          disabled={isCameraSelected}
        />
      );
    });

    //5. all props
    mySituationMetadata.prop_positions.map((propPosition) => {
      const x =
        propPosition.position.x / bounds.width + myTakePosition.position.x;
      const y =
        -propPosition.position.y / bounds.height + myTakePosition.position.y;

      nodes.push(
        <SituationPropPoint
          key={takePositionId + "/" + propPosition.id}
          x={x}
          y={y}
          disabled={isCameraSelected}
          positionId={propPosition.id}
          situationId={situationId}
        />
      );
    });

    return nodes;
  };

  const getAllSituationCameraViews = (
    situationId: string,
    currentCamId: string,
    currentTakeLocationId: string,
    onCamPointClick: (camId: string) => void
  ) => {
    console.log("take position ", currentTakeLocationId);

    const myTakePosition = takePositions.find(
      (point) => point.id === currentTakeLocationId
    );
    const mySituationPreset = situations.find((s) => s.id === situationId);

    if (mySituationPreset === undefined) {
      throw new Error("Could not find take position or situation");
    }

    // 3. use the situation preset id to get the meta-data
    const mySituationMetadata = usedProps[mySituationPreset.propId]
      .metadata as SituationMetadata;
    if (mySituationMetadata === undefined) {
      throw new Error("Could not find situation metadata");
    }

    return mySituationMetadata.camera_positions.map((cam, index) => {
      const x = cam.position.x / bounds.width + myTakePosition!.position.x;
      const y = -cam.position.y / bounds.height + myTakePosition!.position.y;
      return (
        <InteractionPointPreview
          strategy={
            currentCamId === mySituationPreset.id + "/" + cam.id
              ? SelectedCameraPointStrategy
              : CameraPointStrategy
          }
          x={x}
          y={y}
          key={cam.id}
          onClick={() => onCamPointClick(mySituationPreset.id + "/" + cam.id)}
        />
      );
    });
  };

  const getSituationSelectedCameraView = (
    situationId: string,
    currentTakeLocationId: string,
    mainCameraId: string,
    onClick: () => void
  ) => {
    const myTakePosition = takePositions.find(
      (point) => point.id === currentTakeLocationId
    );
    const mySituationPreset = situations.find((s) => s.id === situationId);

    if (mySituationPreset === undefined) {
      throw new Error("Could not find take position or situation");
    }

    // 3. use the situation preset id to get the meta-data
    const mySituationMetadata = usedProps[mySituationPreset.propId]
      .metadata as SituationMetadata;
    if (mySituationMetadata === undefined) {
      throw new Error("Could not find situation metadata");
    }

    const camPos = mySituationMetadata.camera_positions.find(
      (cam) => cam.id === mainCameraId
    );

    if (camPos !== undefined) {
      const x = camPos.position.x / bounds.width + myTakePosition!.position.x;
      const y = -camPos.position.y / bounds.height + myTakePosition!.position.y;
      return (
        <InteractionPointPreview
          key={GetNewGuid()}
          strategy={SelectedCameraPointStrategy}
          x={x}
          y={y}
          onClick={onClick}
        />
      );
    } else return <></>;
  };

  return {
    onSelectSituationForLocation,
    onRemoveSituationForLocation,
    GetAllNodesForAllUsedSituations,
    getAllSituationNodeViews,

    getAllSituationCameraViews,
    getSituationSelectedCameraView,
  } as const;
}
