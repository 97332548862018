import { NpcPointStrategy } from "./InteractionPointPreview";
import NpcPointPreview from "./NpcPointPreview";
import { Viewport3DLayerProps } from "./Viewport3DEnvironmentMetadata";
import { selectSelectedTake3D } from "../../app/slices/TrainingSlice";
import { useSelector } from "react-redux";

export default function Viewport3DNpcLayer(props: Viewport3DLayerProps) {
  const { currentTakeLocation, takePositions, isCameraSelected } = { ...props };
  const situationsInTake = useSelector(selectSelectedTake3D)?.situations;

  const npcs = takePositions
    .filter((pos) => situationsInTake && !(pos.id in situationsInTake))
    .map((point, index) => {
      return point.actor_positions.map((actorPos, index) => {
        return (
          <NpcPointPreview
            strategy={NpcPointStrategy}
            x={actorPos.position.x}
            y={actorPos.position.y}
            positionId={actorPos.id}
            key={actorPos.id}
            disabled={isCameraSelected}
          />
        );
      });
    });

  return <>{currentTakeLocation && npcs}</>;
}
