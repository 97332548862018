import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import LookatPreview from "../../features/previews/lookat/LookatPreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface ILookatConfig {
  sourceNpcId: string;
  targetId: string;
}

export interface ILookat extends Interaction {
  lookats: ILookatConfig[];
}

export const lookatInteraction = (): InteractionFactoryEntity => {
  const view = <LookatPreview />;
  const create = (getKeys: (amount: number) => string[]): ILookat => {
    return {
      id: GetNewGuid(),
      name: "Look At",
      type: InteractionType.Lookat,
      lookats: [
        {
          targetId: "",
          sourceNpcId: "",
        },
      ],
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): ILookat => {
    return interaction as ILookat;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): ILookat => {
    const fromLookat = interaction as ILookat;

    return {
      id: GetNewGuid(),
      name: fromLookat.name,
      type: fromLookat.type,
      lookats: [...fromLookat.lookats],
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as ILookat;
    const warnings: string[] = [];

    for (let i = 0; i < converted.lookats.length; i++) {
      const lookat = converted.lookats[i];

      if (!lookat.sourceNpcId) warnings.push(`Npc Id ${i + 1} is empty `);
      if (!lookat.targetId) warnings.push(`Target Id ${i + 1} is empty`);
    }

    return warnings;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {
    const searchableStrings: string[] = [interaction.name];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType
    );
  };

  const filterType: string = "LookAt";

  return {
    View: view,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
