import Take3D from "../../models/Take3D";
import { Actor } from "../../models/Actor";
import Interaction, {
  InteractionType,
} from "../../models/interactions/Interaction";
import { IAttach } from "../../models/interactions/Attach";
import { IDetach } from "../../models/interactions/Detach";

export class ValidationHelper {
  static ValidateKey(
    key: string,
    getValue: (key: string) => string,
    warnings: string[],
    subject: string
  ) {
    const value = getValue(key);
    if (value === "") warnings.push(`${subject} has no text`);
  }

  static ValidateAssetId(
    assetId: string,
    warnings: string[],
    customWarning?: string
  ) {
    if (assetId === "")
      warnings.push(
        customWarning ? `${customWarning} not selected` : "No asset selected"
      );
  }

  static ValidateNpcInTake(
    actorId: string,
    take: Take3D,
    actors: Actor[],
    warnings: string[]
  ) {
    const actor = actors.find((actor) => actor.id === actorId);
    if (!actor) return;

    let found = take.actors.find((actor) => actor.actorId === actorId);
    if (!found)
      warnings.push(`${actor.workingName} was not found in current take`);
  }

  static ValidatIsPropAttached(
    parent: Take3D,
    interaction: Interaction,
    propGuid: string
  ) {
    let valid = false;

    // check if it is in the attachments of the take
    if (Object.keys(parent.attachments).find((key) => key === propGuid))
      valid = true;

    // via attach interaction
    for (let i = 0; i < parent.interactions.length; i++) {
      const currentInteraction = parent.interactions[i];
      if (currentInteraction === interaction) break; // stop at current interaction

      // if we attach the current interaction, it is valid
      if (currentInteraction.type === InteractionType.Attach) {
        if ((currentInteraction as IAttach).propGuid === propGuid) valid = true;
      }
      // if we detach the prop again before we do the interaction, it is invalid
      if (currentInteraction.type === InteractionType.Detach) {
        if ((currentInteraction as IDetach).propGuid === propGuid)
          valid = false;
      }
    }

    return valid;
  }
}
