import React from "react";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { IFormQuiz } from "../../../models/Quiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiBasicGraphicsQuizAddButton from "../components/PiBasicGraphicsQuizAddButton";
import PiBasicQuizAddButton from "../components/PiBasicQuizAddButton";
import { PiQuizesFormPreviewProps } from "../components/PiBasicQuizBody";
import OrderQuizAnswer from "./OrderQuizAnswer";
import "./OrderQuizBody.css";
import PiBasicGraphicsQuizAnswer from "../components/PiBasicGraphicsQuizAnswer";

export default function OrderQuizBody(props: PiQuizesFormPreviewProps) {
  const { multipleCorrectAnswers, displayGraphics } = { ...props };
  const { getValue, changeValue, getNewKey } = useTrainingLocalization();
  const quiz = useSelector(selectSelectedElement) as IFormQuiz;
  const dispatch = useDispatch();
  const canDelete: boolean = quiz.answers.length > 2;
  const canAdd: boolean = quiz.answers.length < 5;

  const handleAnswerChange = (
    id: string,
    field: string,
    value: boolean | string
  ) => {
    const copy = { ...quiz };
    const updatedAnswers = copy.answers.map((answer) => ({ ...answer }));
    const index = updatedAnswers.findIndex((x) => x.id === id);

    if (field === "checked") {
      const checkedAnswer = updatedAnswers[index];

      if (!multipleCorrectAnswers) {
        updatedAnswers.forEach((answer) => {
          answer.correct = false;
        });
      }
      checkedAnswer.correct = value as boolean;
    }

    if (field === "assetId") {
      const answer = updatedAnswers[index];
      answer.assetId = value as string;
    }

    copy.answers = updatedAnswers;
    dispatch(updateSelectedInteraction(copy));
  };

  const handleAddAnswer = () => {
    const newAnswer = { id: uuidv4(), correct: false, text: getNewKey() };
    const updatedAnswers = [...quiz.answers, newAnswer];
    const copy = { ...quiz, answers: updatedAnswers };
    dispatch(updateSelectedInteraction(copy));
  };

  const handleDeleteAnswer = (id: string) => {
    if (quiz.answers.length > 2) {
      const updatedAnswers = quiz.answers.filter((answer) => answer.id !== id);
      const copy = { ...quiz, answers: updatedAnswers };
      dispatch(updateSelectedInteraction(copy));
    }
  };

  const addButton = !displayGraphics ? (
    <div className="orderquizbody-add-button">
      <PiBasicQuizAddButton onClick={handleAddAnswer} />
    </div>
  ) : (
    <PiBasicGraphicsQuizAddButton onClick={handleAddAnswer} />
  );

  function onDragEnd(result: DropResult) {
    if (!result.destination) return;

    // re-order quiz answers
    var startIndex = result.source.index;
    var destIndex = result.destination.index;

    var copy = [...quiz.answers];
    const [removed] = copy.splice(startIndex, 1);
    copy.splice(destIndex, 0, removed);

    var updatedQuiz = { ...quiz };
    updatedQuiz.answers = copy;

    dispatch(updateSelectedInteraction(updatedQuiz));
  }

  const getListStyle = (isDraggingOver: boolean, itemsLength: number) => ({
    display: "flex",
    padding: "8px",
  });

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ) => {
    return {
      cursor: "pointer",
      ...draggableStyle,
    };
  };

  const getItemStyleHorizontal = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ): React.CSSProperties => {
    console.log(draggableStyle?.transform);

    return {
      cursor: "pointer",
      ...draggableStyle,
    };
  };

  const draggableGfxAnswers = quiz.answers.map((answer, index) => {
    return (
      <PiBasicGraphicsQuizAnswer
        key={answer.id}
        answer={answer}
        quizType={"order_quiz"}
        index={index}
        handleAnswerChange={handleAnswerChange}
        getValue={getValue}
        changeValue={changeValue}
        handleDeleteAnswer={handleDeleteAnswer}
        answersLength={quiz.answers.length}
        canDelete={canDelete}
      />
    );
  });

  const draggableAnswers = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={quiz.id} type="order-quiz-answer">
        {(provided, snapshot) => (
          <div
            className="orderquiz-droppable"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {quiz.answers.map((answer, index) => (
              <Draggable key={answer.id} draggableId={answer.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    className="orderquiz-draggable"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    tabIndex={-1}
                  >
                    <OrderQuizAnswer
                      key={answer.id}
                      answer={answer}
                      index={index}
                      handleAnswerChange={handleAnswerChange}
                      getValue={getValue}
                      changeValue={changeValue}
                      handleDeleteAnswer={handleDeleteAnswer}
                      answersLength={quiz.answers.length}
                      canDelete={canDelete}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  const body = !displayGraphics ? (
    <div className="orderquizbody-vert">
      {draggableAnswers}
      {canAdd && addButton}
    </div>
  ) : (
    <div className="orderquizbody-horz">
      {draggableGfxAnswers}
      {canAdd && addButton}
    </div>
  );

  return <div className="orderquizbody-root">{body}</div>;
}
