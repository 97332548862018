import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedElementType,
  selectSelectedTake3D,
  setSelectedInteraction,
  setSelectedTake,
  updateSelectedTake,
} from "../../app/slices/TrainingSlice";
import Take3D from "../../models/Take3D";
import { Viewport3DLayerProps } from "./Viewport3DEnvironmentMetadata";
import { useMapEvents } from "react-leaflet";
import useCameraHelper from "../../hooks/CameraHelper.hook";

export default function Viewport3DTakeCameraLayer(props: Viewport3DLayerProps) {
  const dispatch = useDispatch();
  const { currentTakeLocation, isCameraSelected } = { ...props };
  const currentTake = useSelector(selectSelectedTake3D);
  const currentCamId = currentTake?.mainCamera.cameraId;
  const selectedElementType = useSelector(selectSelectedElementType);
  const { getAllCameraPointsForTakeLocation, getSelectedCamForTakeLocation } =
    useCameraHelper();

  const map = useMapEvents({
    click: (e) => {
      // if selected element is cam, deselect ! -> is onBlur basically
      if (selectedElementType === "camera")
        dispatch(setSelectedTake(currentTake!.id));
    },
  });

  const onCamPointClick = (cameraId: string) => {
    if (!currentTake) return;
    if (cameraId === currentCamId) return;

    const updatedTake: Take3D = {
      ...currentTake!,
      mainCamera: { ...currentTake!.mainCamera, cameraId: cameraId },
    };
    dispatch(updateSelectedTake(updatedTake));
  };

  const onSelectedCamClick = () => {
    dispatch(setSelectedInteraction(currentTake!.mainCamera.id));
  };

  const cams = useMemo(() => {
    if (!currentTakeLocation || !currentTake) return <></>;

    return getAllCameraPointsForTakeLocation(
      currentTakeLocation,
      currentTake?.mainCamera.cameraId,
      onCamPointClick
    );
  }, [
    getAllCameraPointsForTakeLocation,
    currentTakeLocation,
    onCamPointClick,
    currentTake,
    currentTake?.mainCamera.cameraId,
  ]);

  const selectedCam = useMemo(() => {
    if (!currentTakeLocation || !currentTake) return <></>;

    return getSelectedCamForTakeLocation(
      currentTakeLocation,
      currentTake?.mainCamera.cameraId,
      onSelectedCamClick
    );
  }, [
    getSelectedCamForTakeLocation,
    currentTakeLocation,
    onSelectedCamClick,
    currentTake,
    currentTake?.mainCamera.cameraId,
  ]);

  return (
    <>
      {isCameraSelected && cams}
      {!isCameraSelected && selectedCam}
    </>
  );
}
