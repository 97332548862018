import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { InteractionDefintion } from "./InteractionDefinitions";
import "./InteractionPickerAccordion.css";
import InteractionPickerButton from "./InteractionPickerButton";
import CircleIcon from "@mui/icons-material/Circle";
interface InteractionPickerAccordionProps {
  title: string;
  subTitle?: string;
  interactionDefinitions: InteractionDefintion[];
  onClose: () => void;
  children?: React.ReactNode;
}

export default function InteractionPickerAccordion(
  props: InteractionPickerAccordionProps
) {
  const { title, interactionDefinitions, onClose, children, subTitle } = {
    ...props,
  };

  const pickerbtns = interactionDefinitions.map(
    (interactionDef: InteractionDefintion, index: number) => {
      return (
        <InteractionPickerButton
          key={index}
          interactionDefinition={interactionDef}
          onClose={onClose}
        />
      );
    }
  );

  return (
    <div className="interactionpickeraccordion-root">
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="flex items-center justify-center gap-[8px] interactionpickeraccordion-title">
            {title}
            {subTitle && (
              <>
                <div className="text-creator-text-sub text-[6px]">
                  <CircleIcon fontSize="inherit" color="inherit" />
                </div>
                <div className="flex items-center">
                  <div className="text-creator-text-sub">{subTitle}</div>
                </div>
              </>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="interactionpickeraccordion-pickers">
            {pickerbtns}
            {children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
