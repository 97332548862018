import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedTakeId,
  selectTakes3D,
} from "../../app/slices/TrainingSlice";
import Take3DCard from "./Take3DCard";
import "./Timeline3DTakesList.css";
import { DndContext, DragOverlay, MouseSensor, useSensor } from "@dnd-kit/core";
import useTimeline3DTakesList from "./Timeline3DTakesList.hook";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

export default function Timeline3DTakesList() {
  const takes = useSelector(selectTakes3D);
  const selectedTake = useSelector(selectSelectedTakeId);
  const dispatch = useDispatch();
  const takeRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const { handleDragStart, handleDragEnd, handleDragOver, getDragOverlay } =
    useTimeline3DTakesList();

  // if we don't use this, dndkit blocks the "onClick" event
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });

  useEffect(() => {
    if (selectedTake && takeRefs.current[selectedTake]) {
      takeRefs.current[selectedTake]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [selectedTake]);

  const takesViewDnd = (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      sensors={[mouseSensor]}
    >
      <div className={"flex flex-col gap-[10px] pit-creator-scrollbar "}>
        <SortableContext
          items={takes.map((take) => take.id)}
          strategy={verticalListSortingStrategy}
          id={"takes3d"}
        >
          {takes.map((take, index) => (
            <div ref={(ref) => (takeRefs.current[take.id] = ref)} key={take.id}>
              <Take3DCard
                key={take.id}
                take={take}
                index={index}
                isSelected={selectedTake === take.id}
              />
            </div>
          ))}
        </SortableContext>
      </div>
      <DragOverlay>{getDragOverlay()}</DragOverlay>
    </DndContext>
  );

  return (
    <div
      className={
        "flex flex-col max-h-[calc(100%-102px)] h-[calc(100%-102px)] overflow-y-auto pit-creator-scrollbar px-[18px] pt-[18px] "
      }
    >
      {takesViewDnd}
    </div>
  );
}
