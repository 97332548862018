import classNames from "classnames";
import CheckIcon from "@mui/icons-material/Check";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
export default function PiIndeterminateCheckbox(props: {
  checked: "true" | "false" | "indeterminate";
  onToggle: (checked: boolean) => void;
}) {
  const { checked, onToggle } = props;

  const onClick = () => {
    if (checked === "indeterminate" || checked === "false") {
      onToggle(true);
    } else {
      onToggle(false);
    }
  };

  return (
    <div
      className={classNames({
        "w-[20px] h-[20px] rounded-[5px] flex justify-center items-center cursor-pointer ":
          true,
        "text-white bg-creator-primary hover:bg-creator-primary-hover text-[16px]":
          checked === "true",
        "border-2 border-solid border-creator-text-sub": checked === "false",
        "border-2 border-solid border-creator-primary text-creator-primary":
          checked === "indeterminate",
      })}
      onClick={onClick}
    >
      {checked === "true" && <CheckIcon color="inherit" fontSize="inherit" />}
      {checked === "indeterminate" && (
        <HorizontalRuleOutlinedIcon color="inherit" fontSize="inherit" />
      )}
    </div>
  );
}
