import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TranslatedPropCategoryDTO } from "../../dto/TranslatedPropCategoryDTO";
import { TranslatedPropDTO } from "../../dto/TranslatedPropDTO";
import { PropMetadata } from "../../dto/PropMetadata";
import { TranslatedPropContentDTO } from "../../dto/TranslatedPropContentDTO";
import { SituationMetadata } from "../../dto/SituationMetadata";
import { RootState } from "../Store";

interface UsedSituation {
    propContent: TranslatedPropContentDTO;
    metadata: SituationMetadata;
}

interface SituationState {
    isInitialized:boolean;
    selectedSituationId: string;
    categories: TranslatedPropCategoryDTO[];
    filter: string;
    filteredCategory: string;
    pagedSituations: TranslatedPropDTO[];
    selectedSituationContent: TranslatedPropContentDTO | null;
    selectedSituationMetadata: PropMetadata | null;
    usedSituations: Record<string, UsedSituation>;
}

const initialSituationState: SituationState = {
    isInitialized:false,
    selectedSituationId: "",
    categories: [],
    filter: "",
    filteredCategory: "",
    pagedSituations: [],
    selectedSituationContent: null,
    selectedSituationMetadata: null,
    usedSituations: {}
};



  // TODO: add addPagedSituations to slice
  // TODO: add initializeSituationLibrary to slice
export const situationSlice = createSlice({
    name: "situations",
    initialState: initialSituationState,
    reducers: {
        initializeSituations: (state, action: PayloadAction<{ totalCount: number, firstPage: TranslatedPropDTO[] }>) => {
            state.isInitialized = true;
            state.pagedSituations = new Array(action.payload.totalCount).fill(null);

            for (let i = 0; i < action.payload.firstPage.length; i++) {
                state.pagedSituations[i] = action.payload.firstPage[i];
            }
        },
        setSelectedSituation: (state, action: PayloadAction<string>) => {
            state.selectedSituationId = action.payload;
        },
        setSelectedSituationContent: (state, action: PayloadAction<{content: TranslatedPropContentDTO, metadata: PropMetadata}>) => {
            state.selectedSituationContent = action.payload.content;
            state.selectedSituationMetadata = action.payload.metadata;
        },
        addPagedSituations: (state, action: PayloadAction<{pageNumber: number; assets: TranslatedPropDTO[] }>) => {
            var si = action.payload.pageNumber * 10;
            if(si > state.pagedSituations.length) return;
            
            for(var i = 0; i < action.payload.assets.length; i++) {
                state.pagedSituations[si + i] = action.payload.assets[i];
            }
        },
    },
});

export const {
    initializeSituations,
    setSelectedSituationContent,
    addPagedSituations,
    setSelectedSituation
} = situationSlice.actions;

export const selectSituations = (state: RootState) => state.situations.pagedSituations;
export const selectSelectedSituationId = (state: RootState) => state.situations.selectedSituationId;
export const selectSituationsInitialized = (state: RootState) => state.situations.isInitialized;
export const selectSelectedSituation = (state: RootState) => state.situations.pagedSituations.find(sit => sit.id === state.situations.selectedSituationId);
export const selectSelectedSituationContent = (state: RootState) => state.situations.selectedSituationContent;
export const selectSelectedSituationMetadata = (state: RootState) => state.situations.selectedSituationMetadata;
export default situationSlice.reducer;

