import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import InspectPropPreview from "../../features/previews/inspect_prop/InspectPropQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import {
  InteractionProperty,
  InteractionPropertyType,
} from "../InteractionProperty";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface IInspectProp extends Interaction {
  propId: string;
  tag: string;
}

const requiredInspectProperties: InteractionProperty[] = [
  {
    id: "instant",
    name: "instant",
    locId: "instant",
    type: InteractionPropertyType.boolean,
    value: false,
  },
];

export const inspectPropInteraction = (): InteractionFactoryEntity => {
  const view = <InspectPropPreview />;
  const filterType = "Inspect Prop";

  const create = (getKeys: (amount: number) => string[]): IInspectProp => {
    return {
      id: GetNewGuid(),
      name: "Inspect Prop",
      type: InteractionType.InspectProp,
      propId: "",
      tag: "",
      properties: requiredInspectProperties,
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): Interaction => {
    if (interaction.properties === undefined)
      return { ...interaction, properties: requiredInspectProperties };

    // drill the props and add any missing ones
    var newProperties: InteractionProperty[] = GetMigratedProperties(
      requiredInspectProperties,
      interaction.properties
    );
    console.log(
      "[InspectProp] migrated " +
        (newProperties.length - interaction.properties.length) +
        " properties"
    );

    return { ...interaction, properties: newProperties };
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[]
  ) => {
    return create(getKeys);
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    currentTake: Take,
    actorPresets: Actor[]
  ) => {
    const converted = interaction as IInspectProp;
    const warnings: string[] = [];

    if (converted.propId === "") warnings.push("Prop is required");
    if (converted.tag === "") warnings.push("Tag is required");

    return warnings;
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {
    const converted = interaction as IInspectProp;
    const searchableStrings: string[] = [interaction.name];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType
    );
  };

  return {
    View: view,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
