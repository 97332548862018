import {
  selectLocalizedLanguages,
  updateLocalizedStringFromExcel,
} from "../../app/slices/TrainingLocalizationSlice";
import { useSelector } from "react-redux";
import { selectTrainingName, setIsDirty } from "../../app/slices/TrainingSlice";
import { selectReferenceLanguage } from "../../app/slices/TrainingSlice";
import { selectTrainingLocalizationState } from "../../app/slices/TrainingLocalizationSlice";
import { useDispatch } from "react-redux";
import { ChangeEvent, useRef } from "react";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";

export default function useTrainingLocalizationExcel() {
  const locState = useSelector(selectTrainingLocalizationState);
  const localizedLanguages = useSelector(selectLocalizedLanguages);
  const referenceLanguage = useSelector(selectReferenceLanguage);
  const projName = useSelector(selectTrainingName);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const supportedFiletypes =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const downloadExcel = (shortcodes: string[]) => {
    const filetype =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const entries = locState.localizedEntries;
    var primaryLanguage = referenceLanguage;

    const json: Object[] = [];
    for (var key in entries) {
      const jsonObject = {
        key: key,
        [primaryLanguage]: entries[key][primaryLanguage].value,
      };

      for (var shortcode of shortcodes) {
        if (shortcode === primaryLanguage) continue;
        jsonObject[shortcode] = entries[key][shortcode].value;
      }

      json.push(jsonObject);
    }

    const exportToExcel = async () => {
      const ws = XLSX.utils.json_to_sheet(json);

      //#region OLD_STYLES
      // const cellStyle = {
      //   font: {
      //     bold: true,
      //     color: { rgb: "FF000000" },
      //   },
      //   fill: {
      //     patternType: "solid",
      //     bgColor: { rgb: "1A3AC4FF" },
      //     fgColor: { rgb: "FFFFFFFF" },
      //   },
      // };

      // const cellStyleUneven = {
      //   font: {
      //     bold: true,
      //     color: { rgb: "FFFFFFFF" },
      //   },
      //   fill: {
      //     patternType: "solid",
      //     bgColor: { rgb: "FF3AC4FF" },
      //     fgColor: { rgb: "FF3AC4FF" },
      //   },
      // };

      // for (var i = 1; i < json.length + 2; i++) {
      //   ws["A" + i].s = i % 2 === 0 ? cellStyle : cellStyleUneven;
      //   ws["B" + i].s = i % 2 === 0 ? cellStyle : cellStyleUneven;
      //   if (ws["C" + i] !== undefined)
      //     ws["C" + i].s = i % 2 === 0 ? cellStyle : cellStyleUneven;
      // }
      //#endregion

      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: filetype });
      FileSaver.saveAs(
        data,
        projName + "_" + shortcodes.join("_") + fileExtension
      );
    };

    exportToExcel();
  };

  const handleExcelInput = function (e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files?.length === 1) {
      //handleFiles(e.target.files);
      handleExcel(e.target.files);
    }
  };

  const handleExcel = function (files: FileList) {
    var file = files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      if (e.target) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetname = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetname];

        // Convert the sheet to an array rows
        const sheetData: any[][] = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
        });

        // Get the first row (the headers)
        const headers: any[] = sheetData[0];

        // check first row for shortcodes
        const shortcodes = headers.slice(1);

        for (var shortcode of shortcodes) {
          // if shortcode is not of a language that is localized in the training, continue
          if (!localizedLanguages.some((loc) => loc.shortcode === shortcode)) {
            continue;
          }
          const columnIndex: number = headers.findIndex((header) => {
            return header === shortcode;
          });

          // read the data
          const parsedData: any[][] = sheetData.map((row) => [
            row[0],
            row[columnIndex],
          ]);
          // TODO: check if shortcode is in the training

          parseExcel(shortcode, parsedData);
        }
      }
    };
  };

  const parseExcel = (shortcode: string, parsedData: any[][]) => {
    for (var i = 0; i < parsedData.length; ++i) {
      var record: any = parsedData[i];
      // update record in slice
      // key, language, value
      dispatch(
        updateLocalizedStringFromExcel({
          key: record[0],
          shortcode: shortcode,
          value: record[1],
        })
      );
    }

    dispatch(setIsDirty());
  };

  return {
    downloadExcel,
    handleExcelInput,
    inputRef,
    supportedFiletypes,
  };
}
