import usePropHelper from "../../../hooks/PropHelper";
import { Prop } from "../../../models/Prop";
import { PiInteractionDropdown } from "../../../components/PiInteractionDropdown";
import { useMemo } from "react";
import useAttachmentHelper from "../../../hooks/AttachmentHelper.hook";

export default function AttachPropDropdown(props: {
  onChange: (prop: Prop) => void;
  selectedPropId: string;
}) {
  const { onChange, selectedPropId } = { ...props };
  const { getPropPresetByInstanceId } = usePropHelper();

  const { getAllAttachableProps } = useAttachmentHelper();

  const selectedPropName = useMemo(() => {
    const p = getPropPresetByInstanceId(selectedPropId);
    return p ? p.name : "No prop selected";
  }, [selectedPropId]);

  const dropdownItems = getAllAttachableProps().map((prop) => {
    return (
      <PiInteractionDropdown.Item key={prop.id} onClick={() => onChange(prop)}>
        <div className={"flex justify-center gap-4 w-full"}>
          <div className={"my-auto truncate"}>{prop.name}</div>
        </div>
      </PiInteractionDropdown.Item>
    );
  });

  const dropdown = (
    <PiInteractionDropdown label={"Prop"}>
      <PiInteractionDropdown.Trigger>
        <div className={"flex gap-x-4 w-full"}>
          <div className={"my-auto truncate"}>{selectedPropName}</div>
        </div>
      </PiInteractionDropdown.Trigger>
      {dropdownItems}
    </PiInteractionDropdown>
  );

  return <>{dropdown}</>;
}
