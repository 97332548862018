import { useMemo } from "react";
import { LanguageData } from "../../app/slices/TrainingLocalizationSlice";
import TrainingLocalizationTableCell from "./TrainingLocalizationTableCell";

export default function TrainingLocalizationTableBody(props: {
  languages: LanguageData[];
  checkedLangs: string[];
  checkLanguage: (shortcode: string, checked: boolean) => void;
  filter: string;
}) {
  const { languages, checkedLangs, checkLanguage, filter } = props;

  const cellsMapped = useMemo(() => {
    return languages
      .filter(
        (lang) =>
          lang.shortcode.toLowerCase().includes(filter.toLowerCase()) ||
          lang.full.toLowerCase().includes(filter.toLowerCase())
      )

      .map((lang: LanguageData) => {
        return (
          <TrainingLocalizationTableCell
            key={lang.shortcode}
            languageData={lang}
            checked={checkedLangs.includes(lang.shortcode)}
            onToggleChecked={(checked: boolean, lang: LanguageData) => {
              checkLanguage(lang.shortcode, checked);
            }}
          />
        );
      });
  }, [languages, checkedLangs, filter]);

  return (
    <div className="w-full h-full overflow-y-auto pit-creator-scrollbar">
      {cellsMapped}
    </div>
  );
}
