import { useSelector } from "react-redux";
import { TakePosition } from "../../app/slices/EnvironmentSlice";
import {
  selectSelectedTake3D,
  selectTakes3D,
  updateSelectedTake,
} from "../../app/slices/TrainingSlice";
import { useDispatch } from "react-redux";
import Take3D from "../../models/Take3D";
import useSituationHelper from "../../hooks/SituationHelper.hook";

export default function useViewportTakeLocationLayer() {
  const dispatch = useDispatch();
  const currentTake = useSelector(selectSelectedTake3D);
  const allTakes = useSelector(selectTakes3D);
  const { getSituationMetadata } = useSituationHelper();

  const onAssignTakeToLocation = (interactionPoint: TakePosition) => {
    if (!currentTake) return;

    var camId: string = interactionPoint.camera_positions[0].id;

    // if take location is a situation, select a camera position from the situation
    if (currentTake.situations[interactionPoint.id]) {
      const metadata = getSituationMetadata(
        currentTake.situations[interactionPoint.id]
      );
      if (metadata)
        camId =
          currentTake.situations[interactionPoint.id] +
          "/" +
          metadata?.camera_positions[0].id;
    }

    //Check wether there is a previous take, and whether is has the same take position
    // use default cam pos if conditions are not met
    camId =
      allTakes.length > 1 &&
      allTakes[allTakes.length - 2].interactionPointId === interactionPoint.id
        ? allTakes[allTakes.length - 2].mainCamera.cameraId
        : camId;

    const cpy: Take3D = {
      ...currentTake,
      interactionPointId: interactionPoint.id,
      name: interactionPoint.name,
      isExpanded: true,
      mainCamera: { ...currentTake.mainCamera, cameraId: camId },
    };

    dispatch(updateSelectedTake(cpy));
  };

  return { onAssignTakeToLocation } as const;
}
