import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  LanguageData,
  selectLocalizedLanguages,
} from "../../app/slices/TrainingLocalizationSlice";
import {
  selectReferenceLanguage,
  setReferenceLanguage,
} from "../../app/slices/TrainingSlice";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import PiProgressbar from "../../components/PiProgressbar";
import PiTag from "../../components/PiTag";
import PiMenuButton from "../menu/PiMenuButton";
import useAutotranslation from "./AutoTranslationHook";
import "./TrainingLocalizationTable.css";
import { useTrainingLocalizationTable } from "./TrainingLocalizationTableHooks";
import TranslationprogressbarTooltip from "./TranslationProgressbarTooltip";

import PiInputfield from "../../components/PiInputField";
import TrainingLocalizationTableHeader from "./TrainingLocalizationTableHeader";
import TrainingLocalizationTableCell from "./TrainingLocalizationTableCell";
import { useEffect, useMemo, useState } from "react";
import TrainingLocalizationTableBody from "./TrainingLocalizationTableBody";
import TrainingLocalizationDialogHeader from "./TrainingLocalizationDialogHeader";
import useTrainingLocalizationExcel from "./TrainingLocalizationExcel.hook";

export default function TrainingLocalizationTable(props: {
  setIsAddLanguageOpen: (open: boolean) => void;
}) {
  const { setIsAddLanguageOpen } = props;
  const dispatch = useDispatch();
  const currentLanguages = useSelector(selectLocalizedLanguages);
  const { t } = useTranslation();
  const {
    checkedLangs,
    selectAllLanguages,
    checkLanguage,
    getCheckLanguagesData,
    filter,
    setFilter,
  } = useTrainingLocalizationTable();

  const { inputRef, handleExcelInput, supportedFiletypes, downloadExcel } =
    useTrainingLocalizationExcel();

  const { translateMultipleMissing, translateMultipleAll } =
    useAutotranslation();

  //#region OLD
  // const tableHead = (
  //   <TableHead>
  //     <TableRow>
  //       <TableCell>
  //         <span className="traininglocalizationtable-tablehead">
  //           {t("version")}
  //         </span>
  //       </TableCell>
  //       <TableCell>
  //         <span></span>
  //       </TableCell>
  //       <TableCell>
  //         <span></span>
  //       </TableCell>
  //       <TableCell>
  //         <span className="traininglocalizationtable-tablehead">
  //           {t("language")}
  //         </span>
  //       </TableCell>
  //       <TableCell>
  //         <span className="traininglocalizationtable-tablehead">
  //           {t("translated")}
  //         </span>
  //       </TableCell>
  //       <TableCell>
  //         <span className="traininglocalizationtable-tablehead"></span>
  //       </TableCell>
  //     </TableRow>
  //   </TableHead>
  // );

  // const tableRows = currentLanguages.map(
  //   (lang: LanguageData, index: number) => {
  //     return (
  //       <TableRow hover key={index} className="traininglocalization-table-row">
  //         <TableCell>
  //           <span className="traininglocalizationtable-text">1.0</span>
  //         </TableCell>
  //         <TableCell>
  //           {refLanguage === lang.shortcode && (
  //             <PiTag text="REF" variant="green" />
  //           )}
  //         </TableCell>
  //         <TableCell>
  //           <PiTag text={lang.shortcode} variant="blue" />
  //         </TableCell>
  //         <TableCell>
  //           <span className="traininglocalizationtable-text">{lang.full}</span>
  //         </TableCell>

  //         <Tooltip
  //           title={
  //             <React.Fragment>
  //               <TranslationprogressbarTooltip
  //                 manuallyTranslated={getPercentageTranslated(lang.shortcode)}
  //                 autoTranslated={getPercentageAutoTranslated(lang.shortcode)}
  //                 notTranslated={getPercentageNotTranslated(lang.shortcode)}
  //               />
  //             </React.Fragment>
  //           }
  //           placement="top"
  //           arrow
  //           followCursor
  //         >
  //           <TableCell>
  //             <PiProgressbar
  //               progressBlue={getPercentageTranslated(lang.shortcode)}
  //               progressYellow={getPercentageAutoTranslated(lang.shortcode)}
  //             />
  //           </TableCell>
  //         </Tooltip>

  //         <TableCell>
  //           <PiPopoverMenu>
  //             <PiMenuButton
  //               text="Translate All"
  //               onClick={() => translateAll(lang)}
  //               label={"AI"}
  //               disabled={lang.shortcode === refLanguage}
  //             />
  //             <PiMenuButton
  //               text="Translate Missing"
  //               onClick={() => translateMissing(lang)}
  //               label={"AI"}
  //               disabled={lang.shortcode === refLanguage}
  //             />
  //             <PiMenuButton
  //               text={t("localization_menu.set_as_ref")}
  //               onClick={() => {
  //                 dispatch(setReferenceLanguage(lang.shortcode));
  //               }}
  //             />
  //             {/* <PiMenuButton text={t("localization_menu.translate_missing")} onClick={() => {}} />
  //           <PiMenuButton text={t("localization_menu.translate_all")} onClick={() => {}} /> */}

  //             <PiMenuButton
  //               text={t("localization_menu.download")}
  //               onClick={() => downloadExcel(lang.shortcode)}
  //             />
  //             <PiMenuButton
  //               text={t("localization_menu.upload")}
  //               onClick={() => {
  //                 setExpectedLanguageFromExcel(lang.shortcode);
  //                 inputRef.current?.click();
  //               }}
  //             />
  //             <PiMenuButton
  //               text={t("localization_menu.delete")}
  //               disabled
  //               onClick={() => removeLocalizedLanguage(lang)}
  //             />
  //           </PiPopoverMenu>
  //         </TableCell>
  //       </TableRow>
  //     );
  //   }
  // );
  //#endregion

  useEffect(() => {
    console.log("checkedLangs", checkedLangs.length);
  }, [checkedLangs]);

  return (
    <>
      <TrainingLocalizationDialogHeader
        hasCheckedLanguages={checkedLangs.length > 0}
        onAddLanguageClicked={() => setIsAddLanguageOpen(true)}
        onTranslateAllClicked={() =>
          translateMultipleAll(getCheckLanguagesData())
        }
        onTranslateMissingClicked={() => {
          translateMultipleMissing(getCheckLanguagesData());
        }}
        onDownloadExcelClicked={() => downloadExcel(checkedLangs)}
        onUploadExcelClicked={() => {
          inputRef.current?.click();
        }}
        setFilter={setFilter}
      />
      <div
        key={checkedLangs.join(",")}
        className="relative border-solid border-2 border-creator-border rounded-[5px] max-h-full h-[95%] flex flex-col"
      >
        <TrainingLocalizationTableHeader
          onToggleAll={selectAllLanguages}
          checkedLangs={checkedLangs}
        />
        <TrainingLocalizationTableBody
          languages={currentLanguages}
          checkedLangs={checkedLangs}
          checkLanguage={checkLanguage}
          filter={filter}
        />

        <input
          className="traininglocalizationtable-hide-input"
          ref={inputRef}
          type="file"
          onChange={handleExcelInput}
          accept={supportedFiletypes}
        />
        {/* <div className="traininglocalizationtable-onboarding">
          <OnboardingButton context="localization" position="absolute" />
        </div>
        <AutoOnboarding context="localization" /> */}
      </div>
    </>
  );
}
