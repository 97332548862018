import { SearchResultData } from "../../app/slices/SearchResultsSlice";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import GrabPreview from "../../features/previews/grab/GrabPreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import Interaction, { InteractionType } from "./Interaction";

export interface IGrab extends Interaction {
  playerGuid: string;
  propGuid: string;
  tag: string;
}

export const grabInteraction = (): InteractionFactoryEntity => {
  const component = <GrabPreview />;

  const create = (getKeys: (amount: number) => string[]): IGrab => {
    return {
      id: GetNewGuid(),
      name: "Grab",
      type: InteractionType.Grab,
      playerGuid: "",
      propGuid: "",
      tag: "",
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IGrab => {
    return interaction as IGrab;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void
  ): IGrab => {
    return create(getKeys);
  };

  const filter = (
    interaction: Interaction,
    takeName: string,
    getMatchingResults: (
      searchableStrings: string[],
      takeName: string,
      interaction: Interaction,
      filterType: string
    ) => SearchResultData[]
  ): SearchResultData[] => {

    const searchableStrings: string[] = [interaction.name];

    return getMatchingResults(
      searchableStrings,
      takeName,
      interaction,
      filterType,
    );
  };

  const filterType: string = "Grab";

  return {
    View: component,
    FilterType: filterType,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Filter: filter,
  };
};
