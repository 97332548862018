import { PiInteractionDropdown } from "../../../components/PiInteractionDropdown";
import usePropHelper from "../../../hooks/PropHelper";

export default function AttachTagDropdown(props: {
  onChange: (tag: string) => void;
  selectedTag: string;
  selectedProp: string;
}) {
  const { selectedProp, selectedTag, onChange } = { ...props };
  const { getUsedPropByInstanceId } = usePropHelper();

  const myProp = selectedProp
    ? getUsedPropByInstanceId(selectedProp)
    : undefined;

  const getDropdownItems = () => {
    if (!selectedProp || !myProp) return [] as JSX.Element[];

    return myProp?.metadata.attachmentTags.map((tag) => {
      return (
        <PiInteractionDropdown.Item
          key={tag.tag}
          onClick={() => onChange(tag.tag)}
        >
          <div className={"flex justify-center gap-4 w-full"}>
            <div className={"my-auto truncate"}>{tag.tag}</div>
          </div>
        </PiInteractionDropdown.Item>
      );
    });
  };

  const dropdown = (
    <PiInteractionDropdown label={"Tag"}>
      <PiInteractionDropdown.Trigger>
        <div className={"flex gap-x-4 w-full"}>
          <div className={"my-auto truncate"}>{selectedTag}</div>
        </div>
      </PiInteractionDropdown.Trigger>
      {getDropdownItems()}
    </PiInteractionDropdown>
  );
  return <>{dropdown}</>;
}
