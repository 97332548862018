import usePropHelper from "../../../hooks/PropHelper";
import { PiInteractionDropdown } from "../../../components/PiInteractionDropdown";
import LinkIcon from "@mui/icons-material/Link";
import ActorAvatar from "../../../components/actors/ActorAvatar";
import { useSelector } from "react-redux";
import { selectActors } from "../../../app/slices/TrainingSlice";
import useDetachDropdown from "./DetachDropdown.hook";
import { useMemo } from "react";

export default function DetachDropdown(props: {
  onChange: (propGuid: string, actorId: string) => void;
  selectedPropGuid: string;
}) {
  const { onChange, selectedPropGuid } = { ...props };

  const { getAllAttaches, getSelectedAttachment } = useDetachDropdown();
  const { getPropPresetByInstanceId } = usePropHelper();

  const allActors = useSelector(selectActors);

  const selectedElement = () => {
    if (selectedPropGuid === "") return <div></div>;
    const { preset, actor } = getSelectedAttachment();

    return (
      <div className={"flex gap-[8px]"}>
        <ActorAvatar actor={actor} />
        <div className={"my-auto"}>{actor?.workingName}</div>
        <div className={"px-[10px] my-auto"}>
          <LinkIcon />
        </div>
        <div className={"my-auto"}>{preset!.name}</div>
      </div>
    );
  };

  const dropdownItems = useMemo(() => {
    const attaches = getAllAttaches();

    return attaches.map((attach) => {
      const { propGuid, actorId } = attach;

      const preset = getPropPresetByInstanceId(attach.propGuid);
      const actor = allActors.find((actor) => actor.id === attach.actorId);

      return (
        <PiInteractionDropdown.Item
          key={propGuid}
          onClick={() => onChange(propGuid, actorId)}
        >
          <div className={"flex gap-[8px]"}>
            <ActorAvatar actor={actor} />
            <div className={"my-auto"}>{actor?.workingName}</div>
            <div className={"px-[10px] my-auto"}>
              <LinkIcon />
            </div>
            <div className={"my-auto"}>{preset!.name}</div>
          </div>
        </PiInteractionDropdown.Item>
      );
    });
  }, [getAllAttaches]);

  const dropdown = (
    <PiInteractionDropdown label={"Attachment"}>
      <PiInteractionDropdown.Trigger>
        <div>{selectedElement()}</div>
      </PiInteractionDropdown.Trigger>
      {dropdownItems}
    </PiInteractionDropdown>
  );

  return <>{dropdown}</>;
}
