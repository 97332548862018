import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedElementId,
  setSelectedInteraction,
  updateSelectedInteraction,
} from "../../app/slices/TrainingSlice";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import { useDeleteInteraction } from "../../hooks/DeleteInteractionHook";
import Interaction from "../../models/interactions/Interaction";
import { AllInteractions } from "../interaction_picker/InteractionDefinitions";
import { useInteractionPickerIcons } from "../interaction_picker/InteractionPickerIconHooks";
import PiMenuButton from "../menu/PiMenuButton";
import "./InteractionCard.css";
import classNames from "classnames";
import InfoIcon from "@mui/icons-material/Info";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { selectWarnings } from "../../app/slices/ValidationSlice";
import useValidation from "../validation/Validation.hooks";
import useErrorValidation from "../validation/ErrorValidation.hook";
import ErrorIcon from "@mui/icons-material/Error";
interface InteractionCardProps {
  interaction: Interaction;
  locId?: string;
  ghost?: boolean;
}

export default function InteractionCard(props: InteractionCardProps) {
  const { interaction, locId, ghost } = { ...props };
  const selectedElement = useSelector(selectSelectedElementId);
  const dispatch = useDispatch();
  const [getIcon] = useInteractionPickerIcons();
  const [deleteInteraction] = useDeleteInteraction();
  const { t } = useTranslation();
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const warnings = useSelector(selectWarnings);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: interaction.id,
      transition: {
        duration: 150, // milliseconds
        easing: "cubic-bezier(0.25, 1, 0.5, 1)",
      },
    });
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
  };

  const { getWarningsForInteraction } = useValidation();
  const { getErrorsForInteraction } = useErrorValidation();

  const myWarning = useMemo(() => {
    return getWarningsForInteraction(interaction.id);
  }, [interaction, getWarningsForInteraction]);

  const myErrors = useMemo(() => {
    return getErrorsForInteraction(interaction.id);
  }, [interaction, getErrorsForInteraction]);

  useEffect(() => {
    setIsRenaming(false);
  }, [selectedElement]);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const onClick = () => {
    dispatch(setSelectedInteraction(interaction.id));
  };

  const onDeleteInteraction = () => {
    // TODO: delete interaction keys
    deleteInteraction(interaction);
    //dispatch(deleteInteraction(interaction));
  };

  const startRenameInteraction = async () => {
    setIsRenaming(true);
    await delay(100);
    inputRef.current?.focus();
  };

  const onChangeName = (value: string) => {
    const interactionCopy = { ...interaction };
    interactionCopy.name = value;
    dispatch(updateSelectedInteraction(interactionCopy));
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      setIsRenaming(false);
    }
  };

  const getRootclassname = () => {
    if (selectedElement === interaction.id) {
      return "interactioncard-root interactioncard-selected";
    }

    return "interactioncard-root";
  };

  const localizedName = locId ? t(locId) : "";

  const interactiondef = AllInteractions.find(
    (def) => def.type === interaction.type
  );
  const icon = interactiondef ? (
    <div
      className={classNames({
        "flex my-auto text-[22px]": true,
        "text-creator-primary": selectedElement === interaction.id,
      })}
    >
      {getIcon(interactiondef)}
    </div>
  ) : (
    <MessageOutlinedIcon
      className={"my-auto"}
      fontSize="inherit"
      color="inherit"
    />
  );
  const name = (
    <span
      className={classNames({
        "my-auto w-full text-[14px] truncate": true,
        "font-bold text-creator-primary": selectedElement === interaction.id,
        "font-medium text-creator-text": selectedElement !== interaction.id,
        "text-creator-error": myErrors?.length > 0,
      })}
    >
      {localizedName ? localizedName : interaction.name}
    </span>
  );

  const inputField = (
    <input
      className="interactioncard-input interactioncard-name"
      ref={inputRef}
      value={localizedName ? localizedName : interaction.name}
      onBlur={() => setIsRenaming(false)}
      onChange={(e) => onChangeName(e.target.value)}
      onKeyDown={onKeyDown}
    />
  );

  const menu = (
    <div className={"my-auto"}>
      <PiPopoverMenu>
        <PiMenuButton
          text={t("rename")}
          onClick={startRenameInteraction}
        ></PiMenuButton>
        <PiMenuButton
          text={t("delete")}
          onClick={onDeleteInteraction}
        ></PiMenuButton>
      </PiPopoverMenu>
    </div>
  );

  const warningIcon = (
    <div className={"my-auto text-[12px] text-warning-600"}>
      <InfoIcon fontSize={"inherit"} />
    </div>
  );

  const errorIcon = (
    <div className={"my-auto text-[12px] text-creator-error"}>
      <ErrorIcon fontSize={"inherit"} />
    </div>
  );

  return (
    <div
      className={classNames({
        "flex h-[48px] pl-[24px] pr-[10px] gap-[10px] hover:bg-creator-primary-washed":
          true,
        "bg-creator-childActive": selectedElement === interaction.id,
        "opacity-75": ghost,
      })}
      onClick={onClick}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
    >
      {icon}
      {myWarning?.length > 0 && warningIcon}
      {myErrors?.length > 0 && errorIcon}
      {!isRenaming && name}
      {isRenaming && inputField}
      {menu}
    </div>
  );
}
