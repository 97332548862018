import {
  Actor,
  Gender,
  GenderNeutralNames,
  ProfilePictures,
} from "../../models/Actor";
import { GetNewGuid } from "../../helpers/GuidHelper";
export class ActorHelper {
  static GetNewRandomActor(): Actor {
    const name =
      GenderNeutralNames[Math.floor(Math.random() * GenderNeutralNames.length)];

    return {
      id: GetNewGuid(),
      workingName: name,
      profilePicture: Math.floor(Math.random() * ProfilePictures.length),
      gender: Gender.random,
    };
  }

  static GetActorProfilePicture(profilePicture: string, allActors: Actor[]) {
    console.log(profilePicture);
    if (profilePicture === "player") {
      const playerIcon = (
        <div className="flex items-center justify-center font-medium bg-creator-primary w-9 h-9 text-interaction-text-inverted text-16 rounded-circle">
          P
        </div>
      );

      return playerIcon;
    } else {
      const npcIcon = (
        <img
          className="flex flex-col items-center object-contain border-2 min-w-9 min-h-9 max-w-9 max-h-9 rounded-circle border-creator-primary"
          src={
            ProfilePictures[
              allActors.find((a) => a.id === profilePicture)?.profilePicture ??
                0
            ]
          }
          alt="icon"
        />
      );

      return npcIcon;
    }
  }
}
