export enum PropType {
  Default,
  Situation,
  Environment,
}

export interface Prop {
  id: string; // this is the instance id
  propId: string; // id of the prop (backend)
  name: string; // visual name
  prefabName: string;
  propType: PropType;
  link?: { parentId: string; positionId: string }; // the situation this prop is linked to + on which position the prop is located
}
