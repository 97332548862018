import classNames from "classnames";
import ErrorIcon from "@mui/icons-material/Error";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
export function ErrorToggleButton(props: {
  onClick: () => void;
  count: number;
  enabled: boolean;
}) {
  const { onClick, count, enabled } = { ...props };

  return (
    <div
      className={classNames({
        "border-2 border-solid border-creator-error rounded-[5px] p-[4px] flex justify-center items-center gap-[4px] text-[12px] cursor-pointer hover:opacity-75 select-none":
          true,
        "text-white bg-creator-error": enabled,
        "text-creator-error bg-white": !enabled,
      })}
      onClick={onClick}
    >
      <div className="text-[20px] flex items-center justify-center">
        <ErrorIcon fontSize="inherit" color="inherit" />
      </div>
      <div>
        {count} {count !== 1 ? "Errors" : "Error"}
      </div>
    </div>
  );
}

export function WarningToggleButton(props: {
  onClick: () => void;
  count: number;
  enabled: boolean;
}) {
  const { onClick, count, enabled } = { ...props };

  return (
    <div
      className={classNames({
        "border-2 border-solid border-warning-600 rounded-[5px] p-[4px] flex justify-center items-center gap-[4px] text-[12px] cursor-pointer hover:opacity-75 select-none":
          true,
        "text-white bg-warning-600": enabled,
        "text-warning-600 bg-white": !enabled,
      })}
      onClick={onClick}
    >
      <div className="text-[20px] flex items-center justify-center">
        <ReportProblemIcon fontSize="inherit" color="inherit" />
      </div>
      <div>
        {count} {count !== 1 ? "Warnings" : "Warning"}
      </div>
    </div>
  );
}
