import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { selectEnvironmentInteractionPoints } from "../app/slices/EnvironmentSlice";
import {
  selectActors,
  selectSelectedTake3D,
  updateSelectedTake,
} from "../app/slices/TrainingSlice";
import { Actor } from "../models/Actor";
import { ActorMovementType } from "../models/ActorMovementType";
import { actorActivities, actorProps, actorStates } from "../models/ActorState";
import Take3D from "../models/Take3D";
import { selectProps } from "../app/slices/PropLibrarySlice";
import { PropTakeState } from "../models/PropTakeState";

export const useHelper3D = () => {
  const points = useSelector(selectEnvironmentInteractionPoints); // points
  const allActors = useSelector(selectActors); // all actors
  const selectedTake = useSelector(selectSelectedTake3D); // selected take
  const allPropDtos = useSelector(selectProps); // all props
  const dispatch = useDispatch();

  const getInvolvedActors = (take: Take3D) => {
    if (take.actors.length === 0) return [];

    const involvedPositions = take.interactionPointId
      ? points.find((position) => position.id === take.interactionPointId)
          ?.actor_positions
      : [];

    var involvedActors: Actor[] = [];

    for (var i = 0; i < take.actors.length; i++) {
      const actor = take.actors[i];
      if (involvedPositions?.find((pos) => pos.id === actor.positionId)) {
        if (allActors.find((aa) => aa.id === actor.actorId)) {
          involvedActors.push(allActors.find((aa) => aa.id === actor.actorId)!);
        }
      }
    }
    return involvedActors;
  };

  const getInvolvedProps = (take: Take3D) => {
    const involvedPositions = take.interactionPointId
      ? points.find((position) => position.id === take.interactionPointId)
          ?.prop_positions
      : [];

    var involvedProps: PropTakeState[] = [];

    for (var i = 0; i < take.props.length; i++) {
      const prop = take.props[i];
      if (involvedPositions?.find((pos) => pos.id === prop.positionId)) {
        involvedProps.push(prop);
      }
    }

    return involvedProps;
  };

  const getActorAtPositionIdInSelectedTake = (positionId: string) => {
    if (selectedTake) {
      let id = "";

      for (let i = 0; i < selectedTake.actors.length; i++) {
        if (selectedTake.actors[i].positionId === positionId) {
          id = selectedTake.actors[i].actorId;
          break;
        }
      }

      return allActors.find((a) => a.id === id);
    }
  };

  const addActorToSelectedTake = (
    take: Take3D | undefined,
    actorId: string,
    positionId: string,
    actorToRemove?: string
  ) => {
    if (!take) return;
    var actors = [...take.actors];
    const foundIndex = actors.findIndex((actor) => actor.actorId === actorId);

    // already in array
    if (foundIndex !== -1) {
      actors[foundIndex] = { ...actors[foundIndex], positionId: positionId };
    }
    // add to array
    else {
      actors.push({
        id: uuidv4(),
        actorId: actorId,
        positionId: positionId,
        movement: ActorMovementType.Instant,
        lookat: "",
        outfit: "",
        activity: actorActivities[0],
        state: actorStates[0],
        prop: actorProps[0],
      });
    }

    if (actorToRemove) {
      actors = actors.filter((a) => a.actorId !== actorToRemove);
    }

    dispatch(updateSelectedTake({ ...take, actors: actors } as Take3D));
  };

  const removeActorFromSelectedTake = (
    take: Take3D | undefined,
    actorId: string
  ) => {
    if (!take) return;
    const attachmentsCopy = { ...take.attachments };
    const filteredActors = take.actors.filter(
      (actor) => actor.actorId !== actorId
    );

    // TODO: remove attachment if it is attached to the actor
    for (const [key, value] of Object.entries(attachmentsCopy)) {
      if (value.id === actorId) delete attachmentsCopy[key];
    }

    dispatch(
      updateSelectedTake({
        ...take,
        actors: filteredActors,
        attachments: attachmentsCopy,
      } as Take3D)
    );
  };

  return {
    getInvolvedActors,
    getActorAtPositionIdInSelectedTake,
    addActorToSelectedTake,
    removeActorFromSelectedTake,
    getInvolvedProps,
  } as const;
};
