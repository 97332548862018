import classNames from "classnames";

export default function PiButton(props: {
  children: JSX.Element[] | JSX.Element | string;
  variant: "piBlue" | "piRed" | "piBlueOutlined";
  onClick: () => void;
  disabled?: boolean;
}) {
  const { variant, children, onClick, disabled } = { ...props };

  return (
    <div
      className={classNames({
        "w-full flex rounded-full h-full ": true,
        "bg-creator-error hover:bg-creator-error-hover cursor-pointer text-white":
          variant === "piRed" && !disabled,
        "bg-creator-primary hover:bg-creator-primary-hover cursor-pointer text-white":
          variant === "piBlue" && !disabled,
        "bg-white border-2 border-solid border-creator-primary text-creator-primary box-border hover:bg-interaction-text-hoverBackground cursor-pointer":
          variant === "piBlueOutlined" && !disabled,
        "bg-creator-primary-disabled cursor-not-allowed text-white":
          disabled && variant === "piBlue",
        "bg-white border-interaction-disabled border-2 cursor-not-allowed text-interaction-disabled":
          disabled && variant === "piBlueOutlined",
      })}
      onClick={!disabled ? onClick : undefined}
    >
      <div className="w-full mx-auto my-auto font-medium text-center font">
        {children}
      </div>
    </div>
  );
}
