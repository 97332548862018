import { useDispatch, useSelector } from "react-redux";
import { selectActors } from "../../app/slices/TrainingSlice";
import { Actor } from "../../models/Actor";
import { NpcPopoverButton } from "./NpcPopover";
import classNames from "classnames";
import { useMemo } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import PiSeperator from "../previews/components/PiSeperator";

export default function NpcPointPreviewPopover(props: {
  selectedActor: Actor | undefined;
  onActorClick: (actor: Actor) => void;
  onDelete: () => void;
  onAddNewActor: () => void;
}) {
  const dispatch = useDispatch();
  const { selectedActor, onActorClick, onDelete, onAddNewActor } = { ...props };
  const actors = useSelector(selectActors);
  const canDelete = useMemo(() => selectedActor !== undefined, [selectedActor]);

  const options = actors.map((actor) => {
    return (
      <NpcPopoverButton
        key={actor.id}
        actor={actor}
        onClick={() => onActorClick(actor)}
        checked={actor.id === selectedActor?.id}
      />
    );
  });

  const deleteButton = (
    <div className="h-[42px]">
      <div
        className={classNames({
          "h-full flex gap-[10px] items-center text-[14px] rounded-[5px] cursor-pointer px-[18px]":
            true,
          "text-interaction-disabled": !canDelete,
          "text-interaction-secondary hover:bg-interaction-text-hoverBackground":
            canDelete,
        })}
        onClick={onDelete}
      >
        <DeleteOutlineOutlinedIcon className={"text-[24px]"} color="inherit" />
        <span className="text-[14px] font-medium">Delete</span>
      </div>
    </div>
  );

  const addButton = (
    <div
      className="h-[42px]"
      onClick={() => {
        onAddNewActor();
      }}
    >
      <div className="h-full flex gap-[10px] items-center text-[14px] rounded-[5px] cursor-pointer px-[18px] hover:bg-interaction-text-hoverBackground text-creator-primary">
        <AddIcon className="text-[24px]" color="inherit" />
        <div className="text-[14px] text-creator-primary font-medium">
          Add New Actor
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-[4px]">
      {options}
      <PiSeperator />
      {addButton}
      <PiSeperator />
      {deleteButton}
    </div>
  );
}
