import { DynamicInteraction } from "../../dto/PropMetadata";
import DynamicInteractionPreview from "../../features/dynamic_interactions/DynamicInteractionPreview";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import { Prop } from "../Prop";
import Take from "../Take";
import Take3D from "../Take3D";
import Interaction, { InteractionType } from "./Interaction";

export interface IDynamicInteraction extends Interaction {
  dynamicInteractionProperties: IDynamicInteractionProperty[];
  interactionTag: string;
  source: string;
}

export enum DynamicInteractionPropertyType {
  actorDropdown = "actor_dropdown",
  stringDropdown = "string_dropdown",
  localizedText = "localized_text",
}

export interface IDynamicInteractionProperty {
  id: string;
  name: string;
  description: string;
  type: DynamicInteractionPropertyType;
  requiredTag?: string; // eg. "extinguisher", when interaction requires npc holding extinguisher
  value: string;
  values?: string[]; // for dropdowns !
}

export function CreateDynamicInteraction(
  dynamicInteraction: DynamicInteraction,
  sourceInstanceId: string,
  getArrayOfKeys: (amount: number) => string[]
): IDynamicInteraction {
  console.log(dynamicInteraction);

  // request keys for localized text
  const amountOfKeysNeeded = dynamicInteraction.properties.filter(
    (d) => d.type === DynamicInteractionPropertyType.localizedText
  ).length;
  const keys = getArrayOfKeys(amountOfKeysNeeded);
  let counter = 0;

  // generate properties
  const dynProps: IDynamicInteractionProperty[] =
    dynamicInteraction.properties.map((prop) => {
      let result = {
        ...prop,
        value: prop.value || "",
      } as IDynamicInteractionProperty;

      if (result.type === DynamicInteractionPropertyType.localizedText) {
        result.value = keys[counter];
        counter += 1;
      }
      return result;
    });

  console.log("DYNAMIC PROPS");
  console.log(dynProps);

  return {
    id: GetNewGuid(),
    name: dynamicInteraction.title,
    type: InteractionType.DynamicInteraction,
    interactionTag: dynamicInteraction.interactionTag,
    source: sourceInstanceId,
    dynamicInteractionProperties: dynProps,
    properties: [],
  };
}

export const dynamicInteraction = (): InteractionFactoryEntity => {
  const view = <DynamicInteractionPreview />;
  const create = (
    getKeys: (amount: number) => string[]
  ): IDynamicInteraction => {
    throw new Error("Not implemented (on purpose!)");
  };
  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IDynamicInteraction => {
    return interaction as IDynamicInteraction;
  };

  const validateErrors = (
    interaction: Interaction,
    parentTake: Take,
    currentTake: Take,
    actorPresets: Actor[],
    allPropPresets: Prop[]
  ) => {
    const errors: string[] = [];
    const casted = interaction as IDynamicInteraction;
    const castedTake = parentTake as Take3D;

    const isAttached = ValidationHelper.ValidatIsPropAttached(
      parentTake as Take3D,
      interaction,
      casted.source
    );

    // check if prop is child or not
    let isChild: boolean = false;
    const sourceProp = allPropPresets.find((prop) => prop.id === casted.source);
    if (sourceProp && sourceProp.link) isChild = true;

    // check if source actually exists in the current take
    if (!isChild) {
      const hasProp = castedTake.props.some(
        (prop) => prop.instanceId === casted.source
      );
      if (!hasProp && !isAttached) {
        errors.push("Prop is not added in the scene");
      }
    } else {
      const parentProp = allPropPresets.find(
        (prop) => prop.id === sourceProp!.link?.parentId
      );
      if (
        parentProp &&
        !Object.values(castedTake.situations).some(
          (value) => value === parentProp?.id
        )
      ) {
        errors.push("Parent situation is not spawned");
      }
    }

    // check all properties that are an actor dropdown
    const actorDds = casted.dynamicInteractionProperties.filter(
      (prop) => prop.type === DynamicInteractionPropertyType.actorDropdown
    );

    // check if the actors are present
    actorDds.forEach((prop) => {
      if (
        prop.value &&
        !castedTake.actors.some((actor) => actor.actorId === prop.value)
      ) {
        errors.push("Actor not present in the take");
      }
    });

    // TODO: check if source is prop that is in situation that is spawned !!

    return errors;
  };

  const filterType = "Dynamic Interaction";

  return {
    View: view,
    Create: create,
    Migrate: migrate,
    FilterType: filterType,
    ValidateErrors: validateErrors,
  };
};
