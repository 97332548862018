import { useSelector } from "react-redux";
import { selectSelectedTake3D } from "../../app/slices/TrainingSlice";
import PiCollapsibleSettings from "../../components/PiCollapsibleSettings";
import PiBoxIcon from "../../icons/PiBoxIcon";
import { useTranslation } from "react-i18next";
import TakePropertiesAttachment from "./TakePropertiesAttachment";

export default function TakePropertiesAttachmentList() {
  const attachments = useSelector(selectSelectedTake3D)?.attachments;
  const { t } = useTranslation();

  const getViews = () => {
    if (!attachments) return <></>;

    const views: JSX.Element[] = [];

    for (const [key, value] of Object.entries(attachments)) {
      views.push(
        <TakePropertiesAttachment key={key} propId={key} attachment={value} />
      );
    }

    return views;
  };

  const settings = (
    <PiCollapsibleSettings
      text={t("attached_props")}
      icon={
        <PiBoxIcon width={24} height={24} className={"fill-creator-primary"} />
      }
      paddingY
    >
      {getViews()}
    </PiCollapsibleSettings>
  );

  return <>{settings}</>;
}
