import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedTake3D,
  selectTakes3D,
} from "../../app/slices/TrainingSlice";
import InteractionPointPreview, {
  InteractionPointStrategy,
  SlatePointStrategy,
} from "./InteractionPointPreview";
import { Viewport3DLayerProps } from "./Viewport3DEnvironmentMetadata";
import useSituationHelper from "../../hooks/SituationHelper.hook";
import useViewportTakeLocationLayer from "./ViewportTakeLocationLayer.hook";

export default function Viewport3DTakeLocationLayer(
  props: Viewport3DLayerProps
) {
  const dispatch = useDispatch();
  const take = useSelector(selectSelectedTake3D);
  const allTakes = useSelector(selectTakes3D);
  const { currentTakeLocation, takePositions, isCameraSelected } = { ...props };
  const { getSituationMetadata } = useSituationHelper();
  const { onAssignTakeToLocation } = useViewportTakeLocationLayer();

  const allTakeLocations = takePositions.map((point, index) => {
    return (
      <InteractionPointPreview
        strategy={InteractionPointStrategy}
        x={point.position.x}
        y={point.position.y}
        key={index}
        onClick={() => onAssignTakeToLocation(point)}
      />
    );
  });

  const currentTakeLocationSlate = () => {
    // if no take location is selected, return empty fragment
    if (!currentTakeLocation) return <></>;

    // if can have situation, return. Correct node gets shown in the situation layer
    if (currentTakeLocation.allow_situation) return <></>;

    return (
      <InteractionPointPreview
        key={currentTakeLocation?.id}
        strategy={SlatePointStrategy}
        x={currentTakeLocation?.position.x ?? -1}
        y={currentTakeLocation?.position.y ?? -1}
      />
    );
  };

  return (
    <>
      {!isCameraSelected &&
        take &&
        !take.interactionPointId &&
        allTakeLocations}
      {!isCameraSelected &&
        take &&
        take.interactionPointId &&
        currentTakeLocationSlate()}
    </>
  );
}
