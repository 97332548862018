import PiTabView from "../../components/PiTabView";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined";
import CollapsableColumnToggleButton from "./CollapsableColumnToggleButton";
import AdvancedSearchColumn from "./AdvancedSearchColumn";
import { useEffect, useMemo, useState } from "react";
import ErrorLogColumn from "../validation/error_log/ErrorLogColumn";
import { useDispatch } from "react-redux";
import {
  selectSelectedCollapsableColumn,
  setIsCollapsableColumnOpen,
} from "../../app/slices/GlobalSlice";
import { CollapsableColumn } from "../../app/slices/GlobalSlice";
import { useSelector } from "react-redux";

export default function CollapsableColumnContent() {
  const dispatch = useDispatch();
  const collapsableColumnSelected = useSelector(
    selectSelectedCollapsableColumn
  );

  const tabView = useMemo(() => {
    if (collapsableColumnSelected === CollapsableColumn.Search) {
      return <AdvancedSearchColumn />;
    } else if (collapsableColumnSelected === CollapsableColumn.ErrorLog) {
      return <ErrorLogColumn />;
    }
  }, [collapsableColumnSelected]);

  const onTabClick = (index: number) => {
    dispatch(
      setIsCollapsableColumnOpen({
        open: true,
        column: index as CollapsableColumn,
      })
    );
  };

  const myTabs = (
    <PiTabView
      startIndex={collapsableColumnSelected}
      onTabClick={onTabClick}
      tabSettings={[
        {
          icon: <ManageSearchIcon color="inherit" fontSize="inherit" />,
          orientation: "vertical",
          text: "Search",
          onClick: () => {},
        },
        {
          icon: <ClearAllOutlinedIcon color="inherit" fontSize="inherit" />,
          orientation: "vertical",
          text: "Error Log",
          onClick: () => {},
        },
      ]}
    />
  );

  return (
    <div className="w-full h-full ">
      <div className="flex">
        {myTabs}
        <CollapsableColumnToggleButton layout="relative" />
      </div>
      {tabView}
    </div>
  );
}
