import { useSelector } from "react-redux";
import PropLibraryDetails from "./PropLibraryDetails";
import {
  addSelectedPropAsUsedProp,
  selectSelectedProp,
  selectSelectedPropContent,
  selectSelectedPropMetadata,
} from "../../app/slices/PropLibrarySlice";
import { useDispatch } from "react-redux";
import {
  addPropPreset,
  addPropStateToSelectedTake,
} from "../../app/slices/TrainingSlice";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { PropType } from "../../models/Prop";
import {
  selectPropLibraryTargetPosition,
  setIsPropLibraryOpen,
} from "../../app/slices/GlobalSlice";

export default function PropStrategyDetails() {
  const dispatch = useDispatch();
  const selectedProp = useSelector(selectSelectedProp);
  const propContent = useSelector(selectSelectedPropContent);
  const selectedPropMetadata = useSelector(selectSelectedPropMetadata);
  const propLibraryTargetPosition = useSelector(
    selectPropLibraryTargetPosition
  );
  const onAddProp = () => {
    const id = GetNewGuid();
    dispatch(
      addPropPreset({
        id: id,
        propId: propContent!.id,
        name: propContent!.name,
        prefabName: selectedPropMetadata!.prefab_name,
        propType: PropType.Default,
      })
    );

    // if there is a target position, add the prop to the take
    if (propLibraryTargetPosition) {
      dispatch(
        addPropStateToSelectedTake({
          instanceId: id,
          positionId: propLibraryTargetPosition,
        })
      );
    }

    dispatch(addSelectedPropAsUsedProp());
    dispatch(setIsPropLibraryOpen({ open: false, positionId: "" }));
  };

  return (
    <PropLibraryDetails
      onAddPropClick={onAddProp}
      selectedProp={selectedProp}
      propContent={propContent}
    />
  );
}
