import SituationStrategyDetails from "../situation_library/SituationStrategyDetails";
import SituationStrategyItems from "../situation_library/SituationStrategyItems";
import PropStrategyDetails from "./PropStrategyDetails";
import PropStrategyItems from "./PropStrategyItems";

export interface PropLibraryStrategy {
  GetContent: () => JSX.Element;
  GetDetails: () => JSX.Element;
}

export const defaultPropLibraryStrategy: PropLibraryStrategy = {
  GetContent() {
    return <PropStrategyItems />;
  },
  GetDetails() {
    return <PropStrategyDetails />;
  },
};

export const situationPropLibraryStrategy: PropLibraryStrategy = {
  GetContent() {
    return <SituationStrategyItems />;
  },
  GetDetails() {
    return <SituationStrategyDetails />;
  },
};

export const environmentPropLibraryStrategy: PropLibraryStrategy = {
  GetContent() {
    return <div>COMING SOON </div>;
  },
  GetDetails() {
    return <div>COMING SOON </div>;
  },
};
