import { useSelector } from "react-redux";
import { TakeAttachment } from "../../models/TakeAttachment";
import LinkIcon from "@mui/icons-material/Link";
import {
  selectActors,
  selectAllPropPresets,
} from "../../app/slices/TrainingSlice";
export default function TakePropertiesAttachment(props: {
  propId: string;
  attachment: TakeAttachment;
}) {
  const { propId, attachment } = { ...props };
  const icon = <LinkIcon fontSize={"inherit"} color={"inherit"} />;

  const propPreset = useSelector(selectAllPropPresets).find(
    (p) => p.id === propId
  );

  const actors = useSelector(selectActors);
  const myActor = actors.find((a) => a.id === attachment.id);

  return (
    <div
      className={
        "flex px-[10px] gap-[10px] py-[4px] cursor-pointer select-none hover:bg-creator-primary-washed"
      }
    >
      <div className={"my-auto text-creator-text-sub text-14 font-medium"}>
        {myActor?.workingName ?? "Actor"}
      </div>
      <div>{icon}</div>
      <div className={"my-auto text-creator-text-sub text-14 font-medium"}>
        {propPreset?.name}
      </div>
    </div>
  );
}
