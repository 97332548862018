import { useSelector } from "react-redux";
import { selectCurrentOrganization } from "../../app/slices/UserSlice";
import { selectToken } from "../../app/slices/AuthenticationSlice";
import { useEffect, useState } from "react";
import {
  addPagedSituations,
  initializeSituations,
  selectSituations,
} from "../../app/slices/SituationSlice";
import {
  selectFilteredCategory,
  selectPropFilter,
} from "../../app/slices/PropLibrarySlice";
import { useDispatch } from "react-redux";
import { PropService } from "../../services/PropService";
import { SituationService } from "../../services/SituationService";

export default function useSituationPagination() {
  // needed for service
  const orgId = useSelector(selectCurrentOrganization)?.id;
  const token = useSelector(selectToken);

  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const pageSize = 10;

  // situations
  const allSituations = useSelector(selectSituations);

  // filters
  const filteredCategory = useSelector(selectFilteredCategory); // category - filter
  const propFilter = useSelector(selectPropFilter); // text - filter

  // slice
  const dispatch = useDispatch();

  useEffect(() => {
    LoadFirstPage();
  }, [filteredCategory]);

  async function LoadFirstPage() {
    if (!orgId) return;

    setCurrentPage(1);

    const result = await SituationService.GetSituationsFromPage(
      orgId,
      token,
      currentPage,
      pageSize,
      filteredCategory,
      propFilter
    );

    dispatch(
      initializeSituations({
        totalCount: result.totalCount,
        firstPage: result.props,
      })
    );
  }

  // 2. onRerender
  const onRerender = async (
    amountOfColumns: number,
    rowStartIndex: number,
    rowStopIndex: number
  ) => {
    if (!orgId || !token) return;

    var startIndex = amountOfColumns * rowStartIndex;
    var endIndex = amountOfColumns * rowStopIndex + (amountOfColumns - 1);
    var startPage = Math.floor(startIndex / 10);
    var endPage = Math.ceil(endIndex / 10);

    // load next page
    for (var s = startPage; s < endPage; s++) {
      if (allSituations[10 * s] === undefined) {
        var result = await PropService.GetPropsFromPage(
          orgId,
          token,
          s + 1,
          pageSize,
          filteredCategory,
          propFilter
        );
        if (!result) return;
        dispatch(addPagedSituations({ pageNumber: s, assets: result.props }));
      }
    }
  };

  return { LoadFirstPage, onRerender } as const;
}
