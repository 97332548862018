import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActors,
  selectSelectedElement,
  updateSelectedActorState,
} from "../../app/slices/TrainingSlice";
import { ActorState } from "../../models/ActorState";
import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import ActorStateLookatDropdown from "./ActorStateLookatDropdown";
import ActorStateOutfitDropdown from "./ActorStateOutfitDropdown";
import "./ActorStatePropertiesColumn.css";
import useActorStateProperties, {
  ActorStatus,
} from "./ActorStatePropertiesColumn.hooks";
import ActorStateSetActivityDropdown from "./ActorStateActivityDropdown";
import ActorStatePropertiesBreadcrumb from "./ActorStatePropertiesBreadcrumb";
import PropertiesColumnBody from "../properties_column/PropertiesColumnBody";
import ActorStatePropertiesHeader from "./ActorStatePropertiesHeader";
import ActorStateMovementToggle from "./ActorStateMovementToggle";
import ActorStatePropInfoField from "./ActorStatePropInfoField";

export default function ActorStatePropertiesColumn() {
  const selectedActorState = useSelector(selectSelectedElement) as ActorState;
  const actorPreset = useSelector(selectActors).find(
    (actor) => actor.id === selectedActorState?.actorId
  );
  const { getActorStatus, getActorStatusLabel } = useActorStateProperties();
  const [myActorStatus, setMyActorStatus] = useState<ActorStatus>(
    ActorStatus.Invalid
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setMyActorStatus(getActorStatus());
  }, [selectedActorState, getActorStatus]);

  const onChangeField = (field: keyof ActorState, value: any) => {
    dispatch(
      updateSelectedActorState({ ...selectedActorState, [field]: value })
    );
  };

  const onChangeLookat = (lookatId: string) =>
    onChangeField("lookat", lookatId);

  const canSelectMovement =
    (selectedActorState.actorId === "player" &&
      myActorStatus !== ActorStatus.Idle) ||
    (selectedActorState != null &&
      myActorStatus !== ActorStatus.Idle &&
      myActorStatus !== ActorStatus.Spawned);

  return (
    <>
      <PropertiesColumnHeader text={actorPreset?.workingName ?? "No Name"} />
      <PropertiesColumnBody>
        <div className={"h-full"}>
          <ActorStatePropertiesBreadcrumb />
          <div className={"bg-creator-primary-background"}>
            <div className={"flex flex-col gap-[20px] py-[5px] px-[10px]"}>
              <ActorStatePropertiesHeader actor={selectedActorState} />
              <ActorStateMovementToggle
                actor={selectedActorState}
                onChange={(movement) => onChangeField("movement", movement)}
                disabled={!canSelectMovement}
              />
              <ActorStateLookatDropdown
                actorState={selectedActorState}
                onChange={onChangeLookat}
              />
              <ActorStateSetActivityDropdown
                actorState={selectedActorState}
                onChange={(activity) => onChangeField("activity", activity)}
              />
              <ActorStateOutfitDropdown
                actorState={selectedActorState}
                onChange={(outfit) => onChangeField("outfit", outfit)}
                disabled={myActorStatus === ActorStatus.Spawned}
              />
              <ActorStatePropInfoField actorState={selectedActorState} />
            </div>
          </div>
        </div>
      </PropertiesColumnBody>
    </>
  );
}
