import { useSelector } from "react-redux";
import {
  selectAllPropPresets,
  selectSelectedTake3D,
} from "../../app/slices/TrainingSlice";
import PropPresetPopoverCard from "./PropPresetPopoverCard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useMemo } from "react";
import classNames from "classnames";
import usePropHelper from "../../hooks/PropHelper";
import { PropType } from "../../models/Prop";
import AddIcon from "@mui/icons-material/Add";
import PiSeperator from "../previews/components/PiSeperator";
export default function PropPresetPopover(props: {
  canDelete: boolean;
  onClick: (instanceId: string, propId: string) => void;
  onDelete: () => void;
  includedGrabbedProps?: boolean;
  onAddNewPropClick: () => void;
}) {
  const {
    canDelete,
    onClick,
    onDelete,
    includedGrabbedProps,
    onAddNewPropClick,
  } = { ...props };
  const allProps = useSelector(selectAllPropPresets);
  const currentTake = useSelector(selectSelectedTake3D);
  const propStates = useMemo(() => {
    return currentTake?.props ?? [];
  }, [currentTake]);
  const { getIsPropAttachedToActor } = usePropHelper();

  const propsFiltered = useMemo(() => {
    return allProps.filter((prop) => {
      const pState = propStates.find((p) => p.instanceId === prop.id);
      let show = true;
      if (
        pState !== undefined &&
        getIsPropAttachedToActor(pState) &&
        !includedGrabbedProps
      )
        show = false;

      if (prop.propType === PropType.Situation) show = false;
      if (prop.link !== undefined) show = false;

      if (show) return prop;
    });
  }, [allProps, currentTake, propStates]);

  const allPropsMapped = propsFiltered.map((prop) => {
    return (
      <PropPresetPopoverCard
        key={prop.id}
        prop={prop}
        onClick={() => onClick(prop.id, prop.propId)}
      />
    );
  });

  const deleteButton = (
    <div className="h-[42px]">
      <div
        className={classNames({
          "h-full flex gap-[10px] items-center text-[14px] rounded-[5px] cursor-pointer px-[18px]":
            true,
          "text-interaction-disabled": !canDelete,
          "text-interaction-secondary hover:bg-interaction-text-hoverBackground":
            canDelete,
        })}
        onClick={onDelete}
      >
        <DeleteOutlineOutlinedIcon className={"text-[24px]"} color="inherit" />
        <span className="text-[14px] font-medium">Delete</span>
      </div>
    </div>
  );

  const addButton = (
    <div className="h-[42px]" onClick={onAddNewPropClick}>
      <div className="h-full flex gap-[10px] items-center text-[14px] rounded-[5px] cursor-pointer px-[18px] hover:bg-interaction-text-hoverBackground text-creator-primary">
        <AddIcon className="text-[24px]" color="inherit" />
        <div className="text-[14px] text-creator-primary font-medium">
          Add New Prop
        </div>
      </div>
    </div>
  );

  return (
    <div className={"flex flex-col gap-[4px]"}>
      {allPropsMapped.length > 0 && allPropsMapped}
      {allPropsMapped.length === 0 && (
        <div
          className={
            "flex w-full py-[8px] px-[10px] cursor-not-allowed text-[14px] text-creator-text-faded"
          }
        >
          No props available
        </div>
      )}
      <PiSeperator />
      {addButton}
      <PiSeperator />
      {deleteButton}
    </div>
  );
}
