import { TranslatedPropCategoryDTO } from "../dto/TranslatedPropCategoryDTO";
import axios from "axios";
import { TranslatedPropDTO } from "../dto/TranslatedPropDTO";
import { TranslatedPropContentDTO } from "../dto/TranslatedPropContentDTO";
import { PropInteractionDTO } from "../dto/PropInteractionDTO";
import { PropMetadata } from "../dto/PropMetadata";

var CREATOR_URL = process.env.REACT_APP_PLAYIT_CREATORAPI;

export class PropService {
  static async GetTranslatedCategories(
    orgId: string,
    token: string
  ): Promise<TranslatedPropCategoryDTO[]> {
    const path = "/props/translated-categories";
    const url = CREATOR_URL + path;

    const params = {
      PageSize: 10,
      PageNumber: 1,
      OrderBy: "name",
    };
    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: params,
    };

    var result: TranslatedPropCategoryDTO[] = [];

    await axios
      .get<TranslatedPropCategoryDTO[]>(url, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });

    return result;
  }

  static async GetPropsFromPage(
    orgId: string,
    token: string,
    pageNumber: number,
    pageSize: number,
    category: string,
    filter: string
  ): Promise<{ totalCount: number; props: TranslatedPropDTO[] }> {
    const path = `/organizations/${orgId}/translated-props`;
    const url = CREATOR_URL + path;

    const params = {
      PageSize: pageSize,
      PageNumber: pageNumber,
      Category: category,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    };

    let result: TranslatedPropDTO[] = [];
    let totalCount = 0;

    await axios
      .get(url, config)
      .then((response) => {
        result = response.data;

        totalCount = JSON.parse(response.headers["x-pagination"])["totalCount"];
      })
      .catch((error) => {
        console.log(error.response);
      });
    return {
      totalCount: totalCount,
      props: result,
    };
  }

  static async GetTranslatedInteractions(
    orgId: string,
    token: string
  ): Promise<PropInteractionDTO[]> {
    const path = `/organizations/${orgId}/props/translated-interactions`;
    const url = CREATOR_URL + path;

    const params = {
      PageSize: 10,
      PageNumber: 1,
      OrderBy: "name",
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    };

    let result: PropInteractionDTO[] = [];
    await axios
      .get(url, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });
    return result;
  }

  static async GetPropContent(
    orgId: string,
    token: string,
    propId: string
  ): Promise<TranslatedPropContentDTO | undefined> {
    const path = `/organizations/${orgId}/translated-props/${propId}`;
    const url = CREATOR_URL + path;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let result: TranslatedPropContentDTO | undefined = undefined;

    await axios
      .get(url, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });

    return result;
  }

  static async GetPropMetadata(url: string): Promise<PropMetadata | undefined> {
    let result: PropMetadata | undefined = undefined;

    await axios
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => console.log(error.response));

    return result;
  }
}
