import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addPropPreset } from "../../app/slices/TrainingSlice";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { PropType } from "../../models/Prop";
import PropLibraryDetails from "../prop_library/PropLibraryDetails";
import {
  selectSelectedSituation,
  selectSelectedSituationContent,
  selectSelectedSituationMetadata,
} from "../../app/slices/SituationSlice";
import { addSituationAsUsedProp } from "../../app/slices/PropLibrarySlice";
import { SituationMetadata } from "../../dto/SituationMetadata";
import useSituationHelper from "../../hooks/SituationHelper.hook";
import { setIsPropLibraryOpen } from "../../app/slices/GlobalSlice";

export default function SituationStrategyDetails() {
  const dispatch = useDispatch();
  const selectedSituation = useSelector(selectSelectedSituation);
  const situationContent = useSelector(selectSelectedSituationContent);
  const selectedSituationMetadata = useSelector(
    selectSelectedSituationMetadata
  );
  const { addDynamicProp } = useSituationHelper();

  const onAddProp = () => {
    const newSituationProp = {
      id: GetNewGuid(),
      propId: situationContent!.id,
      name: situationContent!.name,
      prefabName: selectedSituationMetadata!.prefab_name,
      propType: PropType.Situation,
    };
    dispatch(addPropPreset(newSituationProp));

    // add all dynamic props
    const metadata = selectedSituationMetadata as SituationMetadata;

    metadata.prop_positions.forEach((pos) => {
      if (pos.dynamicPropId)
        addDynamicProp(pos.dynamicPropId, newSituationProp.id, pos.id);
    });

    dispatch(
      addSituationAsUsedProp({
        id: situationContent!.id,
        propContent: situationContent!,
        metadata: selectedSituationMetadata!,
      })
    );
    dispatch(setIsPropLibraryOpen({ open: false, positionId: "" }));
  };

  return (
    <PropLibraryDetails
      onAddPropClick={onAddProp}
      selectedProp={selectedSituation}
      propContent={situationContent}
    />
  );
}
