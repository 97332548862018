import LogData from "./LogData";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import classNames from "classnames";
export default function ErrorLogCard(props: {
  log: LogData;
  isSelected: boolean;
  onClick: () => void;
}) {
  const { log, isSelected, onClick } = props;

  const icon =
    log.logLevel === "error" ? (
      <div className="text-creator-error text-[24px]">
        <ErrorOutlineOutlinedIcon fontSize="inherit" color="inherit" />
      </div>
    ) : (
      <div className="text-warning-600 text-[24px]">
        <ReportProblemOutlinedIcon fontSize="inherit" color="inherit" />
      </div>
    );

  const logHeader = (
    <div className="text-[10px] text-creator-text-soft">
      {log.parentName} - {log.interactionName}
    </div>
  );

  const logMessage = (
    <div
      className={classNames({
        "text-[12px]": true,
        "text-creator-error": log.logLevel === "error",
        "text-warning-600": log.logLevel === "warning",
      })}
    >
      {log.message}
    </div>
  );

  return (
    <div
      className={classNames(
        "flex w-full h-[50px] gap-[12px]  border-b-2 border-solid border-creator-border hover:bg-creator-primary-washed cursor-pointer items-center px-[4px] select-none",
        {
          "bg-white": !isSelected,
          "bg-creator-childActive": isSelected,
        }
      )}
      onClick={onClick}
    >
      <div className="">{icon}</div>
      <div className="flex flex-col gap-[2px]">
        {logHeader}
        {logMessage}
      </div>
    </div>
  );
}
