import React from "react";
import { useSelector } from "react-redux";
import { selectOutfits } from "../../app/slices/MetadataSlice";
import PiDropdownV2 from "../../components/PiDropdownV2";
import { PiPopperMenuProps } from "../../components/PiPopper";
import { activitiesMapped, ActorState } from "../../models/ActorState";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PiCreatorDropdown from "../../components/PiCreatorDropdown/PiCreatorDropdown";

export default function ActorStateOutfitDropdown(props: {
  actorState: ActorState;
  onChange: (outfit: string) => void;
  disabled?: boolean;
}) {
  const { actorState, onChange, disabled } = { ...props };

  const outfits = useSelector(selectOutfits);

  const items = outfits.map((outfit, index) => {
    const image = (
      <img
        src={"/outfits/" + outfit.outfit_preview_male}
        alt={outfit.outfit_name}
        className={"w-[36px] h-[36px] object-contain"}
      />
    );

    const label = (
      <div className={"my-auto text-creator-text text-[14px] font-medium"}>
        {outfit.outfit_name}
      </div>
    );
    return (
      <div
        className={"flex gap-[10px] py-[8px]"}
        onClick={() => onChange(outfit.outfit_name)}
      >
        {image}
        {label}
      </div>
    );
  });

  const myOutfit = outfits.find(
    (outfit) => outfit.outfit_name === actorState.outfit
  );

  const trigger = myOutfit ? (
    <div className={"flex gap-[10px] h-full w-full"}>
      <img
        src={"/outfits/" + myOutfit?.outfit_preview_male}
        alt={"/"}
        className={"w-[36px] h-[36px] object-contain"}
      />
      <div className={"my-auto text-creator-text text-[14px] font-medium"}>
        {myOutfit?.outfit_name}
      </div>
    </div>
  ) : (
    <div
      className={"flex text-left text-[14px] text-creator-text h-full w-full"}
    >
      <div className={"my-auto flex gap-[10px]"}>
        <PersonOffOutlinedIcon className={"my-auto"} />
        <span className={"my-auto"}>No Outfit Selected</span>
      </div>
    </div>
  );

  const newDropdown = (
    <PiCreatorDropdown
      label={"Outfit"}
      trigger={trigger}
      items={items as JSX.Element[]}
    />
  );

  return <>{newDropdown}</>;
}
