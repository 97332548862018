import { useSelector } from "react-redux";
import {
  selectAllPropPresets,
  selectSelectedTake3D,
} from "../app/slices/TrainingSlice";
import { selectUsedProps } from "../app/slices/PropLibrarySlice";
import { TakeAttachment } from "../models/TakeAttachment";

export default function useAttachmentHelper() {
  const propPresets = useSelector(selectAllPropPresets);
  const usedProps = useSelector(selectUsedProps);
  const currentTake = useSelector(selectSelectedTake3D)!;

  const getAllAttachableProps = () => {
    const attachables: string[] = [];

    // get all used props that have the category "Attachable" -> store the prop id's in an array
    for (const [key, value] of Object.entries(usedProps)) {
      if (value.propContent.categories.some((cat) => cat.name === "attachable"))
        attachables.push(key);
    }

    // go over all prop presets in the project and return the ones that are attachable
    return propPresets.filter((prop) => attachables.includes(prop.propId));
  };

  const getPropAttachedToActor = (actorId: string) => {
    // find the prop instance id the actor is linked to
    let myAttachmentKey: { key: string; value: TakeAttachment } | undefined =
      undefined;

    for (const [key, value] of Object.entries(currentTake.attachments)) {
      if (value.id === actorId) {
        myAttachmentKey = { key, value };
      }
    }

    if (myAttachmentKey === undefined) return undefined; // if the actor is not linked to a prop, return undefined

    // find the prop preset
    const myPreset = propPresets.find(
      (prop) => prop.id === myAttachmentKey!.key
    );
    if (!myPreset) throw new Error("No prop attached to actor"); // if we can't find it, something is really wrong

    // find the used prop (dto + metadata)
    const usedProp = usedProps[myPreset.propId];

    return {
      attachment: myAttachmentKey.value,
      propPreset: myPreset,
      usedProp: usedProp,
    };
  };

  return { getAllAttachableProps, getPropAttachedToActor } as const;
}
