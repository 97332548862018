import { useSelector } from "react-redux";
import {
  selectActors,
  selectSelectedElement,
  selectSelectedTake3D,
} from "../../../app/slices/TrainingSlice";
import { InteractionType } from "../../../models/interactions/Interaction";
import { IDetach } from "../../../models/interactions/Detach";
import { IAttach } from "../../../models/interactions/Attach";
import usePropHelper from "../../../hooks/PropHelper";

export default function useDetachDropdown() {
  const currentTake = useSelector(selectSelectedTake3D);
  const currentDetach = useSelector(selectSelectedElement) as IDetach;
  const allActors = useSelector(selectActors);

  const { getPropPresetByInstanceId } = usePropHelper();

  const getAllAttachesViaInteraction = () => {
    if (!currentTake) return undefined;

    const limit = currentTake.interactions.findIndex(
      (i) => i.id === currentDetach.id
    );

    const attaches: Record<string, IAttach> = {};

    for (let i = 0; i < limit; i++) {
      const interaction = currentTake.interactions[i];

      if (interaction.type === InteractionType.Attach) {
        const casted = interaction as IAttach;
        attaches[casted.propGuid] = casted;
      }

      if (interaction.type === InteractionType.Detach) {
        const casted = interaction as IDetach;
        delete attaches[casted.propGuid];
      }
    }
    return Object.values(attaches);
  };

  const getAllAttaches = () => {
    const attaches: { propGuid: string; actorId: string }[] = [];
    if (!currentTake) return attaches;

    Object.entries(currentTake.attachments).forEach(
      ([propGuid, attachment]) => {
        attaches.push({ propGuid, actorId: attachment.id });
      }
    );

    getAllAttachesViaInteraction()?.forEach((attach) => {
      attaches.push({ propGuid: attach.propGuid, actorId: attach.playerGuid });
    });

    return attaches;
  };

  const getSelectedAttachment = () => {
    // I need the preset and the actor
    const preset = getPropPresetByInstanceId(currentDetach.propGuid);
    const actor = allActors.find(
      (actor) => actor.id === currentDetach.playerGuid
    );

    return { preset, actor };
  };

  return { getAllAttaches, getSelectedAttachment } as const;
}
