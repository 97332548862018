import { ActorState } from "../../models/ActorState";
import { addAttachmentToTake } from "../../app/slices/TrainingSlice";
import PiCreatorDropdown from "../../components/PiCreatorDropdown/PiCreatorDropdown";
import useAttachmentHelper from "../../hooks/AttachmentHelper.hook";
import PiCreatorDropdownItem from "../../components/PiCreatorDropdown/PiCreatorDropdownItem";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import { useDispatch } from "react-redux";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import usePropHelper from "../../hooks/PropHelper";

export default function ActorStatePropInfoField(props: {
  actorState: ActorState;
}) {
  const { actorState } = { ...props };
  const { getAllAttachableProps, getPropAttachedToActor } =
    useAttachmentHelper();
  const dispatch = useDispatch();
  const attachedProp = getPropAttachedToActor(actorState.actorId);
  const { getUsedPropByInstanceId } = usePropHelper();
  const getAttachedPropView = () => {
    if (!attachedProp) return noneOption;

    return (
      <PiCreatorDropdownItem
        text={attachedProp.propPreset.name}
        icon={<ViewInArOutlinedIcon fontSize="inherit" color="inherit" />}
        onClick={() => onChange("", "")}
      />
    );
  };

  const onChange = (propId: string, tag: string) => {
    // when selecting a NEW prop, change the tag to a good default
    if (
      propId &&
      ((attachedProp && attachedProp.propPreset.id !== propId) || !attachedProp)
    ) {
      var used = getUsedPropByInstanceId(propId);
      tag = used.metadata.attachmentTags![0].tag;
    }
    // create an attachment between propId and actorId
    dispatch(addAttachmentToTake({ actorId: actorState.actorId, propId, tag }));
  };

  const noneOption = (
    <PiCreatorDropdownItem
      text="None"
      icon={<DoDisturbAltOutlinedIcon fontSize="inherit" color="inherit" />}
      onClick={() => onChange("", "")}
    />
  );

  const propTrigger = <>{getAttachedPropView()}</>;
  const propItemsCombined = [
    noneOption,
    ...getAllAttachableProps().map((prop) => {
      return (
        <PiCreatorDropdownItem
          text={prop.name}
          onClick={() => onChange(prop.id, "")}
          icon={<ViewInArOutlinedIcon fontSize="inherit" color="inherit" />}
        />
      );
    }),
  ];
  const attachPropDropdown = (
    <PiCreatorDropdown
      label={"Attached Prop"}
      trigger={propTrigger}
      items={propItemsCombined as JSX.Element[]}
    />
  );

  // === tag dropdown ===
  const getTagViews = () => {
    if (attachedProp?.usedProp.metadata.attachmentTags === undefined)
      return [noneOption];

    return attachedProp?.usedProp.metadata.attachmentTags!.map((tag) => {
      return (
        <PiCreatorDropdownItem
          text={tag.tag}
          icon={<LocalOfferOutlinedIcon fontSize="inherit" color="inherit" />}
          onClick={() => onChange(attachedProp.propPreset.id, tag.tag)}
        />
      );
    });
  };

  const getTagTrigger = () => {
    if (
      attachedProp?.usedProp.metadata.attachmentTags === undefined ||
      !attachedProp?.attachment.tag
    )
      return [noneOption];

    return (
      <PiCreatorDropdownItem
        text={attachedProp?.attachment.tag}
        icon={<LocalOfferOutlinedIcon fontSize="inherit" color="inherit" />}
        onClick={() => {}}
      />
    );
  };

  const tagTrigger = getTagTrigger();
  const tagsCombined = getTagViews();

  const tagDropdown = (
    <PiCreatorDropdown
      label={"Tag"}
      trigger={tagTrigger}
      items={tagsCombined as JSX.Element[]}
    />
  );

  return (
    <div className={"flex flex-col gap-[8px]"}>
      <div className="border-t-[2px] border-solid border-creator-border w-full h-[6px]"></div>
      {/* <PiCollapsibleSettings
        text="Attach"
        icon={<ViewInArOutlinedIcon fontSize="inherit" color="inherit" />}
        paddingX
        paddingY
      ></PiCollapsibleSettings> */}
      {attachPropDropdown}
      {tagDropdown}
      {/* {body} */}
    </div>
  );
}
