import PiModal, { PiModalProps } from "../../components/PiModal";
import ActorPresetEditor from "../actor_presets/ActorPresetEditor";

export default function QuickActorModal(props: PiModalProps) {
  const { isOpen, onClose, title, width, height } = { ...props };
  return (
    <PiModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      width={width}
      height={height}
    >
      <ActorPresetEditor isQuickAction={true} />
    </PiModal>
  );
}
