import { useSelector } from "react-redux";
import { addUsedProps, selectUsedProps } from "../app/slices/PropLibrarySlice";
import { SituationMetadata } from "../dto/SituationMetadata";
import { PropService } from "../services/PropService";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { useDispatch } from "react-redux";
import {
  addPropPreset,
  selectAllPropPresets,
  selectSelectedTake3D,
} from "../app/slices/TrainingSlice";
import { GetNewGuid } from "../helpers/GuidHelper";
import { PropType } from "../models/Prop";
import {
  InteractionCategory,
  InteractionDefintion,
  InteractionIcon,
} from "../features/interaction_picker/InteractionDefinitions";
import { InteractionType } from "../models/interactions/Interaction";
import { useCallback } from "react";

export default function useSituationHelper() {
  const usedProps = useSelector(selectUsedProps);
  const propPresets = useSelector(selectAllPropPresets);
  const orgId = useSelector(selectCurrentOrganization)!.id;
  const token = useSelector(selectToken);
  const currentTake = useSelector(selectSelectedTake3D);

  const dispatch = useDispatch();

  const getStaticPropIds = (situationId: string) => {
    const usedProp = usedProps[situationId].metadata as SituationMetadata;
    let ids: string[] = [];

    usedProp.prop_positions.forEach((element) => {
      if (element.dynamicPropId) ids.push(element.dynamicPropId);
    });

    return ids;
  };

  const addDynamicProp = async (
    propId: string,
    parentId: string,
    positionId: string
  ) => {
    const content = await PropService.GetPropContent(orgId!, token, propId);
    if (!content) return;
    const metadata = await PropService.GetPropMetadata(content.metadataUri);
    if (!metadata) return;

    //usedProps.push({ propContent: content, metadata: metadata });
    dispatch(
      addPropPreset({
        id: GetNewGuid(),
        name: content.name,
        prefabName: metadata.prefab_name,
        propId: content.id,
        propType: PropType.Default,
        link: { parentId: parentId, positionId: positionId },
      })
    );

    dispatch(
      addUsedProps([
        {
          metadata: metadata,
          propContent: content,
        },
      ])
    );
  };

  const getSituationMetadata = useCallback(
    (situationInstanceId: string) => {
      const preset = propPresets.find(
        (preset) => preset.id === situationInstanceId
      );
      if (!preset)
        throw new Error("Situation not found " + situationInstanceId);

      const metadata = usedProps[preset.propId].metadata as SituationMetadata;
      return metadata;
    },
    [propPresets, usedProps]
  );

  const getAllInvolvedSituationInteractions = () => {
    // get all used situations in current take
    // get all prop presets that have the parent id of the situation
    // ...

    if (!currentTake)
      throw new Error("Trying to get involved situation when no take selected");

    // all situations in the project
    const allSituations = propPresets.filter(
      (p) => p.propType === PropType.Situation
    );

    // all prop presets that have a parent
    const allSituationChildren = propPresets.filter(
      (prop) => prop.link !== undefined
    );
    // all situations that are currently used in the take
    const allUsedSituations = Object.values(currentTake.situations).filter(
      (situationInstanceId) =>
        allSituations.some((s) => s.id === situationInstanceId)
    );

    // all props in the situations that are used
    const allUsedSituationProps = allSituationChildren.filter((child) =>
      allUsedSituations.some((s) => s === child.link?.parentId)
    );

    console.log(allUsedSituations.length);

    // add interaction definitions
    const definitions: Record<string, InteractionDefintion[]> = {};

    // TODO -> ADD PARENT NAME

    allUsedSituationProps.forEach((prop) => {
      const propMetadata = usedProps[prop.propId].metadata;
      if (!propMetadata) return new Error("Prop metadata not found");

      let localDefinitions: InteractionDefintion[] = [];

      propMetadata.dynamicInteractions?.forEach((interaction) => {
        localDefinitions.push({
          name: interaction.title,
          icon: InteractionIcon.Message,
          type: InteractionType.DynamicInteraction,
          category: InteractionCategory.Quizes,
          previewImage: "",
          description: interaction.description,
        });
      });

      definitions[prop.id] = localDefinitions;
    });

    return definitions;
  };

  return {
    getStaticPropIds,
    addDynamicProp,
    getSituationMetadata,
    getAllInvolvedSituationInteractions,
  } as const;
}
